import { Libraries } from "@react-google-maps/api";
import momenttz from "moment-timezone";

export const utcOffset = momenttz.tz(momenttz.tz.guess()).utcOffset() / 60;

export const device =
    "downtownv2" +
    (process.env.NODE_ENV === "production" ? "" : `-${process.env.NODE_ENV}`);

export const version = "2023.4.3";

export const loginCookieLifetime = 60 * 60 * 20;

export const colors = {
    primary: "#2D9EAF", // PassioPrimaryColor
    secondary: "#E26B43", // PassioSecondaryColor
};

// All Paraplan responses have the status fields
export const statusFields = {
    errorMessage: "",
    success: true,
    tokenExists: true,
    tokenIsValid: true,
};

export const navigatorAPIUrl = process.env.REACT_APP_NAVIGATOR_API_URL || "";
export const paraPlanApiUrl = process.env.REACT_APP_PARAPLAN_API_URL || "";
export const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "";
export const passioEmail = process.env.REACT_APP_PASSIO_EMAIL || "";
export const passioPassword = process.env.REACT_APP_PASSIO_PASSWORD || "";
export const SESRegion = process.env.REACT_APP_SES_REGION || "";
export const SESSenderEmail = process.env.REACT_APP_SES_SENDER_EMAIL || "";
export const SESCognitoPoolId = process.env.REACT_APP_SES_COGNITO_POOL_ID || "";

export const googleMapLibraries: Libraries = ["maps", "places", "geometry"];
export const dateTimeFormat = "YYYY-MM-DD HH:mm:ss";
export const USDateTimeFormat = "MM/DD/YYYY h:mm A";
