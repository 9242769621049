import { AutoScheduleFailAction } from "src/types";
import { navigatorAPIUrl, statusFields } from "src/utils/constants";

export const defaultHomeAddress = {
    address1: "",
    address2: "",
    city: "",
    databaseId: "",
    isDropOffSpot: false,
    isPickUpSpot: false,
    lat: 0,
    lng: 0,
    name: "",
    phone: "",
    state: "",
    zip: "",
};

export const defaultParsedAddress = {
    name: "",
    address1: "",
    address2: "",
    street: "",
    city: "",
    state: "",
    zip: "",
    latitude: 0,
    longitude: 0,
    description: "",
};

export const defaultPlace = {
    ...statusFields,
    ...defaultHomeAddress,
};

export const defaultUser = {
    ...statusFields,
    AssignedRoute: "",
    AssignedTimes: "",
    AssignedTripCount: "",
    AssignedVehicle: "",
    AvailableFundingSources: null,
    CanCallClients: false,
    CanCancel: false,
    CanScheduleTrips: false,
    CanViewVehicles: false,
    ClientCanRequestRecurringRides: false,
    ClientCanRequestTrips: false,
    ClientID: 0,
    CollectGPS: false,
    CopayPaymentTypes: [],
    CurrentDriverBreakID: 0,
    DatabaseID: 0,
    DefaultPaymentType: "",
    DeviceToken: null,
    Email: "",
    Key: "",
    LocalDriverID: 0,
    Name: "",
    NoShowTimer: 0,
    PPPAccess: 1,
    ProviderCanRequestTrips: false,
    ProviderCanViewClientDetails: false,
    ProviderPrograms: "",
    ProviderProgramsList: null,
    RESTUrl: navigatorAPIUrl,
    UserId: 0,
    UserType: 2,
};

export const defaultAccessibility = {
    isPersonWithDisability: false,
    extraTime: -5,
    hasPCA: false,
    needsWCVehicle: false,
    needsWheelchairVan: false,
    usesWC: false,
    usesWheelchair: false,
    equipment: [],
    needs: [],
    accessibilityItems: null,
    equipmentType: null,
    wheelChairType: null,
};

export const defaultClient = {
    ...statusFields,
    accessibility: defaultAccessibility,
    appointments: null,
    birthDateJson: null,
    birthdate: null,
    birthdateEpoch: null,
    defaultProgramMembershipID: null,
    defaultProgramName: null,
    dwellTime: 0,
    email: "",
    home: defaultPlace,
    address: defaultParsedAddress,
    id: 0,
    riderId: 0,
    name: "",
    notes: null,
    phone: "",
    programs: [],
    services: [],
    specialNeeds: null,
    status: null,
    subscriptions: [],
    agencyId: "",
    defaultServiceId: "",
    puExtraDuration: 0,
    doExtraDuration: 0,
};

export const defaultConfig = {
    AgencyName: "",
    AllowAutoLogin: false,
    AllowSavedPassword: false,
    AmountEarlyForDO: 0,
    AmountEarlyForPU: 0,
    AmountLateForDO: 0,
    AmountLateForPU: 0,
    AutoDispatch: false,
    ClientRideTimeMaxRidingTime: 0,
    ConnectPreferences: {
        AdvancedNoticedRequired: 15,
        AssignmentTarget: "",
        AutoApproveRequests: false,
        AutoScheduleHierarchy: {
            DispatcherResponseTime: 1,
            DispatcherShouldSchedule: false,
            DriverResponseTime: 5,
            DriverShouldSchedule: false,
            DriversToAsk: 0,
            EmailNotificationOfAutoSchedule: [],
            EmailNotificationOfTripsForDispatcher: [],
            Hierarchy: "",
            ShouldSenseDispatcherPresence: false,
            SystemShouldAutoSchedule: false,
        },
        CanCreateApptRequest: false,
        CanRequestAnyDOLocation: false,
        CanRequestAnyPULocation: false,
        CanRequestReturnTrip: false,
        DefaultProgram: "",
        DefaultProgramId: "",
        DefaultStatusType: "",
        DefaultTintString: "",
        DisplayName: "",
        FAQLink: "",
        GeneralPublicOnDemandRiderId: "",
        Logo: "",
        MobilityPlatform: {
            IsPlatformOwner: false,
            IsTransportationProvider: false,
        },
        NegotiationRange: 0,
        OwnerLink: "",
        RestrictRequestToBuiltTimes: false,
        SameDayRequests: false,
        SecondaryTintString: "",
    },
    DefaultBirthdateForNewClients: "",
    DefaultCity: "",
    DefaultEligibiltyStatusForNewClients: "",
    DefaultReturnTime: 0,
    DefaultSchedulingCanvasView: 0,
    DefaultState: "",
    DefaultTripDateOffset: 1,
    DispatcherEmail: "",
    DownloadedTripTriggersBackgroundUpdate: false,
    DriverDisplayName: null,
    DriverPreferences: {
        ExternalLinks: [
            {
                LinkDescription: "",
                LinkImageURL: "",
                LinkTitle: "",
                LinkURL: "",
                LinkURLAndroid: "",
                LinkURLiOS: "",
            },
        ],
        Json: "",
    },
    GenerateClientInvoiceWhenSaveToHistory: false,
    GenerateProgramInvoiceWhenSaveToHistory: false,
    IncomeSortByName: false,
    InvoiceTemplate: "",
    Location: {
        Address1: "",
        Address2: "",
        Building: "",
        City: "",
        County: "",
        ID: 0,
        Latitude: 0,
        Longitude: 0,
        Name: "",
        State: "",
        ZipCode: "",
    },
    More: {
        GenerateProgramInvoiceWhenSaveToHistory: false,
        NoShowTimer: 0,
        ShowEmergencyButton: false,
        Timezone: "",
    },
    NewClientsHaveWCByDefault: false,
    ParaPlanPassengerAccessLevel: 1,
    PassioUser: "",
    PassioUserId: null,
    PassioUsername: "",
    PassioAgency: "",
    PhoneMask: "",
    RemoveDroppedOffTripsFromActiveFilter: false,
    ShowClientMiddleInitial: false,
    TreatBrokerPUTimeAsTripTime: false,
    TripHistoryBackFillDate: "",
    UseNeighborhoodForCity: false,
    UseNewSaveToHistory: false,
    gTimeType: "P",
    iOSCombineClientAndTripComment: false,
    iOSDefaultPaymentType: "",
    iOSDisplayProgramBilled: false,
    gCost4: "",
};

export const defaultProgram = {
    ...statusFields,
    activeGeozoneIds: [],
    archived: false,
    billingAddress: defaultPlace,
    billingEmail: "",
    billingEntityName: "",
    billingPersonName: "",
    billingPhone: "",
    childExempt: false,
    databaseID: "",
    deleted: false,
    dropOffZones: [],
    dropoffLOS: "",
    eligibleVehicleGroups: [],
    eligibleVehicles: [],
    fixedFee: null,
    infantExempt: false,
    isDefault: false,
    membershipID: "",
    membershipIsRequired: false,
    otherRidersExempt: false,
    perMileFee: null,
    pickUpLOS: "",
    pickUpZones: [],
    programCalendarRules: "",
    programCode: "",
    programDescription: "",
    programName: "",
    serviceCalendarId: "",
    puExtraDuration: 0,
    doExtraDuration: 0,
    autoScheduleFailAction: AutoScheduleFailAction.NoAction,
};

export const defaultCoords = { lat: 0, lng: 0 };

export const defaultTripStatusCoords = {
    pickUpCoords: defaultCoords,
    dropOffCoords: defaultCoords,
    vehicleCoords: defaultCoords,
    riderCoords: defaultCoords,
};

export const defaultTripStatus = {
    runId: "",
    username: "",
    status: "",
    rideStatus: 0,
    requesterEmail: "",
    pickUpTime: "",
    dropOffTime: "",
    riderId: "",
    serviceId: "",
    tripId: "",
    tripUuid: "",
    tripURL: "",
    pickUpAddress: "",
    dropOffAddress: "",
    scheduledPickUpTimeEpoch: "",
    actualPickUpArrivalEpoch: "",
    estimatedPickUpArrivalEpoch: "",
    scheduledDropOffTimeEpoch: "",
    actualDropOffTimePerformEpoch: "",
    estimatedDropOffArrivalEpoch: "",
    estimatedDropOffTimePerformEpoch: "",
    coords: defaultTripStatusCoords,
    tripRating: 0,
};

export const defaultGPS = {
    ...statusFields,
    dateStamp: "",
    lat: 0,
    lng: 0,
    radioID: "",
    speed: 0,
};

export const defaultFormattedGPS = {
    speed: 0,
    busId: 0,
    driver: "",
    vehicle: "",
    route: "",
    updated: "",
    lat: 0,
    lng: 0,
};

export const defaultGeozone = {
    Color: "",
    ErrorMessage: "",
    ID: 0,
    IsActive: false,
    Name: "",
};

export const defaultFleetManager = {
    ...statusFields,
    assignedTrips: [],
    driverID: 0,
    driverName: "",
    driverPhone: "",
    endTime: "",
    endTimeJson: "",
    endTimeUTC: "",
    fleetmanagerID: 0,
    gps: defaultGPS,
    nameWithTP: "",
    odometerEnd: 0,
    odometerStart: 0,
    preferredZone: defaultGeozone,
    routeId: 0,
    routeName: "",
    savedToHistory: false,
    startTime: "",
    startTimeJson: "",
    startTimeUTC: "",
    stopsExecuted: 0,
    stopsNoShow: 0,
    stopsPending: 0,
    stopsTotal: 0,
    vehicleID: 0,
    vehicleMake: "",
    vehicleModel: "",
    vehicleName: "",
    vehicleSeats: 0,
    vehicleWCSeats: 0,
    vehicleYear: "",
};

export const defaultVehicle = {
    ...statusFields,
    comment: "",
    currentDriverFirstName: "",
    currentDriverId: 0,
    currentDriverLastName: "",
    customID: 0,
    databaseID: 0,
    defaultDriverFirstName: "",
    defaultDriverId: 0,
    defaultDriverLastName: "",
    fixedAmbulatorySeats: 0,
    fixedWheelchairSpots: 0,
    inUse: false,
    licenseExpirationDate: "",
    licensePlateNumber: "",
    locationEnd: defaultPlace,
    locationStart: defaultPlace,
    make: "",
    model: "",
    ratio: 0,
    vin: "",
    year: "",
    name: "",
    seatedCap: 0,
    totalCap: 0,
};

export const defaultDriver = {
    ...statusFields,
    customID: 0,
    databaseID: 0,
    firstName: "",
    isActive: false,
    lastName: "",
};
