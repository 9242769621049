import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "src/redux";
import { authAPI } from "src/modules/auth/redux/authSlice";
import {
    MDT,
    Device,
    Vehicle,
    SMSRequest,
    PassioRun,
    PassioBus,
    RideStatus,
    OnDemandTrip,
    PassioDriver,
    Fleetmanager,
    PassioGeofence,
    GeofenceRequest,
    CancelTripRequest,
    RemoveTripRequest,
    ScheduleTripRequest,
    CancelReservationRequest,
    GeoEligibilityCheckRequest,
    CancelTripOfReservationRequest,
    GetDriverAndVehicleDetailsResponse,
    GetDriverAndVehicleDetailsRequest,
    FormattedGPS,
} from "../types";
import { TripResponse, TripRequest } from "../pages/TripRequest/types";
import { TripHistory } from "../types";
import {
    EntityResponse,
    ListResponse,
    PassioService,
    TripScheduleStatus,
} from "src/types";
import {
    device,
    SESRegion,
    navigatorAPIUrl,
    dateTimeFormat,
    SESCognitoPoolId,
} from "src/utils/constants";
import { validate } from "uuid";
import {
    isParaplanError,
    validateNavigatorStatus,
    validateParaplanStatus,
} from "src/utils/helpers";
import * as formatters from "./helpers";
import * as rideStatusFormatters from "../pages/details/helpers";
import { RiderExperience } from "../pages/details/types";
import { SES, SendEmailCommandInput } from "@aws-sdk/client-ses";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
import API from "src/redux/api";
import moment from "moment";

// Define a type for the slice state
interface TripState {
    returnTripType: "One-Way" | "Round Trip" | "Callback";
    devices: Device[];
    mdts: MDT[];
}

// Define the initial state using that type
const initialState: TripState = {
    returnTripType: "One-Way",
    devices: [],
    mdts: [],
};

export const tripsAPI = API.injectEndpoints({
    endpoints: (build) => ({
        getTrips: build.query<TripHistory[], string | number>({
            queryFn: async (id, api, extra, baseQuery) => {
                const state = api.getState() as RootState;
                const { service, client } = state.auth;
                if (service === PassioService.navigator) {
                    const request = {
                        url: "/ondemand/tdb/get/",
                        method: "post",
                        body: {
                            type: "onDemandTrip",
                            userId: client.agencyId,
                            filter: { archive: 0, riderId: id },
                        },
                        validateStatus: validateNavigatorStatus,
                    };

                    const { data, error } = await baseQuery(request);
                    if (error) return { error };
                    const trips = formatters.formatTripHistoryResponse(
                        data as OnDemandTrip[]
                    );
                    return { data: trips };
                }

                const request = {
                    url: "TripService/TripsByClient",
                    params: { ClientID: id },
                    validateStatus: validateParaplanStatus,
                };

                const { data, error } = await baseQuery(request);
                if (error) return { error };

                const trips = formatters.sortTripHistory(
                    (data as ListResponse<TripHistory>).list
                );
                return { data: trips };
            },
        }),
        requestTrip: build.mutation<TripResponse, TripRequest>({
            query: (data) => ({
                url: "TripService/AddTripRequest",
                body: [data], //endpoint expects an array of trips
                method: "post",
                validateStatus: (response, result) => {
                    return (
                        response.status === 200 &&
                        result.success &&
                        result?.AddedTrips?.length > 0
                    );
                },
            }),
        }),
        checkGeographicEligibilty: build.mutation<
            PassioGeofence[],
            GeoEligibilityCheckRequest
        >({
            query: (data) => ({
                url: `${navigatorAPIUrl}/${data.passioAgency}/passioTransit/geofence/at/${data.latitude},${data.longitude}`,
                params: { accessToken: data.passioToken },
            }),
        }),
        getGeofence: build.mutation<PassioGeofence, GeofenceRequest>({
            query: (data) => ({
                url: `${navigatorAPIUrl}/${data.passioAgency}/passioTransit/geofence/${data.id}/`,
                params: { accessToken: data.passioToken },
            }),
        }),
        cancelTripOfReservation: build.mutation<
            any,
            CancelTripOfReservationRequest
        >({
            query: (data) => ({
                url: "ReservationService/CancelSingleTrip",
                body: data,
                method: "post",
                validateStatus: (response, result) => {
                    return response.status === 200 && result?.success;
                },
            }),
        }),
        cancelReservation: build.mutation<any, CancelReservationRequest>({
            query: (data) => ({
                url: "ReservationService/CancelReservation/",
                body: data,
                method: "post",
                validateStatus: (response, result) => {
                    return response.status === 200 && result?.success;
                },
            }),
        }),
        cancelTrip: build.mutation<any, CancelTripRequest>({
            queryFn: async (
                { id, reason, lat = 0, lng = 0 },
                api,
                extra,
                baseQuery
            ) => {
                const state = api.getState() as RootState;
                const { service } = state.auth;

                if (service === PassioService.navigator) {
                    const request = {
                        url: "/ondemand/tdb/update/",
                        method: "post",
                        body: {
                            type: "onDemandTrip",
                            id,
                            runId: null,
                            canceled: true,
                            cancelReason: reason,
                            cancelDate: moment().format(dateTimeFormat),
                        },
                        validateStatus: validateNavigatorStatus,
                    };

                    const { data, error } = await baseQuery(request);
                    if (error) return { error };
                    return { data: data as any };
                }

                const request = {
                    url: `TripService/Trips/AddAction2/${id}/${TripScheduleStatus.Cancelled}`,
                    params: {
                        Timestamp: moment().local().unix(),
                        Value: reason,
                        lat,
                        lng,
                    },
                    validateStatus: validateParaplanStatus,
                };

                const { data, error } = await baseQuery(request);
                if (error) return { error };
                return { data: data as any };
            },
        }),
        removeTripRequest: build.mutation<any, RemoveTripRequest>({
            queryFn: async ({ id, reason }, api, extra, baseQuery) => {
                const state = api.getState() as RootState;
                const { service } = state.auth;

                if (service === PassioService.navigator) {
                    const request = {
                        url: "/ondemand/tdb/update/",
                        method: "post",
                        body: {
                            type: "onDemandTrip",
                            id,
                            archive: true,
                        },
                        validateStatus: validateNavigatorStatus,
                    };

                    const { data, error } = await baseQuery(request);
                    if (error) return { error };
                    return { data: data as any };
                }

                const request = {
                    url: "TripService/RemoveTripRequest",
                    method: "post",
                    params: {
                        TripStatus: reason,
                        Reason: reason,
                        NotifyRider: true,
                    },
                    body: { importTripID: id },
                    validateStatus: validateParaplanStatus,
                };

                const { data, error } = await baseQuery(request);
                if (error) return { error };
                return { data: data as any };
            },
        }),
        getTripStatus: build.query<RiderExperience, string>({
            queryFn: async (id, api, extra, baseQuery) => {
                const state = api.getState() as RootState;
                const { service, client, token, user } = state.auth;

                if (service === PassioService.navigator) {
                    // trip id could be a uuid which affects the response type, array or object
                    const isUUID = validate(id);
                    const request = {
                        url: "/ondemand/tdb/get/",
                        method: "post",
                        body: {
                            type: "onDemandTrip",
                            userId: client.agencyId,
                            ...(isUUID ? { filter: { tripGuid: id } } : { id }),
                        },
                    };

                    const { data, error } = await baseQuery(request);
                    if (error) return { error };

                    // if id was UUID, we filtered and got an array back
                    const trip = (
                        Array.isArray(data) ? data[0] : data
                    ) as OnDemandTrip;

                    let bus;

                    // load run and bus/vehicle if trip is scheduled
                    if (trip?.runId) {
                        const runRequest = {
                            url: "/ondemand/tdb/get/",
                            method: "post",
                            body: {
                                type: "run",
                                id: trip.runId,
                                userId: client.agencyId,
                            },
                        };
                        const { data: run } = await baseQuery(runRequest);
                        // only load bus if run fetch was successful
                        if ((run as PassioRun).busId) {
                            const busRequest = {
                                url: "/ondemand/tdb/get/",
                                method: "post",
                                body: {
                                    type: "bus",
                                    id: (run as PassioRun).busId,
                                    userId: client.agencyId,
                                },
                            };

                            const { data } = await baseQuery(busRequest);
                            if (data) bus = data as PassioBus;
                        }
                    }

                    const rideStatus =
                        rideStatusFormatters.formatNavigatorRiderExperience(
                            trip,
                            user,
                            bus
                        );
                    return { data: rideStatus };
                }

                const request = {
                    url: `RideService/Status`,
                    method: "post",
                    body: {
                        device,
                        token,
                        tripId: id,
                        v2: true,
                    },
                };

                const { data, error } = await baseQuery(request);
                if (error) return { error };
                else if (isParaplanError(data))
                    return { error: (data as any)?.errorMessage };

                const rideStatus =
                    rideStatusFormatters.formatParaplanRiderExperience(
                        (data as EntityResponse<RideStatus>).entity
                    );
                return { data: rideStatus };
            },
        }),
        getFleetManagers: build.query<Fleetmanager[], void>({
            queryFn: async (args, api, extraOptions, baseQuery) => {
                const agencyId = (api.getState() as RootState)?.auth.client
                    .agencyId;
                // note: always make sure this query reflects the same in dispatch for loading runs
                // autoschedule depends on it
                const date = moment().startOf("day").add(4, "h");
                const startDate = date.format(dateTimeFormat);
                const endDate = date.add(1, "day").format(dateTimeFormat);

                const request = {
                    url: "/ondemand/tdb/get/",
                    method: "post",
                    body: {
                        type: "run",
                        userId: agencyId,
                        filter: {
                            archive: 0,
                            outOfService: 0,
                            startDateTime: { "<=": endDate },
                            endDateTime: { ">": startDate },
                        },
                    },
                };

                const { data, error } = await baseQuery(request);
                if (error) return { error };

                const fleet = formatters.formatRuns(data as PassioRun[]);
                return { data: fleet };
            },
        }),
        getVehicles: build.query<Vehicle[], void>({
            queryFn: async (date, api, extraOptions, baseQuery) => {
                const agencyId = (api.getState() as RootState)?.auth.client
                    .agencyId;

                const request = {
                    url: "/ondemand/tdb/get/",
                    method: "post",
                    body: { type: "bus", userId: agencyId },
                };

                const { data, error } = await baseQuery(request);
                if (error) return { error };

                const vehicles = formatters.formatVehicles(data as PassioBus[]);
                return { data: vehicles };
            },
        }),
        getRun: build.query<PassioRun, string>({
            queryFn: async (id, api, extraOptions, baseQuery) => {
                const agencyId = (api.getState() as RootState)?.auth.client
                    .agencyId;

                const request = {
                    url: "/ondemand/tdb/get/",
                    method: "post",
                    body: {
                        type: "run",
                        userId: agencyId,
                        id,
                    },
                };

                const { data, error } = await baseQuery(request);
                if (error) return { error };
                return { data: data as PassioRun };
            },
        }),
        getDriverAndVehicleDetails: build.query<
            GetDriverAndVehicleDetailsResponse,
            GetDriverAndVehicleDetailsRequest
        >({
            queryFn: async (
                { busId, driverId },
                api,
                extraOptions,
                baseQuery
            ) => {
                const agencyId = (api.getState() as RootState)?.auth.client
                    .agencyId;
                const request = {
                    url: "/ondemand/tdb/get/",
                    method: "post",
                    body: { type: "driver", userId: agencyId, id: driverId },
                };

                const { data, error } = await baseQuery(request);
                if (error) return { error };

                const busRequest = {
                    url: "/ondemand/tdb/get/",
                    method: "post",
                    body: { type: "bus", userId: agencyId, id: busId },
                };

                const { data: busData, error: busError } = await baseQuery(
                    busRequest
                );

                const bus = busData as PassioBus;
                const yearRegex = /^(19|20)[\d]{2,2}$/;
                const year =
                    bus.yearOfManufacture &&
                    yearRegex.test(bus.yearOfManufacture)
                        ? bus.yearOfManufacture
                        : bus.yearOfRebuild && yearRegex.test(bus.yearOfRebuild)
                        ? bus.yearOfRebuild
                        : "";
                const model = bus.vehicleType || bus.model || "";

                if (busError) return { error: busError };
                return {
                    data: {
                        driverName: (data as PassioDriver).name,
                        vehicle: `${year} ${bus.name} ${model}`,
                    },
                };
            },
        }),
        getRequestedTrips: build.query<OnDemandTrip[], void>({
            queryFn: async (args, api, extra, baseQuery) => {
                const agencyId = (api.getState() as RootState)?.auth.client
                    .agencyId;

                const request = {
                    url: "/ondemand/tdb/get/",
                    method: "post",
                    body: {
                        type: "onDemandTrip",
                        userId: agencyId,
                        filter: {
                            archive: 0,
                            created: {
                                from: moment()
                                    .startOf("day")
                                    .format(dateTimeFormat),
                                to: moment()
                                    .endOf("day")
                                    .format(dateTimeFormat),
                            },
                        },
                    },
                };

                const { data, error } = await baseQuery(request);
                if (error) return { error };

                // Parse programs and fleet vehicles to format trips
                return { data: data as OnDemandTrip[] };
            },
        }),
        scheduleTrip: build.mutation<any, ScheduleTripRequest>({
            queryFn: async (
                { tripId, fleetManagerId, clientId },
                api,
                extra,
                baseQuery
            ) => {
                const agencyId = (api.getState() as RootState)?.auth.client
                    .agencyId;

                // add run to trip and update trip log
                const logRequest = {
                    url: "/ondemand/tdb/add/",
                    method: "post",
                    body: {
                        type: "onDemandTripActionHistory",
                        userId: agencyId,
                        riderId: clientId,
                        runId: fleetManagerId,
                        onDemandTripId: tripId,
                        onDemandActionId: TripScheduleStatus.Scheduled,
                        latitude: 0,
                        longitude: 0,
                    },
                };

                await baseQuery(logRequest);

                const request = {
                    url: "/ondemand/tdb/update/",
                    method: "post",
                    body: {
                        type: "onDemandTrip",
                        userId: agencyId,
                        id: tripId,
                        runId: fleetManagerId,
                    },
                };

                const { data, error } = await baseQuery(request);
                if (error) return { error };
                return { data };
            },
        }),
        sendEmail: build.mutation<any, SendEmailCommandInput>({
            queryFn: async (params) => {
                try {
                    const ses = new SES({
                        region: SESRegion,
                        credentials: fromCognitoIdentityPool({
                            identityPoolId: SESCognitoPoolId,
                            clientConfig: {
                                region: SESRegion,
                            },
                        }),
                    });
                    const data = await ses.sendEmail(params);
                    return { data: data as any };
                } catch (error: any) {
                    return { error };
                }
            },
        }),
        getBusLocation: build.query<
            FormattedGPS | FormattedGPS[],
            string | number | number[]
        >({
            queryFn: async () => {
                // for navigator, response will be updated via websocket,
                // so return empty array to create cache entry
                return { data: [] as FormattedGPS[] };
            },
            async onCacheEntryAdded(
                busId,
                {
                    getState,
                    updateCachedData,
                    cacheDataLoaded,
                    cacheEntryRemoved,
                }
            ) {
                await cacheDataLoaded;
                const state = getState() as RootState;
                const { token, client, service } = state.auth;

                // We only use sockets for navigator
                if (service === PassioService.paraplan) return;

                const url = new URL(navigatorAPIUrl);
                const protocol = url.protocol === "https:" ? "wss" : "ws";
                const ws = new WebSocket(
                    `${protocol}://${url.host}/?accessToken=${token}`
                );
                // query the location for all buses in the fleet pass the ids in as args because they may change
                ws.addEventListener("open", () => {
                    const request = {
                        subscribe: "location",
                        userId: client.agencyId,
                        filter: { busId, outOfService: 0 },
                    };
                    ws.send(JSON.stringify(request));
                });
                ws.addEventListener("message", (event) => {
                    const data = formatters.formatBusLocation(
                        JSON.parse(event.data)
                    );
                    updateCachedData((draft) => Object.assign(draft, data));
                });
                await cacheEntryRemoved;
                ws.close();
            },
        }),
        getDevices: build.query<Device[], string | number>({
            query: (userId) => ({
                url: "/ondemand/tdb/get/",
                method: "post",
                body: {
                    type: "device",
                    userId,
                    field: ["id", "name", "busId", "deviceSystemId"],
                },
            }),
        }),
        getMDTs: build.query<MDT[], void>({
            query: () => ({
                url: "/ondemand/tdb/get/",
                method: "post",
                body: { type: "deviceSystem", filter: { mdt: 1 } },
            }),
        }),
        updateMDT: build.mutation<any, string>({
            query: (deviceId) => ({
                url: "/ondemand/tdb/add/",
                method: "post",
                body: {
                    type: "deviceEvent",
                    name: "ODManifestUpdated",
                    deviceId,
                },
            }),
        }),
        sendSMS: build.mutation<any, SMSRequest>({
            query: (data) => ({
                url: "https://app.passiotransit.com/api/v1/Devices/SendSmsMessage",
                method: "get",
                params: data,
            }),
            transformResponse: () => {}, // prevent response from expiring token
        }),
        getDrivers: build.query<PassioDriver[], void>({
            queryFn: async (arg, api, extraOptions, baseQuery) => {
                const agencyId = (api.getState() as RootState)?.auth.client
                    .agencyId;

                const request = {
                    url: "/ondemand/tdb/get/",
                    method: "post",
                    body: {
                        type: "driver",
                        userId: agencyId,
                    },
                };

                const { data, error } = await baseQuery(request);
                if (error) return { error };
                return { data: data as PassioDriver[] };
            },
        }),
    }),
    overrideExisting: false,
});

const tags = ["trips", "tripStatuses"];
tripsAPI.enhanceEndpoints({
    addTagTypes: tags,
    endpoints: {
        getTrips: {
            providesTags: ["trips"],
        },
        cancelTrip: {
            invalidatesTags: tags,
        },
        cancelReservation: {
            invalidatesTags: tags,
        },
        removeTripRequest: {
            invalidatesTags: tags,
        },
    },
});

const tripSlice = createSlice({
    name: "trips",
    initialState,
    reducers: {
        save: (state, { payload }) => {
            return { ...state, ...payload };
        },
        clear: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            authAPI.endpoints.getConfig.matchFulfilled,
            (state, { payload }) => {
                let gCost4 = payload?.gCost4;
                if (gCost4 === "Roundtrip: Call for return") {
                    state.returnTripType = "Callback";
                }
                if (gCost4 === "Roundtrip: Pick up at ->") {
                    state.returnTripType = "Round Trip";
                }
            }
        );
        builder.addMatcher(
            tripsAPI.endpoints.getDevices.matchFulfilled,
            (state, { payload }) => {
                state.devices = payload;
            }
        );
        builder.addMatcher(
            tripsAPI.endpoints.getMDTs.matchFulfilled,
            (state, { payload }) => {
                state.mdts = payload;
            }
        );
    },
});

// Other code such as selectors can use the imported `RootState` type
export const tripsSelector = (state: RootState) => state.trips;
export const tripActions = tripSlice.actions;

export default tripSlice.reducer;
