import { RiderExperience } from "src/modules/trips/pages/details/types";
import { SESSenderEmail, USDateTimeFormat } from "./constants";
import moment from "moment";
import {
    GetDriverAndVehicleDetailsResponse,
    OnDemandTripRequest,
    TripHistory,
} from "src/modules/trips/types";
import { TripRequestFormValues } from "src/modules/trips/pages/TripRequest/types";
import { SignupFormData } from "src/modules/auth/pages/signup/types";
import { Client, Program } from "src/modules/auth/types";

/**
 * @see https://docs.aws.amazon.com/sdk-for-javascript/v2/developer-guide/ses-examples-sending-email.html
 */

const url = new URL(window.location.href);

export enum EmailType {
    DriverArrived = "driverArrived",
    TripNotApproved = "tripNotApproved",
    TripCancelled = "tripCancelled",
    TripAccepted = "tripAccepted",
    TripAssigned = "tripAssigned",
    NewAccount = "newAccount",
}

export function formatDisplayTime(time: string | Date = "") {
    return moment(time).format(USDateTimeFormat);
}

export function formatDriverArrivedEmail({
    requesterEmail = "",
    pickUpTime = "",
    dropOffAddress = "",
}: RiderExperience) {
    const time = formatDisplayTime(pickUpTime);
    return {
        Destination: {
            BccAddresses: ["tim.hibbard+puarrive@passiotech.com"],
            ToAddresses: [requesterEmail],
        },
        Message: {
            Body: {
                Text: {
                    Charset: "UTF-8",
                    Data: `The driver for your ${time} trip to ${dropOffAddress} has arrived.\nThis is an automated message. Please do not reply. `,
                },
            },
            Subject: {
                Charset: "UTF-8",
                Data: `${time} Driver has arrived`,
            },
        },
        Source: SESSenderEmail,
    };
}

export function formatTripNotApprovedEmail({
    requesterEmail = "",
    username = "",
    pickUpTime = "",
    dropOffTime = "",
    pickUpAddress = "",
    dropOffAddress = "",
    tripUuid = "",
    riderId = "",
}: RiderExperience) {
    const time = formatDisplayTime(pickUpTime);
    return {
        Destination: {
            ToAddresses: [requesterEmail],
        },
        Message: {
            Body: {
                Text: {
                    Charset: "UTF-8",
                    Data: `Hello ${username}, \nYour trip request has not been approved for the following reason: \n{reason}\n Unfortunately, this email is an automated notification, which is unable to receive replies. \nWe’re happy to help you with any questions or concerns you may have. \nHere were the details of your request:\n Pick Up Time: ${time}\nPick Up Address: ${pickUpAddress}\nDrop Off Time: ${formatDisplayTime(
                        dropOffTime
                    )}\nDrop Off Address: ${dropOffAddress}\nRequestID: ${tripUuid}\nClientID: ${riderId}`,
                },
            },
            Subject: {
                Charset: "UTF-8",
                Data: `[NOT APPROVED] Trip Request for ${time}`,
            },
        },
        Source: SESSenderEmail,
    };
}

export function formatTripCancelledEmail(
    {
        pickUpTime = "",
        dropOffTime = "",
        pickUpPlace,
        dropOffPlace,
        tripUuid,
    }: TripHistory,
    client: Client,
    reason = ""
) {
    const time = formatDisplayTime(pickUpTime);
    return {
        Destination: {
            BccAddresses: ["timhibbard+cancelledTrip@passiotech.com"],
            ToAddresses: [client.email],
        },
        Message: {
            Body: {
                Text: {
                    Charset: "UTF-8",
                    Data: `Hello ${
                        client.name
                    }, \nYour trip request has been cancelled for the following reason: \n${reason}\nUnfortunately, this email is an automated notification, which is unable to receive replies. \nWe’re happy to help you with any questions or concerns you may have.  \nHere were the details of your request:\nPick Up Time: ${time}\nPick Up Address: ${
                        pickUpPlace.description
                    }\nDrop Off Time: ${formatDisplayTime(
                        dropOffTime
                    )}\nDrop Off Address: ${
                        dropOffPlace.description
                    }\nRequestID: ${tripUuid}\nClientID: ${client.id} `,
                },
            },
            Subject: {
                Charset: "UTF-8",
                Data: ` [CANCELLED] Trip Request for ${time}`,
            },
        },
        Source: SESSenderEmail,
    };
}

export function formatTripApprovedEmail({
    requesterEmail = "",
    username = "",
    pickUpTime = "",
    dropOffTime = "",
    pickUpAddress = "",
    dropOffAddress = "",
    tripUuid = "",
    tripURL = "",
    riderId = "",
}: RiderExperience | OnDemandTripRequest | any) {
    const time = formatDisplayTime(pickUpTime);
    const dOTime = formatDisplayTime(dropOffTime);
    return {
        Destination: {
            ToAddresses: [requesterEmail],
        },
        Message: {
            Body: {
                Text: {
                    Charset: "UTF-8",
                    Data: `Hello ${username}, \nYour trip request has been approved.\nYou will be picked up at approx: ${time}\nYou will be dropped off at approx: ${dOTime}\nView Status: ${tripURL} \nUnfortunately, this email is an automated notification, which is unable to receive replies. \nWe’re happy to help you with any questions or concerns you may have. \nHere were the details of your request:\n Pick Up Time: ${time}\nPick Up Address: ${pickUpAddress}\nDrop Off Time: ${dOTime}\nDrop Off Address: ${dropOffAddress}\nRequestID: ${tripUuid}\nClientID: ${riderId} `,
                },
            },
            Subject: {
                Charset: "UTF-8",
                Data: `[APPROVED] Trip Request for ${time}`,
            },
        },
        Source: SESSenderEmail,
    };
}

export function formatTripAssignedEmail(
    {
        requesterEmail = "",
        username = "",
        pickUpTime = "",
        dropOffTime = "",
        pickUpAddress = "",
        dropOffAddress = "",
        tripUuid = "",
        tripURL = "",
        riderId = "",
    }: RiderExperience,
    { driverName = "", vehicle = "" }: GetDriverAndVehicleDetailsResponse
) {
    const time = formatDisplayTime(pickUpTime);
    const dOTime = formatDisplayTime(dropOffTime);
    return {
        Destination: {
            ToAddresses: [requesterEmail],
        },
        Message: {
            Body: {
                Text: {
                    Charset: "UTF-8",
                    Data: `Hello ${username}, \nYour trip request has been approved.\nYou will be picked up at approx: ${time}\nYou will be dropped off at approx: ${dOTime}\nYour driver will be ${driverName}, in a ${vehicle}\nView Status: ${tripURL} \nUnfortunately, this email is an automated notification, which is unable to receive replies. \nWe’re happy to help you with any questions or concerns you may have.\nHere were the details of your request:\nPick Up Time: ${time}\nPick Up Address: ${pickUpAddress}\nDrop Off Time: ${dOTime}\nDrop Off Address: ${dropOffAddress}\nRequestID: ${tripUuid}\nClientID: ${riderId} `,
                },
            },
            Subject: {
                Charset: "UTF-8",
                Data: ` [ASSIGNED] Trip Request for ${time}`,
            },
        },
        Source: SESSenderEmail,
    };
}

export function formatTripNotAutoApprovedEmail(
    trip: TripRequestFormValues,
    dispatcherEmail = "",
    returnTripId = ""
) {
    const time = formatDisplayTime(trip.pickUpTime);
    const dOTime = formatDisplayTime(trip.dropOffTime);
    return {
        Destination: {
            ToAddresses: [dispatcherEmail],
        },
        Message: {
            Body: {
                Text: {
                    Charset: "UTF-8",
                    Data: `Go to Dispatch to approve or reject this request\n${url.origin} \nHere are the details of request:\nPick Up Time: ${time}\nPick Up Address: ${trip.pickUpPlace.description}\nDrop Off Time: ${dOTime}\nDrop Off Address: ${trip.dropOffPlace.description}\nReturn Trip ID: ${returnTripId}\nRequestID: ${trip.importTripID}\nClientID: ${trip.clientID}\nAn email with this same information has also been sent to:\n${trip.clientEmail}\n${trip.requesterEmail}`,
                },
            },
            Subject: {
                Charset: "UTF-8",
                Data: `[NOT APPROVED] Trip Request for ${time}`,
            },
        },
        Source: SESSenderEmail,
    };
}

export function formatNewAccountEmail(
    { address, firstName = "", lastName = "", email = "" }: SignupFormData,
    program: Program,
    dispatcherEmail = ""
) {
    const username = `${firstName} ${lastName}`;
    return {
        Destination: {
            ToAddresses: [email],
            BccAddresses: [
                "timhibbard+newrider@passiotech.com",
                dispatcherEmail,
            ],
        },
        Message: {
            Body: {
                Text: {
                    Charset: "UTF-8",
                    Data: `Hello ${username}, \nYour new transportation account has been created.\nPlease use the following credentials the next time you need to request transportation:\nUsername: ${username}\nThe website to request transportation is ${url.origin}\nDo not share this information with anybody else.\nProgram: ${program.programName}\nHome: ${address?.description} \nUnfortunately, this email is an automated notification, which is unable to receive replies.
                   `,
                },
            },
            Subject: {
                Charset: "UTF-8",
                Data: `New account created for transportation`,
            },
        },
        Source: SESSenderEmail,
    };
}

export const autoScheduleFailedEmail = `We regret to inform you that your requested trip cannot be scheduled at this time due to current availability constraints. We apologize for any inconvenience this may cause. \n Please try again later or contact our support team for further assistance.`;

export function formatTripNotAutoScheduledEmail(email = "") {
    return {
        Destination: {
            ToAddresses: [email],
        },
        Message: {
            Body: {
                Text: {
                    Charset: "UTF-8",
                    Data: autoScheduleFailedEmail,
                },
            },
            Subject: {
                Charset: "UTF-8",
                Data: "Trip Scheduling Failure Notification",
            },
        },
        Source: SESSenderEmail,
    };
}

export interface DispatcherEmailProps {
    pickUpAddress: string;
    dropOffAddress: string;
    pickUpTime: string;
    dropOffTime: string;
    riderId: string | number;
    tripUuid: string;
    email: string;
}

export function formatTripNotAutoScheduledDispatcherEmail(
    data: DispatcherEmailProps
) {
    const time = formatDisplayTime(data.pickUpTime);
    const dOTime = formatDisplayTime(data.dropOffTime);
    return {
        Destination: {
            ToAddresses: [data.email],
        },
        Message: {
            Body: {
                Text: {
                    Charset: "UTF-8",
                    Data: `Go to Dispatch to approve or reject this request\n${url.origin} \nHere are the details of request:\nPick Up Time: ${time}\nPick Up Address: ${data.pickUpAddress}\nDrop Off Time: ${dOTime}\nDrop Off Address: ${data.dropOffAddress}\n RequestID: ${data.tripUuid}\nClientID: ${data.riderId}\n`,
                },
            },
            Subject: {
                Charset: "UTF-8",
                Data: `[NOT APPROVED] Trip Request for ${time}`,
            },
        },
        Source: SESSenderEmail,
    };
}

export function formatPasswordRecoveryEmail(email = "", code = 0) {
    return {
        Destination: {
            ToAddresses: [email],
        },
        Message: {
            Body: {
                Text: {
                    Charset: "UTF-8",
                    Data: `Your account verification code is ${code}`,
                },
            },
            Subject: {
                Charset: "UTF-8",
                Data: "Account Verification",
            },
        },
        Source: SESSenderEmail,
    };
}
