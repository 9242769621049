import { useState, useMemo } from "react";
import {
    Box,
    Card,
    Stack,
    Button,
    Alert,
    MenuItem,
    Container,
    FormLabel,
    Typography,
    FormControl,
    ButtonGroup,
    useTheme,
    useMediaQuery,
} from "@mui/material";
import {
    AccountCircleOutlined,
    KeyOutlined,
    PersonAdd,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useAppDispatch, useAppSelector, useSelectedClient } from "src/hooks";
import { authSelector, logout } from "../auth/redux/authSlice";
import { Formik, Form } from "formik";
import {
    FormInput,
    GoogleMap,
    FormCheckbox,
    FormDatePicker,
    PhoneNumberInput,
    PlacesAutocomplete,
} from "src/components";
import { capitalize } from "lodash";
import {
    initProfile,
    formatParaplanProfile,
    formatNavigatorRiderAccount,
} from "./helpers";
import { useTitle } from "react-use";
import { authAPI } from "../auth/redux/authSlice";
import { enqueueSnackbar } from "notistack";
import { getProfileSchema } from "./schema";
import { useParams, useNavigate } from "react-router-dom";
import { omit } from "lodash";
import { ProfileFormData } from "./types";
import { PassioService } from "src/types";
import moment from "moment";
import Programs from "./components/Programs";
import ChangePasswordModal from "./components/ChangePasswordModal";
import { isValidCoord } from "src/utils/helpers";

interface Props {
    title?: string;
    returnTo?: string;
}

const Profile = ({ title = "Account", returnTo = "/trips" }: Props) => {
    useTitle(title);
    const { id = "" } = useParams(); // managers can view the profile of a rider
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [open, setOpen] = useState(false);

    const { config, availableFundingSources, isManager, programs, service } =
        useAppSelector(authSelector);
    const [updateProfile, { isLoading }] = authAPI.useUpdateProfileMutation();
    const [passioLogout, { isLoading: loggingOut }] =
        authAPI.usePassioLogoutMutation();

    const theme = useTheme();
    const isMobileDevice = useMediaQuery(theme.breakpoints.down("md"));
    const isNavigator = service === PassioService.navigator;

    const client = useSelectedClient(id);
    const newRider = isManager && !id;
    const profileSchema = getProfileSchema(newRider, isNavigator);

    const initialValues = useMemo(
        () =>
            initProfile(
                client,
                programs,
                availableFundingSources,
                newRider,
                isNavigator
            ),
        [client, availableFundingSources, newRider, programs, isNavigator]
    );

    const availablePrograms = useMemo(() => {
        return programs.filter((program) => !program.membershipIsRequired);
    }, [programs]);

    async function handleUpdate(values: ProfileFormData) {
        const request = isNavigator
            ? formatNavigatorRiderAccount(values)
            : formatParaplanProfile(values);

        try {
            await updateProfile(request).unwrap();

            if (isNavigator) {
                await passioLogout().unwrap();
            }

            enqueueSnackbar("Profile updated successfully please login", {
                variant: "success",
            });

            // reset location state to prevent auto redirects after login
            navigate(
                {
                    pathname: "/login",
                    search: isNavigator
                        ? `service=${PassioService.navigator}`
                        : undefined,
                },
                { replace: true, state: {} }
            );

            // add a little timeout to ensure service reset only after redirect
            setTimeout(() => dispatch(logout()), 200);
        } catch (error) {
            enqueueSnackbar("Sorry, an error occured. Please try again", {
                variant: "error",
            });
        }
    }

    // Paraplan Mobility managers only, create a new rider
    async function handleRider(values: ProfileFormData) {
        // get full program details
        try {
            const program = programs?.find(
                (program) => program.programName === values.defaultProgramName
            );

            const request = {
                ...omit(values, ["firstName", "lastName", "birthdate"]),
                id: "new", // creates client record only
                name: `${values.firstName} ${values.lastName}`,
                birthdateEpoch: moment(values.birthdate).unix(),
                home: { ...values.home, lat: 0, lng: 0 },
                programs: [
                    {
                        databaseID: program?.databaseID,
                        programName: program?.programName,
                        membershipId: values.defaultProgramMembershipID,
                    },
                ],
            };

            await updateProfile(request).unwrap();
            enqueueSnackbar("Rider created successfully", {
                variant: "success",
            });

            navigate(returnTo);
        } catch (error) {
            enqueueSnackbar("Sorry, an error occured. Please try again", {
                variant: "error",
            });
        }
    }

    return (
        <Container maxWidth="lg" sx={{ p: 1.5 }}>
            <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                justifyContent="space-between"
                my={4}
            >
                <Stack direction="row" spacing={1} alignItems="center">
                    {newRider ? (
                        <PersonAdd fontSize="large" />
                    ) : (
                        <AccountCircleOutlined fontSize="large" />
                    )}
                    <Typography variant="h4" component="h1">
                        {title}
                    </Typography>
                </Stack>

                {/* Only users can change their passwords, managers may view and update accounts*/}
                {!isManager && (
                    <Button
                        startIcon={<KeyOutlined />}
                        onClick={() => setOpen(true)}
                    >
                        Change password
                    </Button>
                )}
            </Stack>

            <Alert severity="warning" sx={{ my: 2 }}>
                You will have to login again after updating your account details
            </Alert>

            <Formik
                initialValues={initialValues}
                validationSchema={profileSchema}
                onSubmit={newRider ? handleRider : handleUpdate}
            >
                {({ values }) => (
                    <Form>
                        <Stack spacing={4}>
                            {/* Agency Profile */}
                            <Card variant="elevation" sx={{ p: 1.5 }}>
                                <FormControl
                                    component="fieldset"
                                    sx={{ mb: 4, display: "flex", gap: 2 }}
                                >
                                    <FormLabel
                                        component="legend"
                                        sx={{
                                            mb: 2,
                                            fontSize: 20,
                                            color: "primary.main",
                                        }}
                                    >
                                        {capitalize(config?.AgencyName)} profile
                                    </FormLabel>

                                    <Stack
                                        spacing={2}
                                        direction={{
                                            xs: "column",
                                            md: "row",
                                        }}
                                    >
                                        <FormInput
                                            name="firstName"
                                            label="First Name"
                                            sx={{ flexGrow: 1 }}
                                        />
                                        <FormInput
                                            name="lastName"
                                            label="Last Name"
                                            sx={{ flexGrow: 1 }}
                                        />
                                    </Stack>

                                    <Stack
                                        spacing={2}
                                        direction={{
                                            xs: "column",
                                            md: "row",
                                        }}
                                    >
                                        <FormDatePicker
                                            format="MM/dd/yyyy"
                                            name="birthdate"
                                            label="Birthday"
                                            sx={{ flexGrow: 1 }}
                                        />
                                        <PhoneNumberInput
                                            type="tel"
                                            name="phone"
                                            label="Phone"
                                            sx={{ flexGrow: 1 }}
                                            autoComplete="tel"
                                        />
                                        {!isNavigator && (
                                            <FormInput
                                                type="email"
                                                name="email"
                                                label="Email"
                                                sx={{ flexGrow: 1 }}
                                            />
                                        )}
                                    </Stack>
                                </FormControl>
                            </Card>

                            {/* Accessibility */}
                            <Card variant="elevation" sx={{ p: 1.5 }}>
                                <FormControl
                                    component="fieldset"
                                    sx={{ mb: 4, display: "flex", gap: 2 }}
                                >
                                    <FormLabel
                                        component="legend"
                                        sx={{
                                            mb: 2,
                                            fontSize: 20,
                                            color: "primary.main",
                                        }}
                                    >
                                        Accessibility
                                    </FormLabel>

                                    <Stack
                                        spacing={1}
                                        direction={{
                                            xs: "column",
                                            md: "row",
                                        }}
                                    >
                                        <FormCheckbox
                                            name="accessibility.hasPCA"
                                            label="I have a personal care attendant"
                                            sx={{ flexGrow: 1 }}
                                        />

                                        <FormCheckbox
                                            name="accessibility.usesWheelchair"
                                            label="I would like an ADA accessible vehicle"
                                            sx={{ flexGrow: 1 }}
                                        />
                                        <FormInput
                                            name="accessibility.wheelChairType"
                                            label="Wheelchair Type"
                                            sx={{ flexGrow: 1 }}
                                        />
                                    </Stack>
                                </FormControl>
                            </Card>

                            {/* Connect Rider */}
                            {isNavigator ? (
                                <Programs
                                    name="programs"
                                    programs={availablePrograms}
                                />
                            ) : (
                                <Card variant="elevation" sx={{ p: 1.5 }}>
                                    <FormControl
                                        component="fieldset"
                                        sx={{ mb: 4, display: "flex", gap: 2 }}
                                    >
                                        <FormLabel
                                            component="legend"
                                            sx={{
                                                mb: 2,
                                                fontSize: 20,
                                                color: "primary.main",
                                            }}
                                        >
                                            Connect Rider
                                        </FormLabel>

                                        <Stack
                                            spacing={1}
                                            direction={{
                                                xs: "column",
                                                md: "row",
                                            }}
                                        >
                                            {!newRider && (
                                                <FormInput
                                                    name="id"
                                                    label="Passio Client ID"
                                                    sx={{ flexGrow: 1 }}
                                                    inputProps={{
                                                        readOnly: true,
                                                    }}
                                                />
                                            )}

                                            <FormInput
                                                select
                                                name="defaultProgramName"
                                                label="Default Program"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                SelectProps={{
                                                    native: isMobileDevice,
                                                }}
                                                sx={{ flexGrow: 1 }}
                                            >
                                                {availableFundingSources.map(
                                                    (program) => {
                                                        const props = {
                                                            key: program,
                                                            value: program,
                                                        };
                                                        return isMobileDevice ? (
                                                            <option {...props}>
                                                                {program}
                                                            </option>
                                                        ) : (
                                                            <MenuItem
                                                                {...props}
                                                            >
                                                                {program}
                                                            </MenuItem>
                                                        );
                                                    }
                                                )}
                                            </FormInput>

                                            <FormInput
                                                name="defaultProgramMembershipID"
                                                label="Membership ID"
                                                sx={{ flexGrow: 1 }}
                                            />
                                        </Stack>
                                    </FormControl>
                                </Card>
                            )}

                            {/*Paraplan  Home Address */}
                            {isManager && (
                                <Card variant="elevation" sx={{ p: 1.5 }}>
                                    <FormControl
                                        component="fieldset"
                                        sx={{ mb: 4, display: "flex", gap: 2 }}
                                    >
                                        <FormLabel
                                            component="legend"
                                            sx={{
                                                mb: 2,
                                                fontSize: 20,
                                                color: "primary.main",
                                            }}
                                        >
                                            Home Address
                                        </FormLabel>

                                        <Stack
                                            spacing={1}
                                            direction={{
                                                xs: "column",
                                                md: "row",
                                            }}
                                        >
                                            <FormInput
                                                name="home.address1"
                                                label="Address"
                                                sx={{ flexGrow: 1 }}
                                            />
                                            <FormInput
                                                name="home.address2"
                                                label="Apt, Unit, Suite, etc"
                                                sx={{ flexGrow: 1 }}
                                            />
                                        </Stack>

                                        <Stack
                                            spacing={1}
                                            direction={{
                                                xs: "column",
                                                md: "row",
                                            }}
                                        >
                                            <FormInput
                                                name="home.city"
                                                label="City"
                                                sx={{ flexGrow: 1 }}
                                            />
                                            <FormInput
                                                name="home.state"
                                                label="State"
                                                sx={{ flexGrow: 1 }}
                                            />

                                            <FormInput
                                                name="home.zip"
                                                label="Zip"
                                                sx={{ flexGrow: 1 }}
                                            />
                                        </Stack>

                                        {/* only show map if coords are available */}
                                        {!isManager &&
                                            isValidCoord(values.home) && (
                                                <GoogleMap
                                                    pickUpCoords={{
                                                        lat:
                                                            values.home.lat ||
                                                            0,
                                                        lng:
                                                            values.home.lng ||
                                                            0,
                                                    }}
                                                    sx={{
                                                        height: 450,
                                                        width: "100%",
                                                        my: 2,
                                                    }}
                                                />
                                            )}
                                    </FormControl>
                                </Card>
                            )}

                            {isNavigator && (
                                // always include the google map when using he places autocomplete,
                                <Box>
                                    <PlacesAutocomplete
                                        label="Address"
                                        name="address"
                                    />

                                    {isValidCoord({
                                        lat: values.address?.latitude,
                                        lng: values.address?.longitude,
                                    }) && (
                                        <GoogleMap
                                            pickUpCoords={{
                                                lat:
                                                    values?.address?.latitude ||
                                                    0,
                                                lng:
                                                    values?.address
                                                        ?.longitude || 0,
                                            }}
                                            sx={{
                                                height: 450,
                                                width: "100%",
                                                my: 2,
                                            }}
                                        />
                                    )}
                                </Box>
                            )}
                        </Stack>

                        {/* Actions */}
                        <Box maxWidth="sm" mx="auto" my={4}>
                            <ButtonGroup
                                fullWidth
                                variant="contained"
                                size="large"
                            >
                                <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    sx={{ py: 1.25 }}
                                    loading={isLoading || loggingOut}
                                >
                                    Update
                                </LoadingButton>

                                {id && isManager && (
                                    <Button
                                        color="secondary"
                                        onClick={() => navigate(-1)}
                                    >
                                        Close
                                    </Button>
                                )}
                            </ButtonGroup>
                        </Box>
                    </Form>
                )}
            </Formik>

            {/* change password modal */}
            <ChangePasswordModal open={open} onClose={() => setOpen(false)} />
        </Container>
    );
};

export default Profile;
