import { v4 as uuidv4 } from "uuid";
import { dateTimeFormat, device, utcOffset } from "src/utils/constants";
import { defaultClient, defaultParsedAddress } from "src/utils/defaultData";
import {
    dateFromJson,
    getGeoCodedAddress,
    getGeolocation,
    getParsedAddress,
    getTravelDuration,
    JSONToString,
} from "src/utils/helpers";
import type {
    User,
    Client,
    Program,
    CalendarRule,
    ParsedProgramCalendarRules,
} from "src/modules/auth/types";
import type {
    TripRequestFormValues,
    SelectionType,
    RequestedPlace,
} from "./types";
import { OnDemandTripRequest } from "../../types";
import { omit } from "lodash";
import { TimeTypes } from "src/types";
import { ParsedAddress } from "src/components/forms/types";
import moment from "moment";

export const OUT_OF_ZONE_ERROR = "Sorry address is not in active service area";
export const SAME_ADDRESS_ERROR =
    "That would be one very short trip. Please select a different drop-off location";
export const defaultTime = "0001-01-01";
export const omitFields = [
    "apptTime",
    "clientBirthDate",
    "pickUpTime",
    "dropOffTime",
    "returnTime",
    "pickUpPlace",
    "dropOffPlace",
];

export const omitTimeFields = ["date", "months", "years"];

interface AddressSuffix {
    suffix: string;
    key: keyof ParsedAddress;
}

export const addressFieldSufixes: AddressSuffix[] = [
    { suffix: "City", key: "city" },
    { suffix: "State", key: "state" },
    { suffix: "ZipCode", key: "zip" },
    { suffix: "PlaceName", key: "name" },
    { suffix: "Latitude", key: "latitude" },
    { suffix: "Longitude", key: "longitude" },
    { suffix: "Address", key: "address1" },
];

/**
 * How the rider is going to get back.
 * 0 = One way, 1 = Call for return, 2 = Specific return time requested
 * */
export enum ReturnTripTypes {
    oneWay = 0,
    callForReturn = 1,
    specificTime = 2,
}

export const returnTripOptions = [
    { label: "No return trip needed", value: ReturnTripTypes.oneWay },
    {
        label: "I'll call when I'm ready to be picked up",
        value: ReturnTripTypes.callForReturn,
    },
    { label: "Please pick me up at:", value: ReturnTripTypes.specificTime },
];

function getEquipment(accessibility: Client["accessibility"]) {
    if (!accessibility || !Array.isArray(accessibility.equipmentType))
        return "";

    const filtered = accessibility?.equipmentType?.filter((et) => {
        if (
            et.attributeValue ===
            accessibility.wheelChairType + " Wheelchair"
        ) {
            return false;
        }
        return true;
    });

    return filtered.map((item) => item.attributeValue).join();
}

export function getDayCalendar(
    date: Date,
    rules: ParsedProgramCalendarRules
): CalendarRule {
    const day = moment(date).format("dddd");
    let rule = {
        Active: false,
        StartJSON: new Date(),
        EndJSON: new Date(),
    };

    switch (day) {
        case "Sunday":
            rule = rules.Sunday;
            break;
        case "Monday":
            rule = rules.Monday;
            break;
        case "Tuesday":
            rule = rules.Tuesday;
            break;
        case "Wednesday":
            rule = rules.Wednesday;
            break;
        case "Thursday":
            rule = rules.Thursday;
            break;
        case "Friday":
            rule = rules.Friday;
            break;
        case "Saturday":
            rule = rules.Saturday;
            break;
        default:
            break;
    }
    return rule;
}

export function validateByCalendar(
    client: Client,
    date: Date,
    tripProgram: string,
    type: "date" | "time" = "date"
) {
    let error = "";
    const time = moment(date);

    /**
     * check if time or date is in the past
     * @see https://momentjs.com/docs/#/query/is-before/
     */

    if (time.isBefore(new Date(), type === "date" ? "day" : undefined)) {
        return `Please select a future ${type}`;
    } else if (time.isBefore(moment(new Date(), "HH:mm"))) {
        return "Please select a future time";
    }

    const activeProgram = client?.programs?.find(
        (item) => item.programName === tripProgram
    );

    if (!activeProgram || !activeProgram.programCalendarRules) return "";
    const rules = JSON.parse(activeProgram.programCalendarRules);
    const dayCalendar = getDayCalendar(date, rules);

    // if program is not active for current day
    if (dayCalendar && !dayCalendar.Active) {
        if (type === "time") {
            error = `${activeProgram.programName} is not active at this time`;
        } else {
            const day = moment(date).format("dddd");
            error = `${activeProgram.programName} does not run on ${day}`;
        }
    }

    // if program is active, validate the period
    if (dayCalendar && dayCalendar.Active) {
        // extract time from dayCalendar and attach to current date for easier calculations
        const parsedStart = moment(dayCalendar.StartJSON).toObject();
        const startTime = moment(date).set(omit(parsedStart, omitTimeFields));

        const parsedEnd = moment(dayCalendar.EndJSON).toObject();
        const endTime = moment(date).set(omit(parsedEnd, omitTimeFields));

        const isBetween = time.isAfter(startTime) && time.isBefore(endTime);

        if (!isBetween) {
            error = `${activeProgram.programName} runs between ${moment(
                startTime
            ).format("hh:mm A")} and ${moment(endTime).format("hh:mm A")}`;
        }
    }

    return error;
}

// load the best program for the current time and day
export async function initTripProgram(client: Client) {
    // initialize program name from defaultProgram name
    let programName = client?.defaultProgramName ?? "";
    const apptTime = moment().add(15, "m").toDate();

    // if defaultProgram is not set, assign from the first program
    if (!programName && client.programs?.length) {
        programName = client.programs[0]?.programName;
    }

    if (client.programs && client.programs?.length) {
        for (const program of client?.programs) {
            const dateError = await validateByCalendar(
                client,
                apptTime,
                program.programName
            );

            const timeError = await validateByCalendar(
                client,
                apptTime,
                program.programName,
                "time"
            );

            // if program date and time are ok
            if (!dateError && !timeError) {
                programName = program.programName;
                break;
            }
        }
    }
    return programName;
}

export async function initTripRequest(
    client: Client,
    requesterEmail: string = "",
    allPULocations = true,
    isNavigator = false
): Promise<TripRequestFormValues | any> {
    if (!Object.keys(client).length) return {};

    const [clientFirstName, ...clientLastName] = client?.name?.split(" ");

    const apptTime = moment().add(15, "m");
    const defaultProgram = await initTripProgram(client);

    let pickUpPlace = defaultParsedAddress;
    let pickUpAddress2 = "";

    if (isNavigator) {
        if (allPULocations) {
            pickUpPlace = client.address;
            pickUpAddress2 = client.address.address2;
        }
    } else {
        if (allPULocations) {
            const description = `${client.home.address1}, ${client.home.city}, ${client.home.state}`;

            try {
                // the riders home address does not get geocoded at signup so we try to geocode it here
                pickUpPlace = await getGeoCodedAddress(description);
            } catch {
                pickUpPlace = {
                    description,
                    name: "Home",
                    address1: client.home.address1 || "",
                    address2: client.home.address2 || "",
                    city: client.home.city || "",
                    state: client.home.state || "",
                    zip: client.home.zip || "",
                    latitude: client.home.lat || 0,
                    longitude: client.home.lng || 0,
                    street: "",
                };
            }
            pickUpAddress2 = client.home.address2 || "";
        }
    }

    const trip = {
        clientID: client.id, // we use this in tripPrograms to filter the programs by clientID if user is manager
        importTripID: uuidv4(),
        importClientID: uuidv4(),
        utcOffset: utcOffset,
        clientFirstName,
        clientLastName: clientLastName.join(" "),
        clientPhone: client.phone,
        clientEmail: client.email,
        pickUpPlace,
        pickUpAddress2,
        dropOffPlace: defaultParsedAddress,
        dropOffAddress2: "",
        timeType: TimeTypes.pickedUp,
        apptTime: apptTime.toDate(),
        pickUpTime: apptTime.toDate(),
        dropOffTime: apptTime.toDate(),
        returnTime: apptTime.add(135, "m").toDate(),
        returnType: 0,
        clientInWC: client.accessibility.usesWheelchair,
        clientNeedsWCVan: client.accessibility.needsWheelchairVan,
        clientWCType: client.accessibility.wheelChairType ?? "", // possibly nullish
        clientIsDisabled: client.accessibility.isPersonWithDisability,
        clientDisabilities: client.specialNeeds || "",
        clientEquipment: getEquipment(client.accessibility),
        personalCareAttendant: client.accessibility.hasPCA,
        otherRiders: 0,
        carSeatDescription: "",
        clientBirthdate: client.birthDateJson
            ? dateFromJson(client.birthDateJson)
            : "",
        clientComments: client.notes || "",
        tripProgram: defaultProgram,
        tripComments: "",
        tripSource: device, // previously "Downtown",
        tripUrl: "", //`will be filled on trip submit,
        newPassword: "",
        clientRESTUrl: "", // will be filled on trip submit with auth.user.RESTUrl,
        requesterEmail: requesterEmail, // will be filled on trip submit with auth.client.email,
        recurrance: {
            weekly: 1,
            sunday: false,
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
        },
    };

    return trip;
}

export function focusElement(id: string) {
    const element = document.getElementById(id);
    if (element) {
        element.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
        });
        element.focus();
    }
}

/**
 *
 * @param type string SelectionType
 * @param parsedAddress (ParsedAddress)
 * @returns formatted address object with type prefix
 * @see TripRequestFormValues
 */
export function formatAddressFields(
    type: SelectionType = "pickUp",
    place: RequestedPlace
) {
    const parsed: any = {};
    // update sub field values first without rerendering
    for (const item of addressFieldSufixes) {
        const field = `${type}${item.suffix}`;
        const value = place[item.key as keyof RequestedPlace];
        parsed[field] = value;
    }
    parsed[`${type}AddressFull`] = place.description;

    return parsed;
}

export function formatDateTime(date: Date) {
    return moment(date).format("YYYY-MM-DD HH:mm:ss");
}

export function formatTripURL(tripId: string) {
    const url = new URL(window.location.href);
    return `${url?.origin}/trips/${tripId}`;
}

export async function formatNavigatorTripTimes(
    values: TripRequestFormValues,
    client: Client,
    service: Program
) {
    const origin = {
        lat: values.pickUpPlace.latitude,
        lng: values.pickUpPlace.longitude,
    };

    const destination = {
        lat: values.dropOffPlace.latitude,
        lng: values.dropOffPlace.longitude,
    };

    // seconds
    const travelDuration = await getTravelDuration(origin, destination);

    // minutes
    const calcPuExtraDuration =
        service.puExtraDuration > client.puExtraDuration
            ? service.puExtraDuration
            : client.puExtraDuration;
    // minutes
    const calcDoExtraDuration =
        service.doExtraDuration > client.doExtraDuration
            ? service.doExtraDuration
            : client.doExtraDuration;

    let requestedTime = moment(values.apptTime);
    let pickUpTime = requestedTime.clone();
    let dropOffTime = requestedTime.clone();
    let unloadedTime = requestedTime.clone();

    if (values.timeType === TimeTypes.pickedUp) {
        dropOffTime = pickUpTime
            .clone()
            .add(calcPuExtraDuration, "minutes")
            .add(travelDuration, "seconds");

        unloadedTime = dropOffTime.clone().add(calcDoExtraDuration, "minutes");
    } else {
        pickUpTime = pickUpTime
            .subtract(travelDuration, "seconds")
            .subtract(calcDoExtraDuration, "minutes")
            .subtract(calcPuExtraDuration, "minutes");
        dropOffTime = requestedTime
            .clone()
            .subtract(calcDoExtraDuration, "seconds");
    }

    // todo: handle cases where dropoff time is less than pickup time after calculations

    return {
        returnTime: null,
        requestTime: requestedTime.format(dateTimeFormat),
        pickUpTime: pickUpTime.format(dateTimeFormat),
        dropOffTime: dropOffTime.format(dateTimeFormat),
        unloadedTime: unloadedTime.format(dateTimeFormat),
    };
}

export async function formatNavigatorTrip(
    values: TripRequestFormValues,
    client: Client,
    service: Program,
    autoschedule: boolean
): Promise<OnDemandTripRequest> {
    const accessibility = {
        ...defaultClient.accessibility,
        usesWheelchair: values.clientInWC,
        needsWheelchairVan: values.clientNeedsWCVan,
        wheelchairType: values.clientWCType,
        isPersonWithDisability: values.clientIsDisabled,
        specialNeeds: values.clientDisabilities,
        hasPCA: values.personalCareAttendant,
    };

    const pickUpPlace = {
        ...values.pickUpPlace,
        address2: values.pickUpAddress2,
    };

    const dropOffPlace = {
        ...values.dropOffPlace,
        address2: values.dropOffAddress2,
    };

    let totalRiders = 1;
    if (values.personalCareAttendant) totalRiders++;
    if (values.otherRiders) totalRiders += values.otherRiders;

    const times = await formatNavigatorTripTimes(values, client, service);

    const trip = {
        ...times,
        tripUuid: values.importTripID,
        tripURL: formatTripURL(values.importTripID),
        pickUpPlace: JSONToString(pickUpPlace),
        dropOffPlace: JSONToString(dropOffPlace),
        accessibility: JSONToString(accessibility),
        tripSource: values.tripSource,
        timeType: values.timeType,
        requesterEmail: values.requesterEmail,
        comment: values.clientComments,
        serviceId: service.databaseID || "",
        totalRiders,
        nextOnDemandTripId: null,
        shouldAutoSchedule: autoschedule ? 1 : 0,
    };
    return trip;
}

export function formatParaplanTrip(values: TripRequestFormValues, user: User) {
    const trip = {
        ...omit(values, omitFields),
        ...formatAddressFields("dropOff", values.dropOffPlace),
        ...formatAddressFields("pickUp", values.pickUpPlace),
        apptTimeEpoch: moment(values.apptTime).unix(),
        pickUpTimeEpoch: moment(values.pickUpTime).unix(),
        dropOffTimeEpoch: moment(values.dropOffTime).unix(),
        returnTimeEpoch: moment(values.returnTime).unix(),
        clientBirthdateEpoch: moment(values.clientBirthdate).unix(),
        tripUrl: formatTripURL(values.importTripID),
        paraPlanClientID: user.ClientID,
        clientRESTUrl: user.RESTUrl,
    };

    return trip;
}

export async function getCurrentPickupLocation(): Promise<
    ParsedAddress | undefined
> {
    let address;
    const location = await getGeolocation();
    const geocoder = new google.maps.Geocoder();
    const response = await geocoder.geocode({
        location: {
            lat: location.coords.latitude,
            lng: location.coords.longitude,
        },
    });
    address = getParsedAddress(response.results[0]);
    return address;
}
