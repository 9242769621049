import { Fragment, useState } from "react";
import { GridRenderCellParams } from "@mui/x-data-grid";
import {
    ButtonGroup,
    IconButton,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    DialogContentText,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
    Delete,
    VisibilityOutlined,
    CancelOutlined,
    EventRepeat,
} from "@mui/icons-material";
import { TripReservationType, TripHistory, StatusTypes } from "../../../types";
import { Link, useNavigate } from "react-router-dom";
import { tripsAPI, tripsSelector } from "src/modules/trips/redux/tripSlice";
import { enqueueSnackbar } from "notistack";
import { useAppSelector } from "src/hooks";
import { authSelector } from "src/modules/auth/redux/authSlice";
import { Formik, Form, FormikValues } from "formik";
import { FormDatePicker } from "src/components";
import { getGeolocation } from "src/utils/helpers";
import { formatTripLabel, formatTripInfo } from "../helpers";
import { PassioService, TripCancelReason, TripScheduleStatus } from "src/types";
import { formatTripCancelledEmail } from "src/utils/email";
import { formatTripCancelledSMS } from "src/utils/sms";
import moment from "moment";

const TableActions = ({ row }: GridRenderCellParams<TripHistory>) => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const { isManager, service, client } = useAppSelector(authSelector);
    const { devices, mdts } = useAppSelector(tripsSelector);
    const isNavigator = service === PassioService.navigator;

    const [cancelTripOfReservation, { isLoading: cancelingTripOfReservation }] =
        tripsAPI.useCancelTripOfReservationMutation();
    const [cancelReservation, { isLoading: cancelingReservation }] =
        tripsAPI.useCancelReservationMutation();
    const [cancelTrip, { isLoading: cancelingTrip }] =
        tripsAPI.useCancelTripMutation();
    const [removeTripRequest, { isLoading: cancelingTripRequest }] =
        tripsAPI.useRemoveTripRequestMutation();
    const [updateTripLog, { isLoading: updatingTripLog }] =
        tripsAPI.useTdbMutation();
    const [sendEmail, { isLoading: isSendingEmail }] =
        tripsAPI.useSendEmailMutation();
    const [updateMDT, { isLoading: updatingMDT }] =
        tripsAPI.useUpdateMDTMutation();
    const [sendSMS, { isLoading: isSendingSMS }] =
        tripsAPI.useSendSMSMutation();

    const { data: runs = [] } = tripsAPI.useGetFleetManagersQuery(undefined, {
        skip: !isNavigator,
    });

    async function handleMDTUpdate() {
        try {
            let runId = row?.runId || "";
            // only update mdts for navigator
            if (!isNavigator || !runId) return;
            else if (typeof runId === "string") {
                runId = parseInt(runId);
            }
            const run = runs.find((run) => run.fleetmanagerID === runId);
            const device = devices.find((device) => {
                const match = parseInt(device.busId) === run?.vehicleID;
                const isMDT = mdts.find(
                    (mdt) => mdt.id === device.deviceSystemId
                );
                return match && isMDT;
            });
            if (device) {
                await updateMDT(device.id);
            }
        } catch (error) {
            enqueueSnackbar("Sorry, an error occured. Please try again", {
                variant: "error",
            });
        }
    }

    async function handleCancelTripOfReservation({
        dateToCancel,
    }: FormikValues) {
        try {
            const reason = isManager
                ? "Cancelled%20by%20Mobility%20Manager"
                : "Cancelled%20by%20Rider";

            const request = {
                cancellationReason: reason,
                reservationId: row.databaseId,
                dateToCancel: moment(dateToCancel).local().unix().toString(),
            };

            await cancelTripOfReservation(request).unwrap();

            enqueueSnackbar(
                `Your reservation for ${moment(dateToCancel).format(
                    "dddd MMM DD YYYY"
                )} has been cancelled`,
                {
                    variant: "success",
                }
            );
            setOpen(false);
        } catch (error) {
            enqueueSnackbar("Sorry, an error occured please try again", {
                variant: "error",
            });
        }
    }

    async function handleCancelReservation() {
        try {
            const reason = isManager
                ? "Cancelled by Mobility Manager"
                : "Cancelled by Rider";

            const request = {
                cancellationReason: reason,
                reservationId: row.databaseId,
            };

            await cancelReservation(request).unwrap();

            enqueueSnackbar("Your reservation has been cancelled", {
                variant: "success",
            });
            // Table is automatcially refreshed with RTKQuery tag invalidation
        } catch (error) {
            enqueueSnackbar("Sorry, an error occured please try again", {
                variant: "error",
            });
        }
    }

    // Add new log when canceling or deleting trip in navigator
    async function updateTripHistory() {
        const logRequest = {
            path: "add",
            body: {
                type: "onDemandTripActionHistory",
                userId: client.agencyId,
                riderId: client.id,
                onDemandTripId: row.databaseId,
                onDemandActionId: TripScheduleStatus.Cancelled,
                latitude: 0,
                longitude: 0,
            },
        };
        await updateTripLog(logRequest).unwrap();
    }

    async function handleCancelTrip() {
        try {
            const reason = isManager
                ? TripCancelReason.cancelledByManager
                : TripCancelReason.cancelledByRider;

            let request = {
                reason,
                id: row.databaseId,
                lat: 34.85454,
                lng: 82.397747,
            };

            // try to get current location. if not, proceed with defaults carried over from v1
            try {
                const geo = await getGeolocation();
                request.lat = geo.coords.latitude;
                request.lng = geo.coords.longitude;
            } catch (error) {
                enqueueSnackbar(
                    "we couldn't get your current position. Defaults have been used instead",
                    { variant: "info" }
                );
            }

            await cancelTrip(request).unwrap();
            if (isNavigator) {
                // update trip log
                await updateTripHistory();
                // update MDT
                await handleMDTUpdate();
                // send email notification
                try {
                    const email = formatTripCancelledEmail(row, client, reason);
                    await sendEmail(email).unwrap();

                    const message = formatTripCancelledSMS();
                    await sendSMS({ to: client.phone, message }).unwrap();
                } catch {}
            }

            enqueueSnackbar("Your trip has been cancelled", {
                variant: "success",
            });

            // Table is automatcially refreshed with RTKQuery tag invalidation
        } catch (error) {
            enqueueSnackbar("Sorry, an error occured please try again", {
                variant: "error",
            });
        }
    }

    async function handleRemoveTripRequest() {
        try {
            const reason = isManager
                ? encodeURIComponent(TripCancelReason.cancelledByManager)
                : encodeURIComponent(TripCancelReason.cancelledByRider);

            const request = { reason, id: row.databaseId };
            await removeTripRequest(request).unwrap();
            // Add new log with updated trip status in onDemandTripActionHistory for navigator
            if (isNavigator) await updateTripHistory();
            enqueueSnackbar("Your Trip request has been cancelled", {
                variant: "success",
            });

            // Table is automatcially refreshed with RTKQuery tag invalidation
        } catch (error) {
            enqueueSnackbar("Sorry, an error occured please try again", {
                variant: "error",
            });
        }
    }

    async function handleDuplication() {
        const trip = await formatTripInfo(row, isNavigator);
        navigate("/trips/request", {
            state: { duplicate: trip },
        });
    }

    // permission checks
    const isPast = moment(row.tripDate).isBefore(new Date());
    const canRemoveReservation =
        row.reservationType === TripReservationType.Reservation &&
        row.secondLine !== "Recurring reservation has ended";

    const canCancelTripRequest =
        isPast &&
        (row.reservationType === TripReservationType.Request ||
            row.secondLine === StatusTypes.Requested);
    const canCancelTrip =
        isPast &&
        row.reservationType === TripReservationType.Trip &&
        row.onDemandActionId !== TripScheduleStatus.Cancelled;

    const canViewDetails =
        row.secondLine && row.secondLine !== StatusTypes.Pending;

    const cancelling =
        cancelingTripRequest ||
        updatingTripLog ||
        isSendingEmail ||
        updatingMDT ||
        isSendingSMS;

    return (
        <Fragment>
            <ButtonGroup>
                {canViewDetails && (
                    <Tooltip title="View details">
                        <IconButton
                            color="primary"
                            component={Link}
                            to={`/trips/${row.databaseId}?status=history${
                                isManager && row.client
                                    ? `&rider=${row.client.id}`
                                    : ""
                            }`}
                            aria-label={formatTripLabel("See details", row)}
                        >
                            <VisibilityOutlined />
                        </IconButton>
                    </Tooltip>
                )}

                {row?.pickUpPlace && row?.dropOffPlace && (
                    <Tooltip title="Duplicate Trip Request">
                        <IconButton
                            onClick={handleDuplication}
                            aria-label={formatTripLabel(
                                "duplicate trip request",
                                row
                            )}
                        >
                            <EventRepeat />
                        </IconButton>
                    </Tooltip>
                )}

                {canCancelTrip && (
                    <Tooltip title="Cancel Trip">
                        <LoadingButton
                            color="warning"
                            onClick={handleCancelTrip}
                            disabled={
                                cancelingTrip ||
                                updatingTripLog ||
                                isSendingEmail
                            }
                            loading={
                                cancelingTrip ||
                                updatingTripLog ||
                                isSendingEmail
                            }
                            aria-label={formatTripLabel(
                                `${
                                    cancelingTrip ? "Canceling" : "Cancel"
                                } trip`,
                                row
                            )}
                        >
                            <CancelOutlined />
                        </LoadingButton>
                    </Tooltip>
                )}

                {canRemoveReservation && (
                    <Tooltip title="Cancel single trip">
                        <IconButton
                            color="warning"
                            onClick={() => setOpen(true)}
                            aria-label={formatTripLabel(
                                "Cancel single trip",
                                row
                            )}
                        >
                            <CancelOutlined />
                        </IconButton>
                    </Tooltip>
                )}

                {canRemoveReservation && (
                    <Tooltip title="Remove reservation">
                        <LoadingButton
                            color="error"
                            onClick={handleCancelReservation}
                            disabled={cancelingReservation}
                            loading={cancelingReservation}
                            aria-label={formatTripLabel(
                                `${
                                    cancelingReservation
                                        ? "Cancelling"
                                        : "Cancel"
                                } reservation`,
                                row
                            )}
                        >
                            <Delete />
                        </LoadingButton>
                    </Tooltip>
                )}

                {canCancelTripRequest && (
                    <Tooltip title="Remove Trip Request">
                        <LoadingButton
                            color="error"
                            onClick={handleRemoveTripRequest}
                            disabled={cancelling}
                            loading={cancelling}
                            aria-label={formatTripLabel(
                                `${
                                    cancelingTripRequest ? "Removing" : "Remove"
                                } trip request`,
                                row
                            )}
                        >
                            <Delete />
                        </LoadingButton>
                    </Tooltip>
                )}
            </ButtonGroup>
            {/* Todo: consider removing this entirely when Paraplan is phased out or repurposing for cancellation reason */}
            <Dialog open={open} maxWidth="md" onClose={() => setOpen(false)}>
                <Formik
                    initialValues={{
                        dateToCancel: moment(row.nextTripDate).toDate(),
                    }}
                    onSubmit={handleCancelTripOfReservation}
                >
                    <Form>
                        <DialogTitle>Cancel Single Trip</DialogTitle>
                        <DialogContent>
                            <DialogContentText gutterBottom>
                                Please enter the date you want to cancel. Your
                                next trip is scheduled for:
                            </DialogContentText>

                            <DialogContentText color="primary.main">
                                {moment(row.nextTripDate).format("LLLL")}
                            </DialogContentText>
                            <FormDatePicker
                                format="MM/dd/yyyy"
                                name="dateToCancel"
                                label="Trip Date"
                                sx={{ width: "100%", my: 2 }}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpen(false)}>
                                Cancel
                            </Button>
                            <LoadingButton
                                color="warning"
                                type="submit"
                                loading={cancelingTripOfReservation}
                            >
                                Continue
                            </LoadingButton>
                        </DialogActions>
                    </Form>
                </Formik>
            </Dialog>
        </Fragment>
    );
};

export default TableActions;
