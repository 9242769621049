import { Stack, Box, Typography, StackProps } from "@mui/material";
import logoImg from "src/images/logo-full-green.png";

interface Props extends StackProps {}

export const Logo = (props: Props) => {
    return (
        <Stack justifyContent="center" alignItems="center" gap={3} {...props}>
            <Box
                component="img"
                alt="logo"
                src={logoImg}
                sx={{
                    mx: "auto",
                    height: 100,
                    width: 400,
                    objectFit: "contain",
                }}
            />

            <Typography
                component="h1"
                variant="h4"
                fontWeight="bold"
                gutterBottom
            >
                Connect Rider
            </Typography>
        </Stack>
    );
};

export default Logo;
