import { Outlet, ScrollRestoration } from "react-router-dom";
import {
    ThemeProvider,
    createTheme,
    CssBaseline,
    responsiveFontSizes,
} from "@mui/material";
import { useAppSelector } from "src/hooks";
import { colors } from "src/utils/constants";
import { isEqual } from "lodash";

function getHexColor(color: string) {
    // colors could be of the form #FF367764 or standard hex
    if(color.length >= 9 && color.substring(1, 3) === "FF") {
        return `#${color.substring(3)}`;
    }
    return color;
}

const ThemedLayout = () => {
    const config = useAppSelector((state) => state.auth.config);
    const primary = config?.ConnectPreferences?.DefaultTintString;
    const secondary = config?.ConnectPreferences?.SecondaryTintString;

    const theme = createTheme({
        palette: {
            primary: {
                main:
                    primary && !isEqual(primary, secondary)
                        ? getHexColor(primary)
                        : colors.primary,
            },
            secondary: {
                main:
                    secondary && !isEqual(primary, secondary)
                        ? getHexColor(secondary)
                        : colors.secondary,
            },
        },
    });

    const responsiveTheme = responsiveFontSizes(theme);

    return (
        <ThemeProvider theme={responsiveTheme}>
            <Outlet />
            <CssBaseline />
            <ScrollRestoration />
        </ThemeProvider>
    );
};

export default ThemedLayout;
