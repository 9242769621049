import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Skeleton from "@mui/material/Skeleton";
import parse from "autosuggest-highlight/parse";
import { debounce } from "@mui/material/utils";
import { AutocompleteProps, AutocompleteChangeReason } from "@mui/material";
import { googleMapLibraries, googleMapsApiKey } from "src/utils/constants";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { FieldValidator, useField } from "formik";
import { getProperty } from "dot-prop";
import { getGeoCodedAddress } from "src/utils/helpers";
import { ParsedAddress } from "./types";
import { defaultParsedAddress } from "src/utils/defaultData";

interface MainTextMatchedSubstrings {
    offset: number;
    length: number;
}

interface StructuredFormatting {
    main_text: string;
    secondary_text: string;
    main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}

interface PlaceType {
    description: string;
    structured_formatting: StructuredFormatting;
}

interface Props
    extends Omit<
        AutocompleteProps<any, any, any, any>,
        "renderInput" | "onChange" | "options"
    > {
    name: string;
    label: string;
    error?: boolean;
    helperText?: string;
    onChange?: (value: ParsedAddress | undefined) => void;
    validate?: FieldValidator;
    validationKey?: string;
}

export const PlacesAutocomplete = ({
    name,
    error,
    label,
    loading,
    onChange,
    validate,
    helperText,
    validationKey = "name",
    ...rest
}: Props) => {
    const [field, meta, helpers] = useField({ name, validate });
    const autocompleteService =
        React.useRef<google.maps.places.AutocompleteService | null>(null);
    const [inputValue, setInputValue] = React.useState("");
    const [options, setOptions] = React.useState<readonly PlaceType[]>([]);

    const fetch = React.useMemo(
        () =>
            debounce(
                (
                    request: { input: string },
                    callback: (results?: readonly PlaceType[]) => void
                ) => {
                    (autocompleteService.current as any).getPlacePredictions(
                        request,
                        callback
                    );
                },
                400
            ),
        []
    );

    async function handleChange(
        _: any,
        value: PlaceType | null,
        reason: AutocompleteChangeReason
    ) {
        if (reason === "clear" || !value) {
            helpers.setValue(defaultParsedAddress);
            if (onChange) onChange(defaultParsedAddress);
            setOptions([]);
            setInputValue("");
            return;
        }
        setOptions(value ? [value, ...options] : options);
        const address = await getGeoCodedAddress(value.description);
        helpers.setValue(address);
        if (onChange) onChange(address);
    }

    React.useEffect(() => {
        let active = true;

        if (
            !autocompleteService.current &&
            window?.google?.maps?.places?.AutocompleteService
        ) {
            autocompleteService.current =
                new window.google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            return undefined;
        }

        if (inputValue === "") {
            setOptions(field.value ? [field.value] : []);
            return undefined;
        }

        fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
            if (active) {
                let newOptions: readonly PlaceType[] = [];

                if (field.value) {
                    newOptions = [field.value];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [field.value, inputValue, fetch]);

    return (
        <Autocomplete
            {...rest}
            getOptionLabel={(option) =>
                typeof option === "string" ? option : option.description
            }
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={field.value}
            noOptionsText="No locations"
            onChange={handleChange}
            onInputChange={(event, value) => {
                setInputValue(value);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    fullWidth
                    label={label}
                    error={meta.error ? true : false}
                    helperText={
                        getProperty(meta.error, validationKey) || helperText
                    }
                    variant="standard"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading && (
                                    <CircularProgress
                                        color="inherit"
                                        size={20}
                                    />
                                )}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
            renderOption={(props, option) => {
                const matches =
                    option?.structured_formatting
                        ?.main_text_matched_substrings || [];

                const parts = parse(
                    option?.structured_formatting?.main_text,
                    matches.map((match: any) => [
                        match.offset,
                        match.offset + match.length,
                    ])
                );

                return (
                    <li {...props}>
                        <Grid container alignItems="center">
                            <Grid item sx={{ display: "flex", width: 44 }}>
                                <LocationOnIcon
                                    sx={{ color: "text.secondary" }}
                                />
                            </Grid>
                            <Grid
                                item
                                sx={{
                                    width: "calc(100% - 44px)",
                                    wordWrap: "break-word",
                                }}
                            >
                                {parts.map((part, index) => (
                                    <Box
                                        key={index}
                                        component="span"
                                        sx={{
                                            fontWeight: part.highlight
                                                ? "bold"
                                                : "regular",
                                        }}
                                    >
                                        {part.text}
                                    </Box>
                                ))}
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                >
                                    {
                                        option?.structured_formatting
                                            ?.secondary_text
                                    }
                                </Typography>
                            </Grid>
                        </Grid>
                    </li>
                );
            }}
        />
    );
};

const Error = () => {
    return (
        <Box>
            <Typography variant="body1" color="error">
                Sorry we couldn't load your location selector. Try refreshing
                the page
            </Typography>
            <Button
                size="small"
                variant="contained"
                sx={{ my: 1 }}
                onClick={() => window && window.location.reload()}
            >
                reload
            </Button>
        </Box>
    );
};

const WrappedPlacesSelector = (props: Props) => {
    const render = (status: Status): React.ReactElement => {
        if (status === Status.FAILURE) return <Error />;
        return <Skeleton variant="rectangular" height={50} />;
    };

    return (
        <Wrapper
            apiKey={googleMapsApiKey ?? ""}
            render={render}
            libraries={googleMapLibraries} // ensure places load and  match libraries loaded by useJsApiLoader in the GoogleMap component
        >
            <PlacesAutocomplete {...props} />
        </Wrapper>
    );
};

export default WrappedPlacesSelector;
