import * as Yup from "yup";
import { matchIsValidTel } from "mui-tel-input";

export const profileSchema = Yup.object().shape({
    name: Yup.string(),
    firstName: Yup.string().required("Please enter first name"),
    lastName: Yup.string().required("Please enter last name"),
    phone: Yup.string()
        .required("Please enter phone number")
        .test({
            name: "validPhone",
            message: "Please enter a valid phone number",
            test: (value) => matchIsValidTel(value),
        }),
    email: Yup.string().email().required("Please enter email"),
    accessibility: Yup.object().shape({
        hasPCA: Yup.bool(),
        usesWheelchair: Yup.bool(),
        wheelchairType: Yup.string(),
    }),
    defaultProgramName: Yup.string().required("Please enter program name"),
    defaultProgramMembershipID: Yup.string(),
});

const birthDateSchema = Yup.object().shape({
    birthdate: Yup.date()
        .required("Please enter date of birth")
        .typeError("Please enter date of birth"),
});

const homeSchema = Yup.object().shape({
    home: Yup.object().shape({
        address1: Yup.string().required("Please enter home address"),
        city: Yup.string().required("Please enter home city"),
        state: Yup.string().required("Please enter state"),
        zip: Yup.string().required("Please enter zip code"),
    }),
});

export const passwordSchema = Yup.object().shape({
    oldPassword: Yup.string().required("Please enter your current password"),
    newPassword: Yup.string()
        .min(8, "Password must be at least 8 characters long")
        .required("Please enter your new password"),
    confirmPassword: Yup.string()
        .min(8, "Password must be at least 8 characters long")
        .required("Please confirm your password")
        .oneOf([Yup.ref("newPassword")], "Passwords do not match"),
});

// only managers can edit a users home address
export function getProfileSchema(isManager = false, isNavigator = false) {
    if (isManager) {
        return profileSchema.concat(homeSchema).concat;
    }

    if (isNavigator) {
        return profileSchema.concat(
            Yup.object().shape({ defaultProgramName: Yup.string() })
        );
    }
    return profileSchema.concat(birthDateSchema);
}
