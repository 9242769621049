import { Navigate, RouteObject, createBrowserRouter } from "react-router-dom";
import { AuthGuard } from "./components/guards";
import {
    DashboardLayout,
    ThemedLayout,
    AuthLayout,
    NotFound,
} from "./components";

// new routes
import Login from "src/modules/auth/pages/Login";
import Signup from "./modules/auth/pages/signup/Signup";
import OAuthRedirect from "./modules/auth/pages/OAuthRedirect";
import TripRequest from "src/modules/trips/pages/TripRequest/TripRequest";
import TripHistory from "./modules/trips/pages/TripHistory/TripHistory";
import Details from "src/modules/trips/pages/details/Details";
import Riders from "src/modules/riders/Riders";
import Profile from "src/modules/profile/Profile";
import MapWrapper from "./components/MapWrapper";
import Schedule from "./modules/trips/pages/Schedule";
import PasswordReset from "./modules/auth/pages/PasswordReset";

const routes: RouteObject[] = [
    {
        path: "/",
        errorElement: <NotFound />, // catch all app and route errors
        element: (
            <MapWrapper>
                <ThemedLayout />
            </MapWrapper>
        ),
        children: [
            {
                index: true,
                element: (
                    <Navigate
                        to={{
                            pathname: "/login",
                            search: window.location.search,
                        }}
                    />
                ),
            },
            {
                // AuthLayout parse and loads agency config from the search params
                element: <AuthLayout />, // layout route no path needed
                children: [
                    { path: "login", element: <Login /> },
                    { path: "signup", element: <Signup /> },
                    { path: "oauth2", element: <OAuthRedirect /> },
                    { path: "recovery", element: <PasswordReset /> },
                ],
            },
            {
                // protected routes
                element: (
                    <AuthGuard>
                        <DashboardLayout />
                    </AuthGuard>
                ),
                children: [
                    // trips
                    {
                        path: "trips",
                        children: [
                            { index: true, element: <Navigate to="request" /> },
                            { path: "request", element: <TripRequest /> },
                            { path: "history", element: <TripHistory /> },
                            { path: "schedule/:id", element: <Schedule /> },
                            { path: ":id", element: <Details /> },
                        ],
                    },
                    // riders
                    {
                        path: "riders",
                        children: [
                            { index: true, element: <Riders /> },
                            { path: ":id", element: <Profile /> },
                            { path: ":id/history", element: <TripHistory /> },
                            { path: ":id/request", element: <TripRequest /> },
                            { path: ":id/details", element: <Details /> },
                            {
                                path: "new",
                                element: (
                                    <Profile
                                        title="New Rider"
                                        returnTo="/riders"
                                    />
                                ),
                            },
                        ],
                    },
                    // profile
                    {
                        path: "profile",
                        element: <Profile />,
                    },
                ],
            },
        ],
    },
];

/**
 * Base URL of the app .
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 * Override `basename` (e.g: `homepage` in `package.json`)
 */

const router = createBrowserRouter(routes, {
    basename: process.env.PUBLIC_URL,
});

export default router;
