// helper functions

import { PassioGeofence } from "src/modules/trips/types";

export function getMapShape(geofence: PassioGeofence) {
    let options = {
        fillColor: geofence.color,
        fillOpacity: 0.1,
        strokeColor: "#2d9eaf",
        strokeOpacity: 1,
        strokeWeight: 2,
        clickable: false,
        draggable: false,
        editable: false,
        geodesic: false,
        zIndex: 1,
    };

    switch (geofence.shape.type) {
        case "polygon":
            // re arrange coordinates for easier plotting
            const pathMap = geofence.shape.coordinates[0].map((coord) => {
                return { lat: coord[1], lng: coord[0] };
            });

            return {
                paths: pathMap,
                options: options,
            };

        case "circle":
            return {
                center: {
                    lat: geofence.shape?.center?.[1],
                    lng: geofence.shape?.center?.[0],
                },
                options: {
                    ...options,
                    radius: geofence.shape.radius,
                },
            };
    }
}
