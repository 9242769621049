import * as Yup from "yup";
import { TimeTypes } from "src/types";

const addressSchema = Yup.object().shape({
    name: Yup.string(),
    address1: Yup.string(),
    city: Yup.string(),
    state: Yup.string(),
    zip: Yup.string(),
    latitude: Yup.number(),
    longitude: Yup.number(),
    description: Yup.string().required("Where would you like to be picked up?"),
});

export const TripRequestSchema = Yup.object().shape({
    apptTime: Yup.date()
        .required("Please select trip date")
        .typeError("Please select trip date"),
    timeType: Yup.string().oneOf([TimeTypes.pickedUp, TimeTypes.droppedOff]),
    pickUpTime: Yup.date().when("timeType", {
        is: TimeTypes.pickedUp, // alternatively: (val) => val == true
        then: (schema) =>
            schema
                .required("When would you like to be picked up?")
                .typeError("When would you like to be picked up?"),
    }),
    dropOffTime: Yup.date().when("timeType", {
        is: TimeTypes.droppedOff, // alternatively: (val) => val == true
        then: (schema) =>
            schema
                .required("When would you like to be dropped off?")
                .typeError("When would you like to be dropped off?"),
    }),
    pickUpPlace: Yup.object()
        .concat(addressSchema)
        .shape({
            description: Yup.string().required(
                "Where would you like to be picked up?"
            ),
        })
        .required("Where would you like to be picked up?"),
    dropOffPlace: Yup.object()
        .concat(addressSchema)
        .shape({
            description: Yup.string().required("Where would you like to go?"),
        })
        .required("Where would you like to go?"),
    tripProgram: Yup.string().required("Please select a trip program"),
    returnType: Yup.number(),
});
