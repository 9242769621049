// shared typing for the whole system

export enum PassioService {
    paraplan = "paraplan",
    navigator = "navigator",
}

export enum TimeTypes {
    pickedUp = "Picked Up",
    droppedOff = "Dropped Off",
}

export interface BaseRequest {
    service: PassioService;
}

// extensible response types
export interface ResponseStatus {
    errorMessage: string;
    success: boolean;
    tokenExists: boolean;
    tokenIsValid: boolean;
}

export interface ListResponse<T> extends ResponseStatus {
    list: Array<T>;
}

export interface EntityResponse<T> extends ResponseStatus {
    entity: T;
}

export enum TripScheduleStatus {
    // Unscheduled = 0,
    Scheduled = 1,
    Cancelled = 2,
    Noshowed = 3,
    Autoscheduled = 4,
    PickUpArrived = 5,
    PickUpPerformed = 6,
    DropOffArrived = 7,
    DropOffPerformed = 8,
    TripSigned = 9,
    Undo = 10,
    Downloaded = 11,
    BuiltFromPrevious = 12,
    NotifiedViaVoice = 13,
    NotifiedViaSMS = 14,
    NotifiedViaEmail = 15,
    NotifiedViaPush = 16,
    ScheduledFromPrevious = 17,
    ScheduledFromAutoRoute = 18,
    ScheduledManually = 19,
    TripChanged = 20,
    ScheduledRightClickAuto = 21,
    NotifiedOfCancelViaVoice = 22,
    SentTripDetailsViaEmail = 23,
    AcceptedTripRequest = 24,
    /**
     * Dispatcher has been alerted
     * about new trip that needs to be
     * assigned to route
     */
    CASH_WaitingOnDispatcher = 30,
    /**
     * Driver has been alerted that
     * a new trip needs to be scheduled
     * or self-assigned
     */
    CASH_WaitingOnDriver = 31,
    CASH_WaitingOnAutoSchedule = 32,
    CASH_NotifiedDispatcher = 35,
    CASH_AutoScheduleFailed = 38,
    CASH_AutoScheduleSucceeded = 39,
    WaitingForApproval = 98,
    NotApproved = 50,
    Unknown = 99,
}

export enum TripCancelReason {
    cancelledByManager = "Cancelled by Mobility Manager",
    cancelledByRider = "Cancelled by Rider",
}

export enum AutoScheduleFailAction {
    NoAction = 0,
    EmailDispatcher = 1,
    CancelTripAndLetRiderKnow = 2,
}
