import { Fragment } from "react";
import { Stack, MenuItem, useTheme, useMediaQuery } from "@mui/material";
import { FormDateTimePicker, FormInput, FormTimePicker } from "src/components";
import {
    returnTripOptions,
    validateByCalendar,
    ReturnTripTypes,
} from "../helpers";
import { authSelector } from "src/modules/auth/redux/authSlice";
import { PassioService } from "src/types";
import { useAppSelector } from "src/hooks";
import { useFormikContext } from "formik";
import { TripRequestFormValues } from "../types";
import { Client } from "src/modules/auth/types";

interface Props {
    client: Client;
    disablePast: boolean;
}

const ReturnTripOptions = ({ client, disablePast = true }: Props) => {
    const { service } = useAppSelector(authSelector);
    const { values } = useFormikContext<TripRequestFormValues>();

    const theme = useTheme();
    const isMobileDevice = useMediaQuery(theme.breakpoints.down("md"));
    const isNavigator = service === PassioService.navigator;
    /* How the rider is going to get back. 0 = One way, 1 = Call for return, 2 = Specific return time requested */

    return (
        <Stack
            spacing={2}
            direction={{
                xs: "column",
                md: "row",
            }}
        >
            {/* How the rider is going to get back. 0 = One way, 1 = Call for return, 2 = Specific return time requested */}
            <FormInput
                name="returnType"
                label="For my return trip, I'd like"
                select
                valueAsNumber
                SelectProps={{
                    native: isMobileDevice,
                }}
                sx={{ flexGrow: 1 }}
            >
                {returnTripOptions.map((item, index) => {
                    const show = isNavigator
                        ? item.value !== ReturnTripTypes.callForReturn
                        : true;

                    if (!show) return null;
                    return isMobileDevice ? (
                        <option key={index} value={item.value}>
                            {item.label}
                        </option>
                    ) : (
                        <MenuItem key={index} value={item.value}>
                            {item.label}
                        </MenuItem>
                    );
                })}
            </FormInput>
            {/* if roundtrip */}
            {values.returnType === ReturnTripTypes.specificTime && (
                <Fragment>
                    {isNavigator ? (
                        <FormDateTimePicker
                            name="returnTime"
                            label="Return Pick Up Time"
                            sx={{ flexGrow: 1 }}
                            disablePast={disablePast}
                            minTime={values.apptTime}
                            validate={(date) => {
                                return validateByCalendar(
                                    client,
                                    date,
                                    values.tripProgram,
                                    "time"
                                );
                            }}
                        />
                    ) : (
                        <FormTimePicker
                            name="returnTime"
                            label="Return Pick Up Time"
                            sx={{ flexGrow: 1 }}
                            disablePast={disablePast}
                            minTime={values.apptTime}
                            validate={(date) => {
                                return validateByCalendar(
                                    client,
                                    date,
                                    values.tripProgram,
                                    "time"
                                );
                            }}
                        />
                    )}
                </Fragment>
            )}
        </Stack>
    );
};

export default ReturnTripOptions;
