import { RiderExperience } from "src/modules/trips/pages/details/types";
import { formatDisplayTime } from "./email";
import { OnDemandTripRequest } from "src/modules/trips/types";

export function formatDriverArrivedSMS({
    pickUpTime = "",
    dropOffAddress = "",
}: RiderExperience) {
    return `The driver for your ${formatDisplayTime(
        pickUpTime
    )} trip to ${dropOffAddress} has arrived`;
}

export function formatTripNotApprovedSMS() {
    return `Your trip request has not been approved`; // todo : add reason
}

export function formatTripCancelledSMS(reason = "") {
    return `Your trip request was cancelled ${
        reason ? `due to ${reason}` : ""
    }.`;
}

export function formatTripApprovedSMS({
    pickUpTime = "",
    dropOffAddress = "",
}: RiderExperience | OnDemandTripRequest | any) {
    return `Your ${formatDisplayTime(
        pickUpTime
    )} trip to ${dropOffAddress} has been approved.`;
}

export function formatTripAssignedSMS(
    { pickUpTime = "", dropOffAddress = "" }: RiderExperience,
    driverName = ""
) {
    return `Your ${formatDisplayTime(
        pickUpTime
    )} trip to ${dropOffAddress} has been assigned to ${driverName}.`;
}

export function formatNewAccountSMS(username = "") {
    return `Your transportation account has been created for ${username}.`;
}

export const autoScheduleFailedSMS = `We can't schedule your requested trip right now due to availability. Please try again later or contact support for assistance.`;
