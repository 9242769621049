import { Fragment } from "react";
import {
    Card,
    Stack,
    CardActions,
    CardContent,
    Typography,
    Divider,
    Chip,
} from "@mui/material";
import { StatusTypes, TripHistory } from "../../../types";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { formatReservationName, getStatusColor } from "../helpers";
import { useAppSelector } from "src/hooks";
import { PassioService } from "src/types";
import TableActions from "./TableActions";
import moment from "moment";
import Ratings from "./Ratings";

const TripCard = ({ row, ...rest }: GridRenderCellParams<TripHistory>) => {
    const service = useAppSelector((state) => state.auth.service);
    const isNavigator = service === PassioService.navigator;
    const isScheduled = row?.secondLine.includes(StatusTypes.Scheduled);

    const color = getStatusColor(
        isScheduled ? StatusTypes.Scheduled : row.secondLine
    );

    return (
        <Card
            sx={{
                my: 2,
                borderRadius: 2,
                width: "100%",
            }}
            elevation={5}
        >
            <CardContent sx={{ p: 2 }}>
                <Stack
                    spacing={4}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    mt={1}
                    mb={2}
                >
                    <Typography variant="h6" fontWeight="medium">
                        {formatReservationName(row.reservationType) || "N/A"}
                    </Typography>

                    <Chip
                        color={color}
                        label={
                            isScheduled
                                ? StatusTypes.Scheduled
                                : row.secondLine || StatusTypes.Pending
                        }
                    />
                </Stack>
                <Typography
                    variant="subtitle2"
                    gutterBottom
                    fontWeight="medium"
                >
                    Trip Date
                </Typography>
                <Typography variant="body2" gutterBottom mb={2}>
                    {row.tripDate ? moment(row.tripDate).format("LLLL") : "N/A"}
                </Typography>
                <Typography
                    variant="subtitle2"
                    gutterBottom
                    fontWeight="medium"
                >
                    Pick Up Place
                </Typography>
                <Typography variant="body2" gutterBottom mb={2}>
                    {row.pickUpPlace?.name || "N/A"}
                </Typography>
                <Typography
                    variant="subtitle2"
                    gutterBottom
                    fontWeight="medium"
                >
                    Drop Off Place
                </Typography>
                <Typography variant="body2" gutterBottom mb={2}>
                    {row.dropOffPlace?.name || "N/A"}
                </Typography>
                {isScheduled &&
                    row.secondLine
                        .trim()
                        .split("\r\n")
                        .map((item, index) => (
                            <Typography
                                variant="body2"
                                gutterBottom
                                mb={2}
                                key={index}
                            >
                                {item}
                            </Typography>
                        ))}

                {isNavigator && (
                    <Fragment>
                        <Typography
                            variant="subtitle2"
                            gutterBottom
                            fontWeight="medium"
                        >
                            Trip Rating
                        </Typography>
                        <Ratings id={row.databaseId} rating={row.tripRating} />
                    </Fragment>
                )}
            </CardContent>
            <Divider />
            <CardActions>
                <TableActions {...rest} row={row} />
            </CardActions>
        </Card>
    );
};

export default TripCard;
