import { useCallback, useEffect, useState, useRef } from "react";
import {
    Box,
    Link,
    Stack,
    Divider,
    Button,
    Container,
    Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Formik, Form } from "formik";
import { enqueueSnackbar } from "notistack";
import { authAPI, authActions, authSelector } from "../redux/authSlice";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import {
    FormInput,
    FormCheckbox,
    Splashscreen,
    Logo,
    Footer,
} from "src/components";
import { version, utcOffset } from "src/utils/constants";
import {
    getService,
    getServiceBrandName,
    isErrorWithMessage,
} from "src/utils/helpers";
import { useAppDispatch, useAppSelector } from "src/hooks";
import type {
    Client,
    Config,
    Credentials,
    FormattedUserAndClient,
    User,
} from "../types";
import { generateLoginQuery } from "../helpers";
import { loginSchema } from "../schema";
import { useTitle } from "react-use";
import { PassioService } from "src/types";
import { tripsAPI } from "src/modules/trips/redux/tripSlice";
import { navigatorAPIUrl } from "src/utils/constants";
import GoogleLogo from "src/images/google-signin.png";
import MSLogo from "src/images/microsoft-logo.png";

/**
 * @param agency: agency name
 * @param service paraplan | navigator
 * @example /login?agency=ENGRAPHTRANSIT&service=paraplan
 */

const Login = () => {
    useTitle("Login");
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const agency = searchParams.get("agency") ?? "";
    const service = getService();
    const isNavigator = service === PassioService.navigator;

    const [login, { isLoading }] = authAPI.useLoginMutation();
    const [passioLogin, { isLoading: passioLoading }] =
        authAPI.usePassioLoginMutation();
    const [processing, setProcessing] = useState({
        active: false,
        message: "processing",
    });

    const {
        config,
        autoLogin,
        savedLogin,
        service: savedService,
    } = useAppSelector(authSelector);
    const autoLoginChecked = useRef(false); // use a ref to track if autologin has been checked, prevents login loops
    const shouldAutoLogin =
        autoLogin && savedLogin && savedLogin?.email && savedLogin?.password;

    /*
     * @params resume - if user is resuming a session and has saved creds
     * @params values - user credentials could be cached or filled in form
     */
    const handleLogin = useCallback(
        async (values: Credentials, resume = false) => {
            try {
                const parsedService = resume ? savedService : service;
                const isNavigator = parsedService === PassioService.navigator;
                const request = generateLoginQuery(
                    values,
                    parsedService,
                    resume
                );
                let user = {} as User;
                let rider = {} as Client;
                let config = {} as Config;

                if (isNavigator) {
                    // login to navigator, get onDemandUser and rider profile
                    const auth = await passioLogin(request).unwrap();

                    // load services first so that we can format rider programs
                    setProcessing({
                        active: true,
                        message: "Loading Services",
                    });

                    const programs = await dispatch(
                        authAPI.endpoints.getPrograms.initiate({
                            id: auth.userId,
                        })
                    );

                    if (programs.isError) {
                        throw new Error("Sorry we couldn't load your services");
                    }

                    // load onDemandUser and rider profile
                    setProcessing({
                        active: true,
                        message: "Loading Account",
                    });

                    const onDemandUser = await dispatch(
                        authAPI.endpoints.getOnDemandUser.initiate(auth.id)
                    );

                    if (onDemandUser.isError) {
                        throw new Error("Sorry we couldn't load your services");
                    }

                    const profile = onDemandUser.data as FormattedUserAndClient;
                    rider = profile.client;
                    user = profile.user;
                } else {
                    user = await login(request).unwrap(); // response is auto saved in state
                }

                // Only proceed if user is authorized
                if (user.PPPAccess !== 1) {
                    throw new Error("Portal access is not available");
                }

                // Preserve hashed credentials if autologin is enabled
                if (values.autoLogin) {
                    const cache = { autoLogin: true, savedLogin: request };
                    dispatch(authActions.save(cache));
                }

                /*
                 * Navigator config was already populated from  user object in previous step
                 * Only fetch config if service is Paraplan
                 */

                setProcessing({ active: true, message: "Loading Preferences" });

                const agencyConfig = await dispatch(
                    authAPI.endpoints.getConfig.initiate(rider.agencyId)
                );

                if (!agencyConfig.data || agencyConfig.isError) {
                    throw new Error("Sorry we couldn't load your preferences");
                }

                config = agencyConfig.data;

                /*
                 * Load passioToken, if service is Paraplan.
                 * Geofence calls are made to Passio API and need a navigator token
                 */

                // Todo: skip load when onDemandAccess can call geofence
                setProcessing({
                    active: true,
                    message: "Authorizing Passio Access",
                });

                const passioToken = await dispatch(
                    authAPI.endpoints.getPassioToken.initiate()
                );

                if (passioToken.isError) {
                    throw new Error("Sorry we couldn't authorize your access");
                }

                // load geozones
                setProcessing({ active: true, message: "Loading Geozones" });

                const agency = isNavigator ? config?.PassioUsername : "";
                const geozones = await dispatch(
                    authAPI.endpoints.getGeoZones.initiate(agency)
                );

                if (geozones.isError) {
                    throw new Error("Sorry we couldn't load your zones");
                }

                // Paraplan: Additional checks if user is a manager
                const isManager = user.ClientID === 0 || user.UserType === 1;
                if (!isNavigator && isManager) {
                    if (!user.ProviderPrograms) {
                        throw new Error("Invalid mobility manager infomation");
                    }

                    if (
                        !user.ProviderCanViewClientDetails ||
                        !user.ProviderCanRequestTrips
                    ) {
                        throw new Error("Unauthorized");
                    }
                }

                // If client is manager or service is navigator, load programs
                if (isNavigator || isManager) {
                    const type = isNavigator ? "Services" : "Programs";
                    setProcessing({ active: true, message: `Loading ${type}` });

                    const programs = await dispatch(
                        authAPI.endpoints.getPrograms.initiate({
                            id: rider.agencyId,
                        })
                    );

                    if (programs.isError) {
                        const message = isNavigator
                            ? "Sorry we couldn't find any services for your agency"
                            : "Sorry we couldn't load your programs";
                        throw new Error(message);
                    }
                } else {
                    // Paraplan: Load client profile
                    setProcessing({
                        active: true,
                        message: "Loading Client Profile",
                    });

                    const client = await dispatch(
                        authAPI.endpoints.getClient.initiate(user.ClientID)
                    );

                    if (client.isError) {
                        throw new Error("Sorry we couldn't load your profile");
                    }
                }

                // check location preferences
                const preferences = config?.ConnectPreferences;
                if (
                    !preferences?.CanRequestAnyPULocation ||
                    !preferences?.CanRequestAnyDOLocation
                ) {
                    // load places
                    setProcessing({ active: true, message: "Loading Places" });

                    const places = await dispatch(
                        authAPI.endpoints.getPlaces.initiate()
                    );

                    if (places.isError) {
                        throw new Error("Sorry we couldn't load places");
                    }
                }

                if (isNavigator) {
                    // load devices
                    setProcessing({ active: true, message: "Loading Devices" });
                    const devices = await dispatch(
                        tripsAPI.endpoints.getDevices.initiate(rider.agencyId)
                    );

                    if (devices.isError) {
                        throw new Error("Sorry we couldn't load devices");
                    }

                    // load MDTs
                    setProcessing({ active: true, message: "Loading MDTs" });
                    const MDTs = await dispatch(
                        tripsAPI.endpoints.getMDTs.initiate()
                    );

                    if (MDTs.isError) {
                        throw new Error("Sorry we couldn't load MDTs");
                    }
                }
                /*
                 * All state is filtered directly in redux state matchers after request complete
                 * check the api slices for each module to learn more
                 */

                enqueueSnackbar("Login successful", { variant: "success" });

                // if user was redirected to login page, redirect back to where they came from
                if (location.state?.from) {
                    navigate(location.state.from, { replace: true, state: {} });
                } else if (user.UserType === 1) {
                    // Redirect to riders list if user is a mobility manager
                    navigate("/riders");
                } else {
                    navigate("/trips");
                }
            } catch (error) {
                if (isErrorWithMessage(error)) {
                    enqueueSnackbar(error.message, { variant: "error" });
                } else {
                    enqueueSnackbar(
                        "Login failed. Please check your password/PIN ",
                        { variant: "error" }
                    );
                }
            }

            setProcessing({ active: false, message: "" });
        },
        [
            dispatch,
            location,
            login,
            passioLogin,
            navigate,
            service,
            savedService,
        ]
    );

    // handle autologin
    useEffect(() => {
        if (shouldAutoLogin && !autoLoginChecked.current) {
            handleLogin({ ...savedLogin, autoLogin }, true);
            setProcessing({
                active: true,
                message: "Auto login enabled, we are restoring your session",
            });
        }
        return () => {
            autoLoginChecked.current = true;
        };
    }, [shouldAutoLogin, autoLogin, savedLogin, handleLogin]);

    // show loader when processing
    if (processing.active) {
        return <Splashscreen description={processing.message} />;
    }

    return (
        <Container
            component="main"
            maxWidth="xs"
            sx={{
                display: "grid",
                placeItems: "center",
                height: "100vh",
            }}
        >
            <Box
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Logo />

                <Typography component="h2" variant="h5">
                    {config?.ConnectPreferences?.DisplayName}
                </Typography>
                {isNavigator && (
                    <Stack spacing={2} sx={{ mt: 4, width: "100%" }}>
                        <Button
                            variant="outlined"
                            fullWidth
                            sx={{ p: 0 }}
                            href={`${navigatorAPIUrl}/auth/oauth2/connect/rider/google`} // api expects rider to redirect here after login
                        >
                            <Box
                                component="img"
                                alt="logo"
                                src={GoogleLogo}
                                sx={{
                                    height: 50,
                                    objectFit: "contain",
                                    p: 1.5,
                                }}
                            />
                        </Button>
                        <Button
                            variant="outlined"
                            fullWidth
                            sx={{ p: 0 }}
                            href={`${navigatorAPIUrl}/auth/oauth2/connect/rider/microsoft`} // api expects rider to redirect here after login
                        >
                            <Box
                                component="img"
                                alt="logo"
                                src={MSLogo}
                                sx={{
                                    height: 50,
                                    objectFit: "contain",
                                    p: 1.7,
                                }}
                            />
                        </Button>
                        <Divider>or</Divider>
                    </Stack>
                )}

                <Formik
                    initialValues={{
                        email: "",
                        password: "",
                        autoLogin: false,
                    }}
                    validationSchema={loginSchema}
                    onSubmit={(values) => handleLogin(values)}
                >
                    <Box component={Form} sx={{ mt: 1 }}>
                        <FormInput
                            fullWidth
                            name="email"
                            label="Email address"
                            margin="normal"
                            inputProps={{
                                autoComplete: "email",
                            }}
                        />
                        <FormInput
                            fullWidth
                            name="password"
                            label="Password or Pin"
                            type="password"
                            margin="normal"
                            inputProps={{
                                autoComplete: "password",
                            }}
                        />

                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <FormCheckbox
                                label="Remember me"
                                name="autoLogin"
                            />
                            {isNavigator && (
                                <Link
                                    href={`/recovery?service=${PassioService.navigator}`}
                                >
                                    Forgot password?
                                </Link>
                            )}
                        </Stack>
                        <LoadingButton
                            size="large"
                            type="submit"
                            fullWidth
                            variant="contained"
                            loading={isLoading || passioLoading}
                            sx={{ my: 2, py: 1, color: "primary.contrastText" }}
                        >
                            {isLoading || passioLoading
                                ? "Verifying account"
                                : "Sign In"}
                        </LoadingButton>
                    </Box>
                </Formik>

                {/* Signup is only available via agency */}
                {agency && config?.AgencyName && (
                    <Typography align="center" my={2}>
                        Need a connect account?{" "}
                        <Link href="/signup">Sign up</Link>
                    </Typography>
                )}

                <Typography align="center" variant="body2">
                    {`${getServiceBrandName(service)} V ${
                        process.env.REACT_APP_VERSION
                    } ${version} UTC ${utcOffset}`}
                </Typography>
            </Box>

            <Footer />
        </Container>
    );
};

export default Login;
