import { useMemo, useState, memo, useCallback, useRef, useEffect } from "react";
import { useFormikContext } from "formik";
import { Grid, FormLabel, FormControl, Box } from "@mui/material";
import {
    FormInput,
    PlacePicker,
    GoogleMap,
    PlacesAutocomplete,
} from "src/components";
import { authSelector } from "src/modules/auth/redux/authSlice";
import { useAppSelector } from "src/hooks";
import { enqueueSnackbar } from "notistack";
import { tripsAPI } from "src/modules/trips/redux/tripSlice";
import { getMapShape } from "./helpers";
import { TripRequestFormValues } from "../types";
import { MapGeoFence } from "src/components/map/GoogleMap";
import { ParsedAddress } from "src/components/forms/types";
import { OUT_OF_ZONE_ERROR } from "../helpers";
import { PassioService } from "src/types";
import TripPrograms from "./TripPrograms";

interface ProcessingState {
    active?: boolean;
    pickUp?: boolean;
    dropOff?: boolean;
    message?: string;
}

interface Props {
    isOnDemand: boolean;
}

const LocationSelector = ({ isOnDemand = false }: Props) => {
    const geofenceChecked = useRef(false);
    const { config, client, places, geozones, passioToken, service, programs } =
        useAppSelector(authSelector);
    const { ConnectPreferences: preferences, Location } = config;
    const isNavigator = service === PassioService.navigator;
    const { values, validateForm } = useFormikContext<TripRequestFormValues>();
    const [getGeofence] = tripsAPI.useGetGeofenceMutation();
    const [checkGeoEligibility] =
        tripsAPI.useCheckGeographicEligibiltyMutation();

    const [geofence, setGeofence] = useState<MapGeoFence>({
        polygons: [],
        circles: [],
        zones: [],
    });

    const [processing, setProcessing] = useState<ProcessingState>({
        active: false,
        pickUp: false,
        dropOff: false,
        message: "processing ...",
    });

    const pickUpSpots = useMemo(() => {
        const filtered = places?.filter((item) => item.isPickUpSpot) ?? [];
        return filtered.sort((a, b) => -b.name.localeCompare(a.name));
    }, [places]);

    const dropOffSpots = useMemo(() => {
        const filtered = places?.filter((item) => item.isDropOffSpot) ?? [];
        return filtered.sort((a, b) => -b.name.localeCompare(a.name));
    }, [places]);

    const getActiveZones = useCallback(
        (programName: string) => {
            const list = isNavigator ? programs : client?.programs;
            const program = list?.find(
                (program) => program.programName === programName
            );
            return program ? program?.activeGeozoneIds : [];
        },
        [isNavigator, client.programs, programs]
    );

    async function handleGeoEligibilityCheck(
        latitude: number,
        longitude: number,
        type: keyof ProcessingState = "pickUp"
    ) {
        const activeZones = getActiveZones(values.tripProgram);

        // if we dont have active zones but a check was requested, return true
        if (!activeZones?.length && latitude && longitude) return true;

        // if coords are invalid and zones are active return false
        if (activeZones?.length && (!latitude || !longitude)) return false;

        try {
            setProcessing({ [type]: true });
            const request = {
                latitude,
                longitude,
                passioToken: passioToken,
                passioAgency: config.PassioAgency,
            };
            const response = await checkGeoEligibility(request).unwrap();
            const zonePoints = response.map((zone) => zone.id);
            const isInZone = activeZones.some((zone) =>
                zonePoints.includes(zone)
            );
            setProcessing({ [type]: false });
            return isInZone;
        } catch (error) {
            enqueueSnackbar(
                "Sorry that address is probably not in our service area",
                {
                    variant: "error",
                }
            );
            setProcessing({ [type]: false });
        }
    }

    // check eligibility if trip program has active zones
    async function validateAddress(
        lat: number,
        lng: number,
        type: keyof ProcessingState = "pickUp"
    ) {
        let error = "";
        if (!lat || !lng) return error;

        const isInZone = await handleGeoEligibilityCheck(lat, lng, type);
        if (!isInZone) {
            error = OUT_OF_ZONE_ERROR;
        }
        return error;
    }

    const handleLoadGeoFence = useCallback(
        async (programName: string) => {
            // reset map
            setGeofence({ polygons: [], circles: [], zones: [] });

            try {
                const activeZones = getActiveZones(programName);
                if (!activeZones.length) return;
                // request might be multiple, using the redux loading state won't be ideal if so
                setProcessing({
                    active: true,
                    message: "Mapping active zones",
                });

                for (const zone of activeZones) {
                    const currentZone: any = geozones?.find(
                        (item: any) => String(item.ID || item.id) === zone
                    );

                    // if zone is active, we load the fence
                    if (currentZone) {
                        let geofence: any = {};
                        if (isNavigator) {
                            geofence = currentZone;
                        } else {
                            const request = {
                                passioToken: passioToken,
                                passioAgency: config.PassioAgency,
                                id: currentZone.ID,
                            };

                            geofence = await getGeofence(request).unwrap();
                        }
                        setGeofence((current) => {
                            const key = `${geofence.shape.type}s`;
                            if (!current[key]) {
                                return current;
                            }
                            return {
                                ...current,
                                [key]: [...current[key], getMapShape(geofence)],
                                zones: activeZones,
                            };
                        });
                    }
                }
            } catch {}
            // all done lets clear up
            setProcessing({ active: false, message: "" });
        },
        [
            getActiveZones,
            getGeofence,
            config.PassioAgency,
            geozones,
            passioToken,
            isNavigator,
        ]
    );

    async function handleTripProgram(value: string) {
        // refresh geofence then recheck addresses
        await handleLoadGeoFence(value);
        // revalidate form, time fields are dependent on trip programs
        await validateForm();
    }

    useEffect(() => {
        if (!geofenceChecked.current && values.tripProgram) {
            handleLoadGeoFence(values.tripProgram);
        }
        return () => {
            geofenceChecked.current = true;
        };
    }, [values.tripProgram, handleLoadGeoFence]);

    return (
        <Grid
            container
            item
            sx={{
                p: 2,
                mb: 4,
                boxShadow: 1,
                borderRadius: 1,
            }}
        >
            <Grid
                item
                xs={12}
                md={6}
                sx={{ pr: { md: 2 }, mb: { xs: 4, md: 0 }, display: "grid" }}
            >
                {/* Show custom place picker if user cannot request all locations */}
                {/* Pickup Address */}
                <FormControl
                    component="fieldset"
                    sx={{ mb: 4, display: "flex", gap: 2 }}
                >
                    <FormLabel
                        component="legend"
                        sx={{
                            mb: 2,
                            fontSize: 20,
                            color: "primary.main",
                        }}
                    >
                        Pick Up{" "}
                        {!preferences?.CanRequestAnyPULocation
                            ? "Spot"
                            : "Address"}
                    </FormLabel>

                    {!preferences?.CanRequestAnyPULocation ? (
                        <PlacePicker
                            label="Address"
                            id="pickUpPlace"
                            name="pickUpPlace"
                            options={pickUpSpots}
                            sx={{ flexGrow: 1 }}
                        />
                    ) : (
                        <PlacesAutocomplete
                            label="Address"
                            id="pickUpPlace"
                            name="pickUpPlace"
                            loading={processing.pickUp}
                            validate={(address: ParsedAddress) => {
                                return validateAddress(
                                    address.latitude,
                                    address.longitude,
                                    "pickUp"
                                );
                            }}
                        />
                    )}

                    {!isOnDemand && (
                        <FormInput
                            multiline
                            name="pickUpAddress2"
                            label="Apt/Suite/Notes"
                        />
                    )}
                </FormControl>

                {/* DropOff address */}
                <FormControl
                    component="fieldset"
                    sx={{ mb: 4, display: "flex", gap: 2 }}
                >
                    <FormLabel
                        component="legend"
                        sx={{
                            mb: 2,
                            fontSize: 20,
                            color: "primary.main",
                        }}
                    >
                        Drop Off{" "}
                        {!preferences?.CanRequestAnyDOLocation
                            ? "Spot"
                            : "Address"}
                    </FormLabel>
                    {!preferences?.CanRequestAnyDOLocation ? (
                        <PlacePicker
                            label="Address"
                            id="dropOffPlace"
                            name="dropOffPlace"
                            options={dropOffSpots}
                            sx={{ flexGrow: 1 }}
                        />
                    ) : (
                        <PlacesAutocomplete
                            label="Address"
                            id="dropOffPlace"
                            name="dropOffPlace"
                            validate={(address: ParsedAddress) => {
                                return validateAddress(
                                    address.latitude,
                                    address.longitude,
                                    "dropOff"
                                );
                            }}
                            loading={processing.dropOff}
                        />
                    )}

                    {!isOnDemand && (
                        <FormInput
                            multiline
                            name="dropOffAddress2"
                            label="Apt/Suite/Notes"
                        />
                    )}
                </FormControl>

                {/* Trip program */}
                <Box sx={{ ...(isOnDemand && { order: -1, mb: 2 }) }}>
                    <TripPrograms
                        loading={processing.active}
                        geofence={geofence}
                        onChange={(evt) => {
                            handleTripProgram(evt.target.value);
                        }}
                    />
                </Box>
            </Grid>
            {/* map view */}
            <Grid item xs={12} md={6} mb={3}>
                <GoogleMap
                    loading={processing.active}
                    geofence={geofence}
                    pickUpCoords={{
                        lat: values.pickUpPlace.latitude || Location?.Latitude,
                        lng:
                            values.pickUpPlace.longitude || Location?.Longitude,
                    }}
                    dropOffCoords={{
                        lat: values.dropOffPlace.latitude,
                        lng: values.dropOffPlace.longitude,
                    }}
                    sx={{ height: 450, width: "100%" }}
                />
            </Grid>
        </Grid>
    );
};

export default memo(LocationSelector);
