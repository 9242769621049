import { createSlice, createAction, createSelector } from "@reduxjs/toolkit";
import type { RootState } from "src/redux";
import type { User, Client, Config, Place, Program, Geozone } from "../types";
import type * as AuthTypes from "../types";
import API from "src/redux/api";
import {
    device,
    navigatorAPIUrl,
    paraPlanApiUrl,
    passioEmail,
    passioPassword,
    utcOffset,
} from "src/utils/constants";
import {
    createQueryError,
    filterFundingSources,
    getService,
    isNavigatorError,
    isParaplanError,
    validateNavigatorStatus,
    validateParaplanStatus,
} from "src/utils/helpers";
import { EntityResponse, ListResponse, PassioService } from "src/types";
import { pick } from "lodash";
import { PassioGeofence } from "src/modules/trips/types";
import * as formatters from "../helpers";

// Define a type for the slice state
interface AuthState {
    user: User;
    token: string;
    passioToken: string;
    serviceUrl: string;
    service: PassioService;
    autoLogin?: boolean;
    savedLogin?: {
        email: string;
        password: string;
    };
    geozones: Geozone[] | PassioGeofence[];
    client: Client;
    clients: Client[];
    config: Config;
    places: Place[];
    programs: Program[];
    availableFundingSources: string[];
    isManager: boolean;
    tokenExists: boolean;
    tokenIsValid: boolean;
    debug: boolean;
    customerIds: string[];
}

// Define the initial state using that type
const initialState: AuthState = {
    user: {} as User,
    token: "",
    tokenExists: false,
    tokenIsValid: false,
    passioToken: "",
    service: PassioService.paraplan,
    serviceUrl: "",
    geozones: [],
    client: {} as Client,
    clients: [],
    config: {} as Config,
    places: [],
    programs: [],
    availableFundingSources: [],
    customerIds: [],
    isManager: false,
    debug: false,
};

export const authAPI = API.injectEndpoints({
    endpoints: (build) => ({
        login: build.mutation<AuthTypes.User, AuthTypes.Credentials>({
            query: (data) => ({
                url: `${paraPlanApiUrl}/UserService/Login?UserName=${data.email}&Password=${data.password}&Device=${device}&Version=0.1&UTCOffset=${utcOffset}`,
                /**
                 * Example: we have a backend API always returns a 200,
                 * but sets an `errorMessage` property when there is an error.
                 */
                validateStatus: (response, result) =>
                    response.status === 200 && result?.success,
            }),
        }),
        passioLogin: build.mutation<
            AuthTypes.OnDemandLogin,
            AuthTypes.Credentials
        >({
            query: (data) => ({
                url: `${navigatorAPIUrl}/ondemand/login`,
                method: "post",
                body: data,
            }),
        }),
        getOnDemandUser: build.query<
            AuthTypes.FormattedUserAndClient,
            string | number
        >({
            queryFn: async (id, api, extra, baseQuery) => {
                // load user profile
                const profileRequest = {
                    url: "/ondemand/tdb/get",
                    method: "post",
                    body: { type: "onDemandUser", id },
                };

                const profile = await baseQuery(profileRequest);
                if (profile.error) return { error: profile.error };
                const user = profile.data as AuthTypes.OnDemandUser;
                // load rider profile
                // TODO: add support for multiple profiles, select by agencyId

                const riderRequest = {
                    url: "/ondemand/tdb/get",
                    method: "post",
                    body: {
                        type: "rider",
                        filter: { onDemandUserId: user.id },
                    },
                };

                const riderResponse = await baseQuery(riderRequest);
                if (riderResponse.error) return { error: riderResponse.error };
                else if (!(riderResponse.data as any)?.length)
                    return { error: createQueryError("invalid rider") };

                const programs = (api.getState() as RootState)?.auth.programs;
                const formatted = formatters.formatUserAndClient(
                    user,
                    (riderResponse.data as AuthTypes.PassioRider[])[0],
                    programs
                );
                return { data: formatted };
            },
        }),
        getPassioToken: build.query<AuthTypes.PassioLoginResponse, void>({
            query: () => ({
                url: `${navigatorAPIUrl}/auth/login`,
                method: "post",
                body: {
                    username: passioEmail,
                    password: passioPassword,
                    extended: 1,
                },
                validateStatus: validateNavigatorStatus,
            }),
        }),
        getClient: build.query<Client, number | string>({
            query: (id) => ({
                url: `ClientService/Client/${id}`,
                validateStatus: (response, result) =>
                    response.status === 200 && result?.success,
            }),
        }),
        getClients: build.query<ListResponse<AuthTypes.Client>, boolean>({
            query: (lightWeight) => ({
                url: "ClientService/Client/ProgramParticipants/",
                params: {
                    Lightweight: lightWeight,
                },
                validateStatus: (response, result) =>
                    response.status === 200 && result?.success,
            }),
        }),
        getPrograms: build.query<Program[], AuthTypes.GetProgramsRequest>({
            queryFn: async ({ id, token }, api, extra, baseQuery) => {
                const state = api.getState() as RootState;
                if (state.auth.service === PassioService.navigator) {
                    // we also load services from the Authlayout component for signup
                    const url = token
                        ? `${navigatorAPIUrl}/tdb/get?accessToken=${token}`
                        : "/ondemand/tdb/get/";
                    const request = {
                        url,
                        method: "post",
                        body: { type: "service", userId: id },
                        validateStatus: validateNavigatorStatus,
                    };

                    const { data, error } = await baseQuery(request);
                    if (error) return { error };
                    else if (!(data as AuthTypes.Service[])?.length) {
                        return { error: createQueryError("invalid services") };
                    }

                    const programs = formatters.formatPrograms(
                        data as AuthTypes.Service[]
                    );
                    return { data: programs };
                }

                const { data, error } = await baseQuery(
                    "ProgramService/Programs"
                );

                if (error) return { error };
                else if (isParaplanError(data))
                    return { error: (data as any)?.errorMessage };
                return { data: (data as ListResponse<Program>).list };
            },
        }),
        getGeoZones: build.query<Geozone[] | PassioGeofence[], string | void>({
            queryFn: async (agency, api, extra, baseQuery) => {
                const state = api.getState() as RootState;
                const { passioToken, service } = state.auth;

                if (service === PassioService.navigator) {
                    const { data, error } = await baseQuery(
                        `${navigatorAPIUrl}/${agency}/passioTransit/geofence?accessToken=${passioToken}`
                    );
                    if (error) return { error };
                    return { data: data as PassioGeofence[] };
                }

                const { data, error } = await baseQuery(
                    "ProgramService/Geozones"
                );
                if (error) return { error };
                else if (isParaplanError(data))
                    return { error: (data as any)?.errorMessage };
                return { data: (data as ListResponse<Geozone>).list };
            },
        }),
        getConfig: build.query<Config, string | number | void>({
            queryFn: async (id, api, extra, baseQuery) => {
                const state = api.getState() as RootState;
                if (state.auth.service === PassioService.navigator) {
                    const request = {
                        url: "/ondemand/tdb/get/",
                        method: "post",
                        body: {
                            type: "user",
                            id,
                            field: formatters.passioUserFields,
                        },
                    };

                    const { data, error } = await baseQuery(request);
                    if (error) return { error };

                    const config = formatters.formatConfig(
                        data as AuthTypes.PassioUser
                    );
                    return { data: config };
                }

                const { data, error } = await baseQuery("UserService/Config");
                if (error) return { error };
                else if (isParaplanError(data))
                    return { error: (data as any)?.errorMessage };

                return { data: (data as EntityResponse<Config>).entity };
            },
        }),
        getPlaces: build.query<Place[], void>({
            queryFn: async (arg, api, extra, baseQuery) => {
                const state = api.getState() as RootState;
                const { client, service } = state.auth;

                // fetch routes and routeStops to build places
                if (service === PassioService.navigator) {
                    const stopRequest = {
                        url: "/ondemand/tdb/get/",
                        method: "post",
                        body: {
                            type: "stop",
                            userId: client.agencyId,
                            filter: { archive: 0 },
                        },
                    };

                    const stops = await baseQuery(stopRequest);
                    if (stops.error) return { error: stops.error };

                    // extract stop ids and load routeStops
                    const stopIds = (stops.data as AuthTypes.PassioStop[])
                        .map((item) => item.id)
                        .sort();

                    const request = {
                        url: "/ondemand/tdb/get/",
                        method: "post",
                        body: {
                            type: "routeStop",
                            filter: {
                                stopId: stopIds,
                                archive: 0,
                                onDemandStop: 1,
                            },
                        },
                    };

                    const routeStops = await baseQuery(request);
                    if (routeStops.error) return { error: routeStops.error };

                    const places = (
                        routeStops.data as AuthTypes.PassioRouteStop[]
                    ).reduce((accumulator: Place[], routeStop) => {
                        const stop = (
                            stops.data as AuthTypes.PassioStop[]
                        ).find((stop) => stop.id === routeStop.stopId);

                        if (stop) {
                            const place = formatters.formatPlace(
                                stop,
                                routeStop
                            );

                            // prevent duplications
                            const exists = accumulator.find(
                                (item: Place) =>
                                    item.databaseId === place.databaseId
                            );

                            if (!exists) {
                                accumulator.push(place);
                            }
                        }

                        return accumulator;
                    }, []);

                    return { data: places };
                }

                // Paraplan onDemand places
                const { data, error } = await baseQuery(
                    "PlaceService/OnDemand"
                );

                if (error) return { error };
                else if (isParaplanError(data))
                    return { error: (data as any)?.errorMessage };

                return { data: (data as ListResponse<AuthTypes.Place>).list };
            },
        }),
        getAgencyConfig: build.query<
            AuthTypes.GetAgencyResponse,
            AuthTypes.GetAgencyRequest
        >({
            queryFn: async (
                { agency, url, service },
                api,
                extra,
                baseQuery
            ) => {
                const { passioToken } = (api.getState() as RootState).auth;
                if (service === PassioService.navigator) {
                    const request = {
                        url: `${navigatorAPIUrl}/tdb/get?accessToken=${passioToken}`,
                        method: "post",
                        body: {
                            type: "user",
                            id: agency,
                            field: formatters.passioUserFields,
                        },
                    };

                    const { data, error } = await baseQuery(request);
                    if (error) return { error };

                    const config = formatters.formatConfig(
                        data as AuthTypes.PassioUser
                    );
                    return { data: { config, service } };
                }
                const request = {
                    url: `${url}UserService/ConfigLite`, // request doesn't require a token so we build directly to prevent blocking
                    params: { Agency: agency },
                };
                const { data, error } = await baseQuery(request);
                if (error) return { error };
                else if (isParaplanError(data))
                    return { error: (data as any)?.errorMessage };

                const config = {
                    ...(data as EntityResponse<Config>).entity,
                    agencyId: agency,
                };

                return { data: { config, service } };
            },
        }),
        getUrlMapper: build.query<string, string>({
            query: (agency) => ({
                url: `${paraPlanApiUrl}/UserService/URLMapper`,
                params: { Agency: agency },
                validateStatus: validateParaplanStatus,
            }),
            transformResponse: (response: EntityResponse<string>) => {
                return response.entity;
            },
        }),
        getOndemandUrlMapper: build.query<
            AuthTypes.OnDemandURLMapper[],
            string[]
        >({
            queryFn: async (ids, api, extra, baseQuery) => {
                const { passioToken } = (api.getState() as RootState).auth;
                const request = {
                    url: `${navigatorAPIUrl}/tdb/get?accessToken=${passioToken}`,
                    method: "post",
                    body: {
                        type: "onDemandUrlMapper",
                        filter: { userId: ids },
                    },
                    validateStatus: validateNavigatorStatus,
                };

                const { data, error } = await baseQuery(request);
                if (error) return { error };
                return { data: data as AuthTypes.OnDemandURLMapper[] };
            },
        }),
        getUserSolution: build.query<AuthTypes.UserSolution[], string>({
            queryFn: async (userId, api, extra, baseQuery) => {
                const { passioToken } = (api.getState() as RootState).auth;

                const request = {
                    url: `${navigatorAPIUrl}/tdb/get?accessToken=${passioToken}`,
                    method: "post",
                    body: { type: "userSolution", userId },
                    validateStatus: validateNavigatorStatus,
                };

                const { data, error } = await baseQuery(request);
                if (error) return { error };
                return { data: data as AuthTypes.UserSolution[] };
            },
        }),

        /**
         * For Paraplan, pass id as new to to url and 0 in body to create a new client record
         * Used to create new riders by mobility managers
         */
        updateProfile: build.mutation<Client, any>({
            queryFn: async ({ id, ...account }, api, extra, baseQuery) => {
                const state = api.getState() as RootState;
                const { service, config, client } = state.auth;

                if (service === PassioService.navigator) {
                    const request = {
                        url: "/ondemand/tdb/update",
                        method: "post",
                        body: {
                            type: "onDemandUser",
                            userId: config.agencyId,
                            id,
                            ...account,
                        },
                    };

                    const { data, error } = await baseQuery(request);
                    if (error) return { error };
                    else if (isNavigatorError(data))
                        return {
                            error: createQueryError(
                                "Sorry we couldn't update your account"
                            ),
                        };

                    const riderRequest = {
                        url: "/ondemand/tdb/update",
                        method: "post",
                        body: {
                            type: "rider",
                            userId: config.agencyId,
                            id: client.id,
                            ...pick(account, ["programs", "defaultServiceId"]),
                        },
                    };

                    const riderResponse = await baseQuery(riderRequest);
                    if (riderResponse.error)
                        return { error: riderResponse.error };
                    else if (isNavigatorError(riderResponse.data))
                        return {
                            error: createQueryError(
                                "Sorry we couldn't update your account"
                            ),
                        };

                    const formatted = formatters.formatUserAndClient(
                        data as AuthTypes.OnDemandUser,
                        riderResponse.data as AuthTypes.PassioRider
                    );

                    return { data: formatted.client };
                }

                const request = {
                    url: `ClientService/Client/${id}`,
                    method: "post",
                    body: { ...account, id: id === "new" ? 0 : id },
                };
                const { data, error } = await baseQuery(request);
                if (error) return { error };
                else if (isParaplanError(data))
                    return {
                        error: createQueryError((data as any)?.errorMessage),
                    };
                return { data: data as Client };
            },
        }),
        changePassword: build.mutation<any, AuthTypes.changePasswordRequest>({
            queryFn: async (args, api, extra, baseQuery) => {
                const state = api.getState() as RootState;
                const { service, config } = state.auth;

                if (service === PassioService.navigator) {
                    const request = {
                        url: "/ondemand/tdb/update",
                        method: "post",
                        body: {
                            type: "onDemandUser",
                            userId: config.agencyId,
                            id: args.userId,
                            password: args.newPassword,
                        },
                        validateStatus: validateNavigatorStatus,
                    };

                    const { data, error } = await baseQuery(request);
                    if (error) return { error };
                    return { data };
                }

                const request = {
                    url: "UserService/ChangePassword",
                    method: "post",
                    body: args,
                    validateStatus: validateParaplanStatus,
                };

                const { data, error } = await baseQuery(request);
                if (error) return { error };
                return { data };
            },
        }),
        resetCache: build.mutation<any, AuthTypes.CacheResetRequest>({
            query: (data) => ({
                url: `ProgramService/ResetCache/`,
                params: {
                    Geozones: data.geozones,
                    Filespecs: data.filespecs,
                },
                validateStatus: validateParaplanStatus,
            }),
        }),
        signup: build.mutation<any, any>({
            queryFn: async ({ agency, account }, api, extra, baseQuery) => {
                const state = api.getState() as RootState;
                const { service, serviceUrl, passioToken } = state.auth;

                if (service === PassioService.navigator) {
                    // create onDemandUser and rider profile
                    const request = {
                        url: `${navigatorAPIUrl}/tdb/add?accessToken=${passioToken}`,
                        method: "post",
                        body: {
                            type: "onDemandUser",
                            userId: agency,
                            ...account,
                        },
                    };

                    const { data, error } = await baseQuery(request);
                    if (error) return { error };
                    else if (isNavigatorError(data))
                        return {
                            error: createQueryError(
                                "Sorry we couldn't create your account"
                            ),
                        };

                    const user = data as AuthTypes.OnDemandUser;

                    const riderRequest = {
                        url: `${navigatorAPIUrl}/tdb/add?accessToken=${passioToken}`,
                        method: "post",
                        body: {
                            type: "rider",
                            userId: agency,
                            agencyId: agency,
                            onDemandUserId: user.id,
                            ...pick(account, ["programs", "defaultServiceId"]),
                        },
                    };
                    const riderResponse = await baseQuery(riderRequest);
                    if (riderResponse.error)
                        return { error: riderResponse.error };
                    else if (isNavigatorError(riderResponse.data))
                        return {
                            error: createQueryError(
                                "Could not create rider profile"
                            ),
                        };
                    return { data: user };
                }

                const request = {
                    url: `${serviceUrl}UserService/Register`,
                    method: "post",
                    body: account,
                    params: {
                        Token: account.password,
                        Device: device,
                        UTCOffset: utcOffset,
                        Agency: agency,
                    },
                };
                const { data, error } = await baseQuery(request);
                if (error) return { error };
                else if (isParaplanError(data))
                    return { error: (data as any)?.errorMessage };
                return { data };
            },
        }),
        passioLogout: build.mutation<object, void>({
            query: () => ({
                url: "/ondemand/logout",
                method: "post",
            }),
        }),
        validateOldPassword: build.mutation<
            object,
            AuthTypes.ValidatePasswordRequest
        >({
            query: ({ id, password }) => ({
                url: "/ondemand/tdb/get",
                method: "post",
                body: {
                    type: "onDemandUser",
                    id,
                    filter: {
                        archive: 0,
                        password: { "=": password },
                    },
                    noType: true,
                    field: ["id"], // only return the id
                },
                validateStatus: (response, result) =>
                    response.status === 200 && result?.id,
            }),
        }),
    }),
    overrideExisting: false,
});

const authTags = ["config", "geozones"];
authAPI.enhanceEndpoints({
    addTagTypes: authTags,
    endpoints: {
        getConfig: { providesTags: ["config"] },
        getGeoZones: { providesTags: ["geozones"] },
        resetCache: { invalidatesTags: authTags },
    },
});

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        save: (state, { payload }) => {
            return { ...state, ...payload };
        },
        clear: () => initialState,
    },
    extraReducers: (builder) => {
        // ParaPlan login
        builder.addMatcher(
            authAPI.endpoints.login.matchFulfilled,
            (state, { payload }) => {
                // if login is called from the login modal we want to make sure current sources are not overriden
                const sources = payload.AvailableFundingSources?.length
                    ? [
                          ...state.availableFundingSources,
                          ...payload.AvailableFundingSources,
                      ]
                    : state.availableFundingSources;
                return {
                    ...state,
                    user: payload,
                    token: payload.Key,
                    tokenIsValid: payload.tokenIsValid,
                    serviceUrl: payload.RESTUrl,
                    availableFundingSources: sources,
                    isManager: payload.UserType === 1,
                    service: PassioService.paraplan,
                };
            }
        );
        // Navigator login
        builder.addMatcher(
            authAPI.endpoints.passioLogin.matchFulfilled,
            (state, { payload }) => {
                const token = payload.onDemandAccessToken[0].value;
                state.token = token;
                state.serviceUrl = navigatorAPIUrl;
                state.service = PassioService.navigator;
                state.tokenIsValid = true;
                state.tokenExists = true;

                // check for debug
                const debug = new URLSearchParams(window.location.search).get(
                    "debug"
                );
                state.debug = debug && parseInt(debug) === 1 ? true : false;
            }
        );
        // Get Passio Token
        builder.addMatcher(
            authAPI.endpoints.getPassioToken.matchFulfilled,
            (state, { payload }) => {
                const service = getService();
                state.passioToken = payload.accessToken;
                if (service === PassioService.navigator) {
                    state.service = service;
                    state.serviceUrl = navigatorAPIUrl;
                    // extract customer ids for loading URL mapper
                    state.customerIds = formatters.getCustomerIdsFromAccess(
                        payload.access
                    );
                }
            }
        );
        // OnDemandUser
        builder.addMatcher(
            authAPI.endpoints.getOnDemandUser.matchFulfilled,
            (state, { payload }) => {
                state.client = payload.client;
                state.user = payload.user;
            }
        );
        // Geozones
        builder.addMatcher(
            authAPI.endpoints.getGeoZones.matchFulfilled,
            (state, { payload }) => {
                state.geozones = payload;
            }
        );
        // Get client
        builder.addMatcher(
            authAPI.endpoints.getClient.matchFulfilled,
            (state, { payload }) => {
                state.client = payload;
                // filter available funding sources if client is a mobilitiy manager
                if (state.user.UserType === 2) {
                    state.availableFundingSources = filterFundingSources(
                        payload?.programs
                    );
                }
            }
        );
        // Get Clients
        builder.addMatcher(
            authAPI.endpoints.getClients.matchFulfilled,
            (state, { payload }) => {
                state.clients = payload.list;
            }
        );
        // Get Programs
        builder.addMatcher(
            authAPI.endpoints.getPrograms.matchFulfilled,
            (state, { payload }) => {
                state.programs = payload;
                // For navigator, extract funding sources from programs, used for profile update
                if (state.service === PassioService.navigator) {
                    state.availableFundingSources = payload.map(
                        (program) => program.programName
                    );
                }
            }
        );
        // Get Config
        builder.addMatcher(
            authAPI.endpoints.getConfig.matchFulfilled,
            (state, { payload }) => {
                state.config = payload;
            }
        );
        // Get Agency Config
        builder.addMatcher(
            authAPI.endpoints.getAgencyConfig.matchFulfilled,
            (state, { payload }) => {
                state.config = payload.config;
                state.service = payload.service;
            }
        );
        // Get Places
        builder.addMatcher(
            authAPI.endpoints.getPlaces.matchFulfilled,
            (state, { payload }) => {
                state.places = payload;
            }
        );
        // Get Url Mapper
        builder.addMatcher(
            authAPI.endpoints.getUrlMapper.matchFulfilled,
            (state, { payload }) => {
                state.serviceUrl = payload;
            }
        );
        // profile update
        builder.addMatcher(
            authAPI.endpoints.updateProfile.matchFulfilled,
            (state, { payload }) => {
                // update client profile in state only if not manager
                if (!state.isManager) {
                    state.client = payload;
                }
            }
        );
        // Get Passio Token
        builder.addMatcher(
            authAPI.endpoints.getUrlMapper.matchRejected,
            (state) => {
                state.passioToken = "";
            }
        );
    },
});

export const authActions = authSlice.actions;
// Other code such as selectors can use the imported `RootState` type
export const authSelector = (state: RootState) => state.auth;
export const userSelector = (state: RootState) => state.auth.user;

export const navigatorSelector = createSelector(
    (state: RootState) => state.auth.service,
    (service) => service === PassioService.navigator
);

export const logout = createAction("LOG_OUT");

export default authSlice.reducer;
