import { useEffect, useState, useCallback } from "react";
import { Box, Button, Container, Typography, ButtonGroup } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Formik, Form, FormikValues, FormikHelpers } from "formik";
import { enqueueSnackbar } from "notistack";
import { useNavigate, useBeforeUnload } from "react-router-dom";
import { FormInput, Logo, Footer, Splashscreen } from "src/components";
import { getService, isErrorWithMessage } from "src/utils/helpers";
import { recoverySchema, codeSchema, passwordResetSchema } from "../schema";
import { useTitle } from "react-use";
import { PassioService } from "src/types";
import { authAPI, authSelector, logout } from "../redux/authSlice";
import { useAppSelector, useAppDispatch } from "src/hooks";
import { tripsAPI } from "src/modules/trips/redux/tripSlice";
import { generateRandomSixDigitNumber } from "../helpers";
import { formatPasswordRecoveryEmail } from "src/utils/email";

/**
 * @param agency: agency name
 * @param service paraplan | navigator
 * @example /login?agency=ENGRAPHTRANSIT&service=paraplan
 */

type Step = "email" | "verify" | "reset";
interface Cache {
    id: string;
    step: Step;
    email: string;
    code: number;
    resend: boolean;
}

const PasswordReset = () => {
    useTitle("Password Recovery");
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const service = getService();
    const isNavigator = service === PassioService.navigator;
    const { passioToken, customerIds } = useAppSelector(authSelector);

    const [cache, setCache] = useState<Cache>({
        id: "",
        email: "",
        code: 0,
        step: "email",
        resend: false,
    });

    const { isLoading: fetchingToken } = authAPI.useGetPassioTokenQuery(
        undefined,
        {
            skip: !isNavigator || passioToken ? true : false,
        }
    );
    const [sendEmail, { isLoading: isSendingEmail }] =
        tripsAPI.useSendEmailMutation();
    const [tdb, { isLoading }] = authAPI.useTdbMutation();

    useEffect(() => {
        if (!isNavigator) navigate("/login");
    }, [isNavigator, navigate]);

    // always clear cache when the user leaves this page
    useBeforeUnload(
        useCallback(() => {
            dispatch(logout());
        }, [dispatch])
    );

    async function sendCode(email = "") {
        try {
            email = email || cache.email;
            const code = generateRandomSixDigitNumber();
            const message = formatPasswordRecoveryEmail(email, code);
            await sendEmail(message).unwrap();
            setCache((cache) => ({ ...cache, code }));
            enqueueSnackbar(
                `A verification code has been sent to ${email}. Please enter it to verify your account`,
                { variant: "success" }
            );
        } catch (error) {
            enqueueSnackbar(
                "Sorry we couldn't send your verification code. Please try again",
                { variant: "error" }
            );
        }
    }

    async function handleEmail(
        { email }: FormikValues,
        helpers: FormikHelpers<any>
    ) {
        try {
            // verify email
            const response = await tdb({
                path: "get",
                token: passioToken,
                body: {
                    type: "onDemandUser",
                    filter: { email, userId: customerIds },
                    limit: 1,
                },
            }).unwrap();

            if (!response[0] || response[0]?.email !== email) {
                const message =
                    "Sorry we couldn't find your account. Please contact support";
                helpers.setFieldError("email", message);
                throw new Error(message);
            }
            await sendCode(email);
            setCache((cache) => ({
                ...cache,
                email,
                id: response[0].id,
                step: "verify",
            }));
        } catch (error) {
            const message = isErrorWithMessage(error)
                ? error.message
                : "Sorry an error occured please try again";
            enqueueSnackbar(message, { variant: "error" });
        }
    }

    function verifyCode({ code }: FormikValues, helpers: FormikHelpers<any>) {
        if (Number(code) !== cache.code) {
            const message =
                "Sorry the code entered is invalid. Please try again";
            enqueueSnackbar(message, { variant: "error" });
            helpers.setFieldError("code", message);
            setCache((cache) => ({ ...cache, resend: true }));
            return;
        }
        setCache((cache) => ({ ...cache, resend: false, step: "reset" }));
        enqueueSnackbar("Account verified successfully", {
            variant: "success",
        });
    }

    async function handleReset({ password }: FormikValues) {
        try {
            await tdb({
                path: "update",
                token: passioToken,
                body: {
                    type: "onDemandUser",
                    id: cache.id,
                    password,
                },
            }).unwrap();
            enqueueSnackbar("Password updated successfully. Please login", {
                variant: "success",
            });
            navigate(`/login?service=${PassioService.navigator}`);
        } catch {
            enqueueSnackbar("Sorry an error occured please try again", {
                variant: "error",
            });
        }
    }

    const loading = isLoading || fetchingToken || isSendingEmail;

    if (fetchingToken) {
        <Splashscreen description="Processing ..." />;
    }

    if (cache.step === "verify") {
        return (
            <Container
                component="main"
                maxWidth="xs"
                sx={{ display: "grid", placeItems: "center", height: "100vh" }}
            >
                <Box
                    sx={{
                        marginTop: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Logo />

                    <Typography component="h2" variant="h5" my={1}>
                        Account Verification
                    </Typography>

                    <Typography my={3} textAlign="center">
                        Please check your email for a verification code and
                        enter it below
                    </Typography>

                    <Formik
                        initialValues={{ code: "" }}
                        validationSchema={codeSchema}
                        onSubmit={verifyCode}
                    >
                        <Box component={Form} sx={{ mt: 4 }}>
                            <FormInput
                                fullWidth
                                name="code"
                                label="Verification code"
                                margin="normal"
                                sx={{ mb: 2 }}
                            />

                            <Button
                                size="large"
                                type="submit"
                                fullWidth
                                variant="contained"
                                disabled={loading}
                                sx={{
                                    my: 2,
                                    py: 1,
                                    color: "primary.contrastText",
                                }}
                            >
                                verify
                            </Button>

                            <ButtonGroup variant="text" fullWidth>
                                <Button
                                    type="button"
                                    onClick={() => {
                                        setCache((cache) => ({
                                            ...cache,
                                            step: "email",
                                        }));
                                    }}
                                >
                                    Cancel
                                </Button>
                                {cache.resend && (
                                    <LoadingButton
                                        type="button"
                                        disabled={loading}
                                        loading={isSendingEmail}
                                        onClick={() => sendCode()}
                                    >
                                        Resend Code
                                    </LoadingButton>
                                )}
                            </ButtonGroup>
                        </Box>
                    </Formik>
                </Box>
                <Footer />
            </Container>
        );
    }

    if (cache.step === "reset") {
        return (
            <Container
                component="main"
                maxWidth="xs"
                sx={{ display: "grid", placeItems: "center", height: "100vh" }}
            >
                <Box
                    sx={{
                        marginTop: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Logo />

                    <Typography component="h2" variant="h5" my={1}>
                        Password Reset
                    </Typography>

                    <Typography my={3} textAlign="center">
                        Please enter your new account password
                    </Typography>

                    <Formik
                        initialValues={{ password: "", confirmPassword: "" }}
                        validationSchema={passwordResetSchema}
                        onSubmit={handleReset}
                    >
                        <Box component={Form} sx={{ mt: 4 }}>
                            <FormInput
                                fullWidth
                                name="password"
                                label="New Password"
                                type="password"
                                margin="normal"
                            />
                            <FormInput
                                fullWidth
                                name="confirmPassword"
                                label="Confirm Password"
                                type="password"
                                margin="normal"
                                sx={{ mb: 2 }}
                            />

                            <LoadingButton
                                size="large"
                                type="submit"
                                fullWidth
                                variant="contained"
                                loading={loading}
                                disabled={loading}
                                sx={{
                                    my: 2,
                                    py: 1,
                                    color: "primary.contrastText",
                                }}
                            >
                                Continue
                            </LoadingButton>

                            <Button
                                type="button"
                                fullWidth
                                onClick={() => {
                                    setCache((cache) => ({
                                        ...cache,
                                        step: "email",
                                    }));
                                }}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Formik>
                </Box>
                <Footer />
            </Container>
        );
    }

    return (
        <Container
            component="main"
            maxWidth="xs"
            sx={{ display: "grid", placeItems: "center", height: "100vh" }}
        >
            <Box
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Logo />
                <Typography component="h2" variant="h5">
                    Password Recovery
                </Typography>

                <Formik
                    initialValues={{ email: "" }}
                    validationSchema={recoverySchema}
                    onSubmit={handleEmail}
                >
                    <Box component={Form} sx={{ mt: 4 }}>
                        <FormInput
                            fullWidth
                            name="email"
                            label="Email address"
                            margin="normal"
                            inputProps={{ autoComplete: "email" }}
                            sx={{ mb: 2 }}
                        />

                        <LoadingButton
                            size="large"
                            type="submit"
                            fullWidth
                            variant="contained"
                            disabled={loading}
                            loading={loading}
                            sx={{ my: 2, py: 1, color: "primary.contrastText" }}
                        >
                            {loading ? "Verifying" : "Continue"}
                        </LoadingButton>

                        <Button
                            variant="text"
                            type="button"
                            fullWidth
                            href={`/login?service=${PassioService.navigator}`}
                            sx={{ mb: 2 }}
                        >
                            Cancel
                        </Button>
                    </Box>
                </Formik>
            </Box>
            <Footer />
        </Container>
    );
};

export default PasswordReset;
