// Helpers for normalizing Navigator responses and requests to match Paraplan

import * as CryptoJS from "crypto-js";
import type {
    Config,
    PassioUser,
    PassioRider,
    Credentials,
    PassioRouteStop,
    PassioStop,
    Place,
    Service,
    Program,
    ServiceArea,
    OnDemandUser,
    FormattedUserAndClient,
    RiderProgram,
    Access,
} from "./types";
import {
    defaultClient,
    defaultConfig,
    defaultUser,
    defaultPlace,
    defaultProgram,
    defaultParsedAddress,
    defaultAccessibility,
} from "src/utils/defaultData";
import { omit, pick } from "lodash";
import { stringToJSON } from "src/utils/helpers";
import { AutoScheduleFailAction, PassioService } from "src/types";
import moment from "moment";

function hashPasswordByService(password: string, service: PassioService) {
    let hashedPass = "";
    if (service === PassioService.navigator) {
        const key = CryptoJS.lib.WordArray.random(128 / 8); // previously "djdance";
        let hash = CryptoJS.HmacSHA256(password, key);
        hashedPass = hash.toString(CryptoJS.enc.Hex).toUpperCase();
    } else {
        let hash = CryptoJS.SHA512(password);
        hashedPass = hash.toString(CryptoJS.enc.Hex).toUpperCase();
    }

    return hashedPass;
}

export function generateLoginQuery(
    creds: Credentials,
    service = PassioService.paraplan,
    resume = false
) {
    /* when resuming, don't rehash credentials for Paraplan,
     * navigator doesn't need hashed credentials.
     */
    if (service === PassioService.navigator || resume) {
        return creds;
    }

    return {
        email: encodeURIComponent(creds.email),
        password: hashPasswordByService(creds.password, service),
    };
}

export function formatPlace(
    stop: PassioStop,
    routeStop: PassioRouteStop
): Place {
    if (!stop || !routeStop) return defaultPlace;

    const formattedStop = {
        ...defaultPlace,
        name: stop.name,
        databaseId: stop.id,
        isPickUpSpot: routeStop.pickupType === 1 ? false : true,
        isDropOffSpot: routeStop.dropOffType === 1 ? false : true,
        lat: parseFloat(stop.latitude),
        lng: parseFloat(stop.longitude),
    };

    return formattedStop;
}

/*
 * Parse client detail from navigator response received as stringified object
 * @see PassioClient interface
 */

const omitRiderFields = [
    "userId",
    "address",
    "membershipId",
    "created",
    "updated",
    "archive",
    "type",
];

export const passioUserFields = [
    "id",
    "admin",
    "email",
    "fullname",
    "username",
    "address",
    "admin",
    "customer",
    "manager",
    "onDemandSettings",
    "DispatcherEmail",
];

export function formatRiderPrograms(
    riderPrograms: RiderProgram[] = [],
    programs: Program[] = [], // formatted services
    defaultServiceId = ""
): Program[] {
    if (!riderPrograms.length) return [];

    const formattedPrograms = riderPrograms.reduce((acc: Program[], item) => {
        const baseProgram = programs.find(
            (program) => program.databaseID === item.serviceId
        );

        if (baseProgram) {
            acc.push({
                ...baseProgram,
                isDefault: baseProgram.databaseID === defaultServiceId,
                membershipID: item.membershipId,
            });
        }

        return acc;
    }, []);

    return formattedPrograms;
}

export function formatUserAndClient(
    onDemandUser: OnDemandUser,
    rider: PassioRider,
    programs: Program[] = []
): FormattedUserAndClient {
    if (!rider || !onDemandUser)
        return { user: defaultUser, client: defaultClient };

    const riderPrograms = rider.programs
        ? formatRiderPrograms(
              stringToJSON(rider.programs),
              programs,
              rider.defaultServiceId || ""
          )
        : [];

    const defaultProgramName =
        riderPrograms.find((program) => program.isDefault)?.programName || "";

    // imported riders may not have an address or accessibility
    const address = stringToJSON(onDemandUser.address) || defaultParsedAddress;
    const accessibility =
        stringToJSON(onDemandUser.accessibility) || defaultAccessibility;

    const client = {
        ...defaultClient,
        ...omit(rider, omitRiderFields),
        ...pick(onDemandUser, ["birthdate", "phone", "email", "name"]),
        id: parseInt(onDemandUser.id),
        riderId: parseInt(rider.id),
        birthDateEpoch: moment(onDemandUser.birthdate).unix(),
        accessibility,
        status: rider.status && stringToJSON(rider.status),
        defaultProgramMembershipID: rider.membershipId || "",
        address,
        defaultServiceId: rider.defaultServiceId || "",
        defaultProgramName,
        programs: riderPrograms,
        puExtraDuration: rider.puExtraDuration
            ? parseInt(rider.puExtraDuration)
            : 0,
        doExtraDuration: rider.doExtraDuration
            ? parseInt(rider.doExtraDuration)
            : 0,
    };

    const user = {
        ...defaultUser,
        Email: onDemandUser.email,
        ClientID: parseInt(onDemandUser.id),
        PassioUserId: rider.agencyId,
        UserId: parseInt(onDemandUser.id),
        Name: onDemandUser.name,
        Key: onDemandUser.accessToken,
    };

    return { user, client };
}

export function formatConfig(data: PassioUser): Config {
    if (!data) return defaultConfig;
    const config = JSON.parse(data.onDemandSettings);
    const response = {
        ...defaultConfig,
        ...config,
        AgencyName: data.fullname,
        PassioUserId: data.id,
        PassioAgency: data.username,
        PassioUsername: data.username,
        agencyId: data.id,
    };

    return response;
}

function formatGeozoneIds(data: ServiceArea[]): string[] {
    if (!data) return [];

    const geozoneIds = data.reduce((accumulator: string[], area) => {
        accumulator.push(area.geofenceId);
        return accumulator;
    }, []);

    return geozoneIds;
}

export function formatPrograms(data: Service[]): Program[] {
    if (!data || !data.length) return [];

    const programs = data.reduce((accumulator: Program[], service) => {
        const program = {
            ...defaultProgram,
            databaseID: service.id,
            programName: service.name,
            pickUpLOS: service.pickUpLosId,
            dropOffLOS: service.dropOffLosId,
            activeGeozoneIds: formatGeozoneIds(service.serviceArea),
            serviceCalendarId: service.serviceCalendarId,
            puExtraDuration: service.puExtraDuration
                ? parseInt(service.puExtraDuration)
                : 0,
            doExtraDuration: service.doExtraDuration
                ? parseInt(service.doExtraDuration)
                : 0,
            autoScheduleFailAction: service.autoscheduleFailAction
                ? parseInt(service.autoscheduleFailAction)
                : AutoScheduleFailAction.NoAction,
        };

        accumulator.push(program);
        return accumulator;
    }, []);

    return programs;
}

export function getCustomerIdsFromAccess(access: Access[] = []) {
    const ids = access.reduce((acc: string[], { accountId }) => {
        if (accountId && !acc.includes(accountId)) {
            acc.push(accountId);
        }
        return acc;
    }, []);
    return ids;
}

export const generateRandomSixDigitNumber = () =>
    Math.floor(100000 + Math.random() * 900000);
