// Types for trip module
import { ResponseStatus } from "src/types";
import { Client, Place, Geozone } from "../auth/types";
import { TripRequest } from "./pages/TripRequest/types";

export enum TripReservationType {
    Trip = "T",
    Reservation = "R",
    Request = "Q",
}

export enum StatusTypes {
    Scheduled = "Scheduled",
    Accepted = "Accepted",
    Rejected = "Rejected",
    Requested = "Requested",
    Cancelled = "Cancelled",
    Pending = "Pending",
    Completed = "Completed",
    InProgress = "In Progress",
}

export interface GeoEligibilityCheckRequest {
    passioAgency: string;
    passioToken: string;
    latitude: number;
    longitude: number;
}

export interface PassioGeofence {
    id: string;
    name: string;
    shape: Shape;
    color: string;
}

export interface Shape {
    type: "polygon" | "circle";
    coordinates: Array<Array<number[]>>;
    center?: number[];
    radius?: number;
}

export interface GeofenceRequest {
    passioAgency: string;
    passioToken: string;
    id: number;
}

export interface CancelTripOfReservationRequest {
    cancellationReason: string;
    reservationId: string;
    dateToCancel: string;
}

export interface CancelReservationRequest {
    cancellationReason: string;
    reservationId: string;
}

export interface RemoveTripRequest {
    id: string;
    reason: string;
}

export interface CancelTripRequest extends RemoveTripRequest {
    lat?: number;
    lng?: number;
}

export interface RideStatus {
    clientId: string | number;
    geo: Geo;
    request: RideRequest;
    rideStatus: number;
    riderIsOnBoard: boolean;
    times: Times;
    tripId: string;
}

export interface Geo {
    dropOff: PassioLocation;
    pickUp: PassioLocation;
    vehicle: PassioLocation;
    rider: PassioLocation;
}

export interface PassioLocation {
    city: string;
    databaseId: string;
    lat: number;
    lng: number;
    name: string;
    state: string;
    zip: string;
    address1?: string;
}

export interface RideRequest extends TripRequest {
    ParaPlanRideID: string;
    apptTimeEpoch: number;
    carSeatDescription: string;
    clientBirthdateEpoch: number;
    clientEmail: string;
    clientGender: string;
    clientHomeAddress: string;
    clientHomeAddress2: string;
    clientHomeCity: string;
    clientHomeState: string;
    clientHomeZipCode: string;
    dropOffTimeEpoch: number;
    fleetmanagerID: any | null;
    levelOfService: string;
    negotiatedDropOffTimeEpoch: number;
    negotiatedPickUpTimeEpoch: number;
    returnTimeEpoch: number;
    returnType: number;
    tripComments: string;
    tripCost: number;
    tripMiles: number;
    tripStatus: string;
}

export interface Times {
    scheduledDropOffTimeEpoch: string;
    scheduledPickUpTimeEpoch: string;
    actualPickUpArrivalEpoch: string;
    estimatedPickUpArrivalEpoch: string;
    actualDropOffTimePerformEpoch: string;
    estimatedDropOffArrivalEpoch: string;
    estimatedDropOffTimePerformEpoch: string;
}

export interface TripHistory extends ResponseStatus {
    actualDropOffTime: string | any;
    actualPickUpTime: string | any;
    appointmentTime: string | any;
    client: Client | null;
    databaseId: string | any;
    dropOffPlace: Place;
    dropOffTime: string | any;
    nextTripDate: string | any;
    pickUpPlace: Place;
    pickUpTime: string | any;
    recurrenceInfo: any;
    reservationType: TripReservationType;
    returnTripInfo: string | any;
    secondLine: string;
    tripDate: string | any;
    tripStatus: string | any;
    onDemandTripActionHistoryId: string | number; // only available for navigator
    onDemandActionId: number; // only available for navigator
    tripUuid: string; // only available for navigator
    runId?: number; // available if fleetManager is assigned for navigator
    tripRating: number;
}

export interface OnDemandTripRequest {
    tripSource: string;
    timeType: string;
    requesterEmail: string;
    tripURL: string;
    tripUuid: string;
    requestTime: string;
    pickUpTime: string;
    dropOffTime: string;
    pickUpPlace: string;
    dropOffPlace: string;
    accessibility: string;
    comment: string;
    serviceId: string;
    totalRiders: number | string;
    returnTime: string | null;
    unloadedTime: string;
    nextOnDemandTripId: string | null;
}

export interface OnDemandTrip extends OnDemandTripRequest {
    type: string;
    id: string;
    userId: string;
    riderId: string;
    runId: string | null;
    archive: number;
    canceled: number;
    cancelReason: string | null;
    cancelDate: string | null;
    noShow: number;
    noShowDate: string | null;
    travelDistance: string | null;
    travelDuration: string | null;
    billableDistance: string | null;
    billableDuration: string | null;
    fare: string | null;
    farePaid: string | null;
    created: Date;
    createdUtc: Date;
    updated: Date;
    updatedUtc: Date;
    timeType: string;
    negotiatedTime: string | null;
    privateComment: string | null;
    totalRiders: string;
    pickUpWindowStart: string | null;
    pickUpWindowEnd: string | null;
    dropOffWindowStart: string | null;
    dropOffWindowEnd: string | null;
    onDemandTripActionHistory: OnDemandTripActionHistory[];
    actualPickUpTime: string;
    actualDropOffTime: string;
    pickUpOdometer: string;
    dropOffOdometer: string;
    driverId: string;
    tripRating: string;
    riderRating: string;
}

export interface OnDemandTripActionHistory {
    type: string;
    id: string;
    onDemandTripId: string;
    userId: string;
    runId: string | null;
    riderId: string;
    driverId: string | null;
    dispatcherId: string | null;
    onDemandActionId: string;
    created: Date;
    createdUtc: Date;
    updated: Date;
    updatedUtc: Date;
    datetime: string | null;
    datetimeUtc: string | null;
    latitude: string;
    longitude: string;
}

export interface PassioRun {
    type: string;
    id: string;
    userId: string;
    routeId: string;
    driverId: string;
    busId: string;
    archive: number;
    created: string | null;
    updated: string | null;
    startDateTime: string | null;
    endDateTime: string | null;
    startingPlace: string | null;
    endingPlace: string | null;
    startOdometer: string | null;
    endOdometer: string | null;
    outOfService: number;
}

export interface PassioBus {
    type: string;
    id: string;
    name: string;
    userId: string;
    seatedCap: number;
    totalCap: number;
    snap: string;
    color: string | null;
    mapApp: number;
    emulatorRouteId: string | null;
    imdId: string | null;
    archive: number;
    vehicleType: string | null;
    vehicleLength: string | null;
    yearOfManufacture: string | null;
    adaAccessible: number;
    ownership: string | null;
    fundingSource: string | null;
    yearOfRebuild: string | null;
    manufacturer: string;
    model: string | null;
    odometerReading: string;
    doorCount: string | null;
    available: number;
    busStatusId: string;
    vin: string | null;
    snapOnEventCode103: string;
    driverId: string;
    routeBlockId: string;
    stopId: string | null;
    routeStopId: string;
    tripId: string;
    timePointId: string;
    paxLoad: string;
    outOfService: number;
    deviceJobId: string | null;
    paraPlanId: string | null;
    zonarId: string | null;
    image: string | null;
    blockId: string;
    paddleId: string | null;
    externalGpsId: string | null;
    externalGpsProviderId: string | null;
    externalVehicle: string | null;
    setupVehicle: number;
    routeStopIdUpdated: string;
    vehicleTypeId: string | null;
    externalGpsConsumerId: string | null;
    externalGpsConsumerVehicleId: string | null;
    externalGpsConsumerVehicle: string | null;
    externalGpsConsumerOdometerUnit: string | null;
    lastTimePointId: string;
    lastTimePointIdUpdated: string;
    departureDatetime: string | null;
    outOfServiceUpdated: string;
    outOfServiceSourceId: string;
    driverIdUpdated: string;
    driverIdSourceId: string;
    routeBlockIdUpdated: string;
    routeBlockIdSourceId: string;
    routeStopIdSourceId: string;
    tripIdUpdated: string;
    tripIdSourceId: string;
    timePointIdUpdated: string;
    timePointIdSourceId: string;
    deviceJobIdUpdated: string;
    deviceJobIdSourceId: string;
    blockIdUpdated: string;
    blockIdSourceId: string;
    paddleIdUpdated: string;
    paddleIdSourceId: string;
    adaSeatCount: string | null;
    latitude: string;
    longitude: string;
    speed: string;
    course: string;
    heading: string | null;
    correctedLatitude: string;
    correctedLongitude: string;
    calculatedCourse: string;
    eventCode: string;
    postLocation: string;
    emulatorEventCode103: number;
    note: string | null;
    onlineStatusId: string;
    onlineStatusIdUpdated: string;
    flOnlineStatusId: string;
    flOnlineStatusIdUpdated: string;
    hideOnFleetLogistics: number;
    gtfsOccupancyStatus: string | null;
    busAsset: BusAsset[];
    busDocument: any[];
    busExternalProvider: any[];
    busLicense: any[];
    busOnlineStatusLog: any[];
    busSolution: BusSolution[];
    eta: any[];
}

export interface BusAsset {
    type: string;
    id: string;
    busId: string;
    name: string;
    description: string;
    serial: string | null;
    archive: number;
    archiveUpdated: string | null;
}

export interface BusSolution {
    type: string;
    id: string;
    busId: string;
    userSolutionId: string;
}

export interface Fleetmanager extends ResponseStatus {
    assignedTrips: string[];
    driverID: number;
    driverName: string;
    driverPhone: string;
    endTime: string;
    endTimeJson: string;
    endTimeUTC: string;
    fleetmanagerID: number;
    gps: GPS | null;
    nameWithTP: string;
    odometerEnd: number;
    odometerStart: number;
    preferredZone: Geozone;
    routeId: number;
    routeName: string;
    savedToHistory: boolean;
    startTime: string;
    startTimeJson: string;
    startTimeUTC: string;
    stopsExecuted: number;
    stopsNoShow: number;
    stopsPending: number;
    stopsTotal: number;
    vehicleID: number;
    vehicleMake: string;
    vehicleModel: string;
    vehicleName: string;
    vehicleSeats: number;
    vehicleWCSeats: number;
    vehicleYear: string;
}

export interface FormattedGPS {
    speed: number;
    busId: string | number;
    driver: string;
    vehicle: string;
    route: string;
    updated: string;
    lat: number;
    lng: number;
}

export interface GPS extends ResponseStatus {
    dateStamp: string;
    lat: number;
    lng: number;
    radioID: string;
    speed: number;
}

export interface Vehicle extends ResponseStatus {
    comment: string;
    currentDriverFirstName: string | null;
    currentDriverId: number;
    currentDriverLastName: string | null;
    customID: number;
    databaseID: number;
    defaultDriverFirstName: string;
    defaultDriverId: number;
    defaultDriverLastName: string;
    fixedAmbulatorySeats: number;
    fixedWheelchairSpots: number;
    inUse: boolean;
    licenseExpirationDate: string;
    licensePlateNumber: string;
    locationEnd: Place;
    locationStart: Place;
    make: string;
    model: string;
    ratio: number;
    vin: string;
    year: string;
    name?: string;
    seatedCap?: number;
    totalCap?: number;
}

export interface PassioDriver {
    type: string;
    id: string;
    name: string;
    userId: string;
    email: string | null;
    hideOnDevice: number;
    archive: number;
    pin: string | null;
    testPassed: number;
    publicName: string | null;
    luminatorCode: string | null;
    available: number;
    phone: string | null;
    paraPlanId: string | null;
    firstName: string | null;
    lastName: string | null;
    externalId: string | null;
    training: number;
    allowOverlap: string | null;
    image: string;
    fareboxCode: string | null;
    imageLength: string | null;
    transitCheckUser: string | null;
    transitCheckPin: string | null;
    onDemandIsPaused: number;
    driverLicense: any[];
    onDemandDriverPauseLog: any[];
}

export type TripID = string | number;

export interface ScheduleTripRequest {
    tripId: TripID;
    clientId: TripID;
    fleetManagerId: number;
}

export interface GetDriverAndVehicleDetailsRequest {
    busId: string;
    driverId: string;
}

export interface GetDriverAndVehicleDetailsResponse {
    driverName: string;
    vehicle: string;
}

export interface PassioBusLocation {
    busId: number;
    deviceId: number;
    created: string;
    latitude: number;
    longitude: number;
    paxLoad: number;
    speed: number;
    routeBlock: string;
    more: any;
    userId: number;
    datetime: string;
    createdUtc: string;
    datetimeUtc: string;
    eventCode: string | null;
    driverId: number;
    routeBlockId: number;
    stopId: string | null;
    routeStopId: string | null;
    tripId: number;
    timePointId: string | null;
    outOfService: number;
    device: string;
    bus: string;
    driver: string;
    route: string;
    invalid: number;
    calculatedCourse: number;
    correctedLatitude: number;
    correctedLongitude: number;
    id: number;
    type: string;
    action: string;
}

export interface Device {
    type: string;
    id: string;
    busId: string;
    deviceSystemId: string;
    name: string;
}

export interface MDT {
    type: string;
    id: string;
    name: string;
    rooted: number | null;
    mdt: number;
    router: number;
    gps: number;
    apc: number;
    icon: string;
    fl: number;
}

export interface SMSRequest {
    to: string;
    from?: string;
    message: string;
}
