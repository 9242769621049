import * as Yup from "yup";
import { matchIsValidTel } from "mui-tel-input";

export const loginSchema = Yup.object({
    email: Yup.string().email().required("Please enter email address"),
    password: Yup.string().required("Please enter password or PIN"),
    autoLogin: Yup.bool(),
});

export const signupSchema = Yup.object().shape({
    name: Yup.string(),
    firstName: Yup.string().required("Please enter first name"),
    lastName: Yup.string().required("Please enter last name"),
    phone: Yup.string()
        .required("Please enter phone number")
        .test({
            name: "validPhone",
            message: "Please enter a valid phone number",
            test: (value) => matchIsValidTel(value),
        }),
    email: Yup.string().email().required("Please enter email"),
    accessibility: Yup.object().shape({
        hasPCA: Yup.bool(),
        usesWheelchair: Yup.bool(),
        wheelchairType: Yup.string(),
    }),
    password: Yup.string()
        .min(8, "Password must be at least 8 characters long")
        .required("Please enter your new password"),
    confirmPassword: Yup.string()
        .min(8, "Password must be at least 8 characters long")
        .required("Please confirm your password")
        .oneOf([Yup.ref("password")], "Passwords do not match"),
});

const addressSchema = Yup.object().shape({
    address: Yup.object().shape({
        address1: Yup.string().required("Please enter home address"),
        city: Yup.string().required("Please enter home city"),
        state: Yup.string().required("Please enter state"),
    }),
});

const birthDateSchema = Yup.object().shape({
    birthdate: Yup.date()
        .required("Please enter date of birth")
        .typeError("Please enter date of birth"),
});

export const recoverySchema = Yup.object().shape({
    email: Yup.string().email().required("Please enter email"),
});

export const codeSchema = Yup.object().shape({
    code: Yup.string()
        .required("Please enter code")
        .matches(/^[0-9]+$/, "Code must be 6 digits long")
        .min(6, "Code must be 6 digits long")
        .max(6, "Code must be 6 digits long"),
});

export const passwordResetSchema = Yup.object().shape({
    password: Yup.string()
        .min(8, "Password must be at least 8 characters long")
        .required("Please enter your new password"),
    confirmPassword: Yup.string()
        .min(8, "Password must be at least 8 characters long")
        .required("Please confirm your password")
        .oneOf([Yup.ref("password")], "Passwords do not match"),
});

export function getSchema(isNavigator = false) {
    return isNavigator
        ? signupSchema
        : signupSchema.concat(addressSchema).concat(birthDateSchema);
}
