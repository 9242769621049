import { Fragment } from "react";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { ButtonGroup, IconButton, Tooltip } from "@mui/material";
import {
    HistoryOutlined,
    AccountCircleOutlined,
    DepartureBoardOutlined,
} from "@mui/icons-material";
import type { Client } from "src/modules/auth/types";
import { Link } from "react-router-dom";

const TableActions = ({ row }: GridRenderCellParams<Client>) => {
    return (
        <Fragment>
            <ButtonGroup>
                <Tooltip title="View details">
                    <IconButton
                        component={Link}
                        to={`/riders/${row.id}`}
                        aria-label={`See details for ${row.name}`}
                    >
                        <AccountCircleOutlined />
                    </IconButton>
                </Tooltip>
                <Tooltip title="View history">
                    <IconButton
                        component={Link}
                        to={`/riders/${row.id}/history`}
                        aria-label={`See history for ${row.name}`}
                    >
                        <HistoryOutlined />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Book a ride">
                    <IconButton
                        component={Link}
                        to={`/riders/${row.id}/request`}
                        aria-label={`Book a ride for ${row.name}`}
                    >
                        <DepartureBoardOutlined />
                    </IconButton>
                </Tooltip>
            </ButtonGroup>
        </Fragment>
    );
};

export default TableActions;
