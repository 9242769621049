import { useCallback, useEffect, useMemo } from "react";
import {
    Container,
    Typography,
    Stack,
    FormControl,
    FormLabel,
    Card,
    Box,
} from "@mui/material";
import { PersonAdd } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Formik, Form } from "formik";
import { Link, useNavigate, useBeforeUnload } from "react-router-dom";
import {
    FormInput,
    FormCheckbox,
    FormDatePicker,
    PhoneNumberInput,
    PlacesAutocomplete,
    GoogleMap,
    Error,
    Splashscreen,
} from "src/components";
import { useTitle } from "react-use";
import { authAPI, authSelector, logout } from "../../redux/authSlice";
import { enqueueSnackbar } from "notistack";
import { getSchema } from "../../schema";
import {
    alertIfMissingFields,
    isFetchBaseQueryError,
    isValidCoord,
} from "src/utils/helpers";
import { useAppSelector } from "src/hooks";
import {
    getInitialValues,
    formatNavigatorAccount,
    formatParaplanAccount,
    missingProgramsError,
} from "./helpers";
import { PassioService } from "src/types";
import { useDispatch } from "react-redux";
import { SignupFormData } from "./types";
import { tripsAPI } from "src/modules/trips/redux/tripSlice";
import { formatNewAccountEmail } from "src/utils/email";
import { formatNewAccountSMS } from "src/utils/sms";

const Signup = () => {
    useTitle("Connect Signup");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [signup, { isLoading }] = authAPI.useSignupMutation();
    const [sendEmail, { isLoading: isSendingEmail }] =
        tripsAPI.useSendEmailMutation();
    const [sendSMS, { isLoading: isSendingSMS }] =
        tripsAPI.useSendSMSMutation();

    const { serviceUrl, config, service, passioToken } =
        useAppSelector(authSelector);
    const isNavigator = service === PassioService.navigator;
    const agency = config?.agencyId ?? "";
    const defaultServiceId = config?.ConnectPreferences?.DefaultProgramId;
    const schema = getSchema(isNavigator);
    const initialValues = getInitialValues(isNavigator);

    const { data: programs = [], isLoading: loadingPrograms } =
        authAPI.useGetProgramsQuery(
            { id: agency, token: passioToken },
            {
                skip: !agency || !isNavigator || !passioToken,
                refetchOnFocus: true,
                refetchOnMountOrArgChange: true,
                refetchOnReconnect: true,
            }
        );

    const availablePrograms = useMemo(() => {
        return programs.filter((program) => !program.membershipIsRequired);
    }, [programs]);

    function handleRedirect() {
        dispatch(logout());
        // reset location state to prevent auto redirects after login
        navigate(
            {
                pathname: "/login",
                search: isNavigator
                    ? `service=${PassioService.navigator}`
                    : undefined,
            },
            { replace: true, state: {} }
        );
    }

    async function handleSignup(values: SignupFormData) {
        try {
            if (isNavigator) {
                if (!defaultServiceId && !programs.length) {
                    enqueueSnackbar(missingProgramsError, {
                        variant: "error",
                    });
                    return;
                }

                let program;
                if (!defaultServiceId) {
                    if (programs.length) {
                        program = programs.filter(
                            (program) => !program.membershipIsRequired
                        )[0];
                    }
                } else {
                    program = programs.find(
                        (item) => item.databaseID === defaultServiceId
                    );
                }

                if (!program) {
                    enqueueSnackbar(missingProgramsError, {
                        variant: "error",
                    });
                    return;
                }

                const account = formatNavigatorAccount(values, program);
                await signup({ agency, account }).unwrap();

                try {
                    const email = formatNewAccountEmail(
                        values,
                        program,
                        config.DispatcherEmail
                    );

                    await sendEmail(email).unwrap();
                    const message = formatNewAccountSMS(
                        `${values.firstName} ${values.lastName}`
                    );
                    await sendSMS({
                        to: values.phone as string,
                        message,
                    }).unwrap();
                } catch {}
            } else {
                const account = formatParaplanAccount(values);
                await signup({ agency, account }).unwrap();
            }
            enqueueSnackbar("Account created successfully", {
                variant: "success",
            });

            handleRedirect();
        } catch (error) {
            if (isFetchBaseQueryError(error)) {
                enqueueSnackbar(`${error.data}`, { variant: "error" });
            } else {
                enqueueSnackbar("Sorry, an error occured. Please try again", {
                    variant: "error",
                });
            }
        }
    }

    /**
     * only proceed if service url is present
     * if agency name was passed to / or /login and /signup paths,
     * serviceURL will be available after the agency config is fetched
     */

    useEffect(() => {
        if (!serviceUrl || !agency) {
            navigate("/login");
        }
    }, [serviceUrl, agency, navigate]);

    // always clear cache when the user leaves this page
    useBeforeUnload(
        useCallback(() => {
            dispatch(logout());
        }, [dispatch])
    );

    const loading = isLoading || isSendingEmail || isSendingSMS;

    // for navigator, ensure agency has defaultService set or has at leat one program without membership required
    if (
        isNavigator &&
        !loadingPrograms &&
        !defaultServiceId &&
        !availablePrograms.length
    ) {
        return (
            <Error
                description={missingProgramsError}
                onClick={() => handleRedirect()}
            />
        );
    }

    if (loadingPrograms) {
        return <Splashscreen description="Processing" />;
    }

    return (
        <Container maxWidth="lg" sx={{ p: 1.5 }}>
            <Stack
                direction={{ sm: "column", md: "row" }}
                sx={{
                    my: 4,
                    alignItems: "center",
                    justifyContent: { xs: "center", md: "space-between" },
                }}
            >
                <Stack direction="row" spacing={1} alignItems="center">
                    <PersonAdd fontSize="large" />
                    <Typography variant="h4" component="h1">
                        Connect Signup
                    </Typography>
                </Stack>

                <Typography my={2}>
                    Already have an account?{" "}
                    <Link
                        to={{
                            pathname: "/login",
                            search: isNavigator
                                ? `service=${PassioService.navigator}`
                                : undefined,
                        }}
                        onClick={() => dispatch(logout())}
                    >
                        Login
                    </Link>
                </Typography>
            </Stack>

            <Formik
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={handleSignup}
            >
                {({ values, errors }) => (
                    <Form>
                        <Stack spacing={4}>
                            {/* Agency Profile */}
                            <Card variant="elevation" sx={{ p: 1.5 }}>
                                <FormControl
                                    component="fieldset"
                                    sx={{ mb: 4, display: "flex", gap: 2 }}
                                >
                                    <FormLabel
                                        component="legend"
                                        sx={{
                                            mb: 2,
                                            fontSize: 20,
                                            color: "primary.main",
                                        }}
                                    >
                                        Account Details
                                    </FormLabel>

                                    <Stack
                                        spacing={2}
                                        direction={{ xs: "column", md: "row" }}
                                    >
                                        <FormInput
                                            name="firstName"
                                            label="First Name"
                                            sx={{ flexGrow: 1 }}
                                        />
                                        <FormInput
                                            name="lastName"
                                            label="Last Name"
                                            sx={{ flexGrow: 1 }}
                                        />
                                    </Stack>

                                    <Stack
                                        spacing={2}
                                        direction={{
                                            xs: "column",
                                            md: "row",
                                        }}
                                    >
                                        <PhoneNumberInput
                                            type="tel"
                                            name="phone"
                                            label="Phone"
                                            sx={{ flexGrow: 1 }}
                                            autoComplete="tel"
                                        />

                                        <FormDatePicker
                                            format="MM/dd/yyyy"
                                            name="birthdate"
                                            label={`Birthday ${
                                                isNavigator ? "(Optional)" : ""
                                            }`}
                                            sx={{ flexGrow: 1 }}
                                        />

                                        <FormInput
                                            type="email"
                                            name="email"
                                            label="Email"
                                            sx={{ flexGrow: 1 }}
                                        />
                                    </Stack>
                                </FormControl>
                            </Card>

                            {/* Password */}
                            <Card variant="elevation" sx={{ p: 1.5 }}>
                                <FormControl
                                    component="fieldset"
                                    sx={{ mb: 4, display: "flex", gap: 2 }}
                                >
                                    <FormLabel
                                        component="legend"
                                        sx={{
                                            mb: 2,
                                            fontSize: 20,
                                            color: "primary.main",
                                        }}
                                    >
                                        Password
                                    </FormLabel>

                                    <Stack spacing={1}>
                                        <FormInput
                                            type="password"
                                            name="password"
                                            label="Password"
                                            sx={{ flexGrow: 1 }}
                                        />
                                        <FormInput
                                            type="password"
                                            name="confirmPassword"
                                            label="confirmPassword"
                                            sx={{ flexGrow: 1 }}
                                        />
                                    </Stack>
                                </FormControl>
                            </Card>

                            {/* Accessibility */}
                            <Card variant="elevation" sx={{ p: 1.5 }}>
                                <FormControl
                                    component="fieldset"
                                    sx={{ mb: 4, display: "flex", gap: 2 }}
                                >
                                    <FormLabel
                                        component="legend"
                                        sx={{
                                            mb: 2,
                                            fontSize: 20,
                                            color: "primary.main",
                                        }}
                                    >
                                        Accessibility
                                    </FormLabel>

                                    <Stack
                                        spacing={1}
                                        direction={{ xs: "column", md: "row" }}
                                    >
                                        <FormCheckbox
                                            name="accessibility.hasPCA"
                                            label="I travel with a Personal Care Attendant"
                                            sx={{ flexGrow: 1 }}
                                        />

                                        <FormCheckbox
                                            name="accessibility.usesWheelchair"
                                            label="I would like an ADA accessible vehicle"
                                            sx={{ flexGrow: 1 }}
                                        />
                                        <FormInput
                                            name="accessibility.wheelChairType"
                                            label="Wheelchair Type"
                                            sx={{ flexGrow: 1 }}
                                        />
                                    </Stack>
                                </FormControl>
                            </Card>

                            {/* Home Address */}
                            <Card variant="elevation" sx={{ p: 1.5 }}>
                                <FormControl
                                    component="fieldset"
                                    sx={{ mb: 4, display: "flex", gap: 2 }}
                                >
                                    <FormLabel
                                        component="legend"
                                        sx={{
                                            mb: 2,
                                            fontSize: 20,
                                            color: "primary.main",
                                        }}
                                    >
                                        Home Address{" "}
                                        {isNavigator && "(Optional)"}
                                    </FormLabel>

                                    <Box>
                                        <PlacesAutocomplete
                                            label="Address"
                                            name="address"
                                            validationKey="address1"
                                        />

                                        {isValidCoord({
                                            lat: values.address?.latitude,
                                            lng: values.address?.longitude,
                                        }) && (
                                            <GoogleMap
                                                pickUpCoords={{
                                                    lat:
                                                        values?.address
                                                            ?.latitude || 0,
                                                    lng:
                                                        values?.address
                                                            ?.longitude || 0,
                                                }}
                                                sx={{
                                                    height: 450,
                                                    width: "100%",
                                                    my: 2,
                                                }}
                                            />
                                        )}
                                    </Box>
                                </FormControl>
                            </Card>
                        </Stack>

                        <Box maxWidth="sm" mx="auto" my={4}>
                            <LoadingButton
                                size="large"
                                fullWidth
                                type="submit"
                                variant="contained"
                                sx={{ py: 1.25 }}
                                loading={loading}
                                onClick={() => alertIfMissingFields(errors)}
                            >
                                Create account
                            </LoadingButton>
                        </Box>
                    </Form>
                )}
            </Formik>
        </Container>
    );
};

export default Signup;
