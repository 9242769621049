import { pick, omit } from "lodash";
import { Client, Program, RiderProgram } from "src/modules/auth/types";
import { ProfileFormData } from "./types";
import { dateTimeFormat } from "src/utils/constants";
import { getInitialValues } from "../auth/pages/signup/helpers";
import moment from "moment";

// selected fields for profile update
export const accessibleFields = ["hasPCA", "usesWheelchair", "wheelChairType"];
export const dataFields = [
    "id",
    "email",
    "phone",
    "defaultProgramMembershipID",
    "defaultProgramName",
    "accessibility",
];

export const navigatorDataFields = [
    "riderId",
    "defaultServiceId",
    "address",
    "programs",
];

export function initProfile(
    client: Client,
    programs: Program[] = [],
    availableFundingSources: string[] = [],
    newRider = false,
    isNavigator = false
): ProfileFormData {
    let profile: ProfileFormData = omit(getInitialValues(), [
        "password",
        "confirmPassword",
    ]);

    if (client && Object.keys(client).length) {
        const [firstName, ...lastName] = client?.name?.split(" ");
        profile = {
            ...pick(client, dataFields),
            firstName,
            lastName: lastName.join(" "),
            birthdate: moment(client?.birthdate).toDate(),
        };
    }
    if (isNavigator) {
        profile = {
            ...profile,
            ...pick(client, navigatorDataFields),
        };
    }

    if (newRider) {
        profile.defaultProgramName = availableFundingSources[0];
    }

    return profile;
}

// only include needed properties
export function formatParaplanProfile(values: ProfileFormData) {
    return {
        ...pick(values, dataFields),
        accessibility: pick(values.accessibility, accessibleFields),
        name: `${values.firstName} ${values.lastName}`,
        birthdateEpoch: moment(values.birthdate).unix(),
    };
}

export function formatNavigatorRiderAccount(values: ProfileFormData) {
    // convert programs back, we only need two fields
    const programs = values?.programs?.reduce((acc: RiderProgram[], item) => {
        const program = {
            serviceId: item.databaseID,
            membershipId: item.membershipID,
        };

        acc.push(program);
        return acc;
    }, []);

    return {
        ...omit(values, ["home", "birthdate", "firstName", "lastName"]),
        name: `${values.firstName} ${values.lastName}`,
        birthdate: moment(values.birthdate).format(dateTimeFormat),
        accessibility: JSON.stringify(values.accessibility),
        address: JSON.stringify(values.address),
        membershipId: values.defaultProgramMembershipID || "",
        programs: programs || [],
    };
}
