import {
    Box,
    Stack,
    useTheme,
    Container,
    Typography,
    useMediaQuery,
} from "@mui/material";
import { AccountCircleOutlined, HistoryOutlined } from "@mui/icons-material";
import { DataGrid, GridLogicOperator } from "@mui/x-data-grid";
import { useTitle } from "react-use";
import { useParams } from "react-router-dom";
import { useAppSelector } from "src/hooks";
import { columns } from "./helpers";
import { authSelector } from "src/modules/auth/redux/authSlice";
import { tripsAPI } from "../../redux/tripSlice";
import CustomToolbar from "./components/CustomToolbar";
import { PassioService } from "src/types";

declare module "@mui/x-data-grid" {
    interface RowPropsOverrides {
        refreshTable?: () => void;
    }
}

const TripHistory = () => {
    useTitle("Trip History");
    const { id } = useParams(); // managers can view the trip history of a rider
    const theme = useTheme();
    const isMobileDevice = useMediaQuery(theme.breakpoints.down("md"));
    const { isManager, client, service } = useAppSelector(authSelector);
    const isNavigator = service === PassioService.navigator;

    // client id maps to navigator riderId
    const requestId = id && isManager ? parseInt(id) : client.id;
    const {
        data: trips = [],
        isLoading,
        isFetching,
        refetch,
    } = tripsAPI.useGetTripsQuery(requestId, {
        skip: !requestId,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true,
        refetchOnReconnect: true,
    });

    const activeRider = trips?.[0]?.client; // only available for Paraplan

    return (
        <Container maxWidth="xl" sx={{ p: 2 }}>
            <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                justifyContent="space-between"
                my={4}
            >
                <Stack direction="row" spacing={1} alignItems="center">
                    <HistoryOutlined fontSize="large" />
                    <Typography variant="h4" component="h1">
                        Trip History
                    </Typography>
                </Stack>

                {id && isManager && activeRider?.name && (
                    <Stack direction="row" spacing={1} alignItems="center">
                        <AccountCircleOutlined fontSize="large" />
                        <Typography variant="h5" component="span">
                            {activeRider?.name}
                        </Typography>
                    </Stack>
                )}
            </Stack>

            <Box>
                <DataGrid
                    autoHeight
                    columns={columns}
                    rows={trips}
                    getRowId={(row) => row.databaseId}
                    getRowHeight={() => "auto"}
                    loading={isLoading || isFetching}
                    pageSizeOptions={[5, 10, 25, 50, 100]}
                    slots={{ toolbar: CustomToolbar }}
                    slotProps={{
                        toolbar: {
                            quickFilterProps: { debounceMs: 500 },
                            refreshTable: refetch,
                        },
                    }}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: isMobileDevice ? 5 : 10,
                            },
                        },
                        filter: {
                            filterModel: {
                                items: [],
                                quickFilterLogicOperator: GridLogicOperator.Or,
                            },
                        },
                        columns: {
                            columnVisibilityModel: {
                                mobileCard: isMobileDevice,
                                tripDate: !isMobileDevice,
                                puPlace: !isMobileDevice,
                                doPlace: !isMobileDevice,
                                reservationType: !isMobileDevice,
                                dummy: !isMobileDevice,
                                status: !isMobileDevice,
                                tripRating: !isMobileDevice && isNavigator,
                            },
                        },
                    }}
                    sx={{
                        p: 0.5,
                        ...(isMobileDevice && {
                            border: 0,
                            "& .MuiDataGrid-cell": {
                                p: 0,
                                border: 0,
                                bgcolor: "white",
                            },
                            "& .MuiDataGrid-cell:focus": {
                                outline: "none",
                            },
                        }),
                    }}
                />
            </Box>
        </Container>
    );
};

export default TripHistory;
