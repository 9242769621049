import { ChangeEventHandler } from "react";
import { Program } from "src/modules/auth/types";
import { FormInput } from "src/components";
import { useTheme, useMediaQuery, MenuItem } from "@mui/material";

interface Props {
    name: string;
    label: string;
    programs: Program[];
    availableFundingSources: string[];
    onChange?: ChangeEventHandler;
    hasAddresses: boolean;
}

const TripSelector = ({
    name = "",
    label = "",
    programs = [],
    availableFundingSources = [],
    hasAddresses = false,
    onChange,
}: Props) => {
    const theme = useTheme();
    const isMobileDevice = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <FormInput
            select
            fullWidth
            tabIndex={0}
            name={name}
            label={label}
            SelectProps={{ native: isMobileDevice }}
            onChange={onChange}
            helperText={
                hasAddresses && programs.length
                    ? "Available services filtered due to times or locations"
                    : ""
            }
        >
            {programs.length > 0
                ? programs.map((program, index) => {
                      const props = { key: index, value: program.programName };
                      const label = `${program.programName} ${
                          program.membershipID
                              ? `(${program.membershipID})`
                              : ""
                      }`;
                      return isMobileDevice ? (
                          <option {...props}>{label}</option>
                      ) : (
                          <MenuItem {...props}>{label}</MenuItem>
                      );
                  })
                : availableFundingSources.map((program, index) => {
                      const props = { key: index, value: program };
                      return isMobileDevice ? (
                          <option {...props}>{program}</option>
                      ) : (
                          <MenuItem {...props}>{program}</MenuItem>
                      );
                  })}
        </FormInput>
    );
};

export default TripSelector;
