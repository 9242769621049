import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Splashscreen } from "src/components";
import { useAppDispatch } from "src/hooks";
import { enqueueSnackbar } from "notistack";
import { authAPI, authActions } from "../redux/authSlice";
import { isErrorWithMessage } from "src/utils/helpers";
import { Config, Client, FormattedUserAndClient } from "../types";
import { PassioService } from "src/types";
import { navigatorAPIUrl } from "src/utils/constants";
import { tripsAPI } from "src/modules/trips/redux/tripSlice";

const OAuthRedirect = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [processing, setProcessing] = useState({
        active: false,
        message: "Processing ...",
    });

    const handleLogin = useCallback(
        async (userId = "") => {
            try {
                let rider = {} as Client;
                let config = {} as Config;

                // load onDemandUser and rider profile
                setProcessing({ active: true, message: "Loading Account" });
                const onDemandUser = await dispatch(
                    authAPI.endpoints.getOnDemandUser.initiate(userId)
                );

                if (onDemandUser.isError) {
                    throw new Error("Sorry we couldn't load your services");
                }

                const profile = onDemandUser.data as FormattedUserAndClient;
                rider = profile.client;

                setProcessing({ active: true, message: "Loading Services" });
                const programs = await dispatch(
                    authAPI.endpoints.getPrograms.initiate({
                        id: rider.agencyId,
                    })
                );

                if (programs.isError) {
                    throw new Error("Sorry we couldn't load your services");
                }

                // reload rider so that programs get formatted correctly
                setProcessing({ active: true, message: "Organizing Services" });
                await dispatch(
                    authAPI.endpoints.getOnDemandUser.initiate(userId)
                );

                /*
                 * Navigator config was already populated from  user object in previous step
                 * Only fetch config if service is Paraplan
                 */
                setProcessing({ active: true, message: "Loading Preferences" });
                const agencyConfig = await dispatch(
                    authAPI.endpoints.getConfig.initiate(rider.agencyId)
                );

                if (!agencyConfig.data || agencyConfig.isError) {
                    throw new Error("Sorry we couldn't load your preferences");
                }

                config = agencyConfig.data;

                /*
                 * Geofence calls are made to Passio API and need a navigator token
                 */

                // Todo: skip load when onDemandAccess can call geofence
                setProcessing({
                    active: true,
                    message: "Authorizing Passio Access",
                });

                const passioToken = await dispatch(
                    authAPI.endpoints.getPassioToken.initiate()
                );

                if (passioToken.isError) {
                    throw new Error("Sorry we couldn't authorize your access");
                }

                // load geozones
                setProcessing({ active: true, message: "Loading Geozones" });

                const agency = config?.PassioUsername || "";
                const geozones = await dispatch(
                    authAPI.endpoints.getGeoZones.initiate(agency)
                );

                if (geozones.isError) {
                    throw new Error("Sorry we couldn't load your zones");
                }

                // check location preferences
                const preferences = config?.ConnectPreferences;
                if (
                    !preferences?.CanRequestAnyPULocation ||
                    !preferences?.CanRequestAnyDOLocation
                ) {
                    // load places
                    setProcessing({ active: true, message: "Loading Places" });

                    const places = await dispatch(
                        authAPI.endpoints.getPlaces.initiate()
                    );

                    if (places.isError) {
                        throw new Error("Sorry we couldn't load places");
                    }
                }

                // load devices
                setProcessing({ active: true, message: "Loading Devices" });
                const devices = await dispatch(
                    tripsAPI.endpoints.getDevices.initiate(rider.agencyId)
                );

                if (devices.isError) {
                    throw new Error("Sorry we couldn't load devices");
                }

                // load MDTs
                setProcessing({ active: true, message: "Loading MDTs" });
                const MDTs = await dispatch(
                    tripsAPI.endpoints.getMDTs.initiate()
                );

                if (MDTs.isError) {
                    throw new Error("Sorry we couldn't load MDTs");
                }

                enqueueSnackbar("Login successful", { variant: "success" });
                navigate("/trips");
            } catch (error) {
                const message = isErrorWithMessage(error)
                    ? error.message
                    : "Login failed. Please try again later.";
                enqueueSnackbar(message, { variant: "error" });
                navigate(`/login?service=${PassioService.navigator}`);
            }
        },
        [dispatch, navigate]
    );

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const userId = params.get("userId");
        const token = params.get("token");

        if (!userId || !token) {
            navigate(`/login?service=${PassioService.navigator}`);
        } else {
            dispatch(
                authActions.save({
                    token,
                    service: PassioService.navigator,
                    serviceUrl: navigatorAPIUrl,
                    tokenIsValid: true,
                    tokenExists: true,
                })
            );
            handleLogin(userId);
        }
    }, [navigate, dispatch, handleLogin]);

    return <Splashscreen description={processing.message} />;
};

export default OAuthRedirect;
