import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import type {
    BaseQueryFn,
    FetchArgs,
    FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import type { RootState } from "src/redux/store";
import { PassioService } from "src/types";
import { paraPlanApiUrl, device, navigatorAPIUrl } from "src/utils/constants";
import { isPrefixedUrl } from "src/utils/helpers";

interface TDBRequest extends Pick<FetchArgs, "body" | "params"> {
    path: string;
    token?: string;
    method?: "get" | "post" | "put" | "delete" | "patch";
}

const rawBaseQuery = fetchBaseQuery({ baseUrl: paraPlanApiUrl });

const dynamicBaseQuery: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    const state = api.getState() as RootState;
    const serviceUrl = state.auth.serviceUrl;
    const token = state.auth.token;
    const url = typeof args === "string" ? args : args.url;
    // check to make sure url is not fully qualified i.e has http:// or https://
    const isPrefixed = isPrefixedUrl(url);
    // gracefully handle scenarios where data to generate the URL is missing

    // there wont be a token at login so we login first
    if (!isPrefixed && (!serviceUrl || !token)) {
        return {
            error: {
                status: 400,
                statusText: "Bad Request",
                data: "missing token or serviceurl",
            },
        };
    }

    // construct a dynamically generated portion of the url
    const adjustedUrl = isPrefixed
        ? url
        : state.auth.service === PassioService.navigator
        ? `${serviceUrl}${url}?onDemandAccessToken=${token}`
        : `${serviceUrl}${url}?Token=${token}&Device=${device}`;

    const adjustedArgs =
        typeof args === "string" ? adjustedUrl : { ...args, url: adjustedUrl };
    // provide the amended url and other params to the raw base query
    return rawBaseQuery(adjustedArgs, api, extraOptions);
};

/**
 * Endpoints are extended in each module
 * @see https://redux-toolkit.js.org/rtk-query/usage/code-splitting
 */
const API = createApi({
    reducerPath: "api",
    baseQuery: dynamicBaseQuery,
    endpoints: (build) => ({
        tdbGet: build.query<any, any>({
            query: (data) => ({
                url: "/ondemand/tdb/get/",
                method: "post",
                body: data,
            }),
        }),
        // dynamic tdb handler
        tdb: build.mutation<any, TDBRequest>({
            query: ({ path, body, token, method = "post" }) => ({
                url: token
                    ? `${navigatorAPIUrl}/tdb/${path}?accessToken=${token}`
                    : `/ondemand/tdb/${path}`,
                method,
                body,
            }),
        }),
    }),
});

export default API;
