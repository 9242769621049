import { useState, useMemo, useEffect, useCallback } from "react";
import {
    Box,
    Grid,
    List,
    Stack,
    Button,
    ListItem,
    Container,
    Typography,
    ButtonGroup,
} from "@mui/material";
import { FeedOutlined } from "@mui/icons-material";
import { useTitle, useLocalStorage } from "react-use";
import { useAppDispatch, useAppSelector } from "src/hooks";
import {
    Error as ErrorDialog,
    GoogleMap,
    Splashscreen,
    CustomListItemText,
} from "src/components";
import {
    Link,
    useNavigate,
    useParams,
    useSearchParams,
} from "react-router-dom";
import { authSelector } from "src/modules/auth/redux/authSlice";
import { tripsAPI } from "../../redux/tripSlice";
import { isFetchBaseQueryError } from "src/utils/helpers";
import { PassioService, TripScheduleStatus } from "src/types";
import { getNotificationLSKey, notificationCheckKeys } from "./helpers";
import * as emailHelpers from "src/utils/email";
import * as smsHelpers from "src/utils/sms";
import { GetDriverAndVehicleDetailsResponse } from "../../types";
import RatingsDialog from "../components/RatingsDialog";

/**
 *
 * @param id - trip id, uuidv4 format
 * Optional query parameters include status and rider id if manager requested this trip
 * @examples
 * @param status - trip status, one of "live", "history"
 * @param rider - rider id number 12345
 */

const Details = () => {
    useTitle("Trip Confirmation");
    const { id = "" } = useParams(); // trip id
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [rating, setRating] = useState({ open: false, driver: "" });

    const [autoScheduleChecked] = useLocalStorage(
        `${id}autoScheduleChecked`,
        false
    );
    const [autoScheduleFailCount = 0] = useLocalStorage(
        `${id}autoScheduleFailCount`,
        0
    );

    const [emailChecks, setEmailChecks] = useLocalStorage(
        getNotificationLSKey(id),
        notificationCheckKeys
    );

    const [smsChecks, setSMSChecks] = useLocalStorage(
        getNotificationLSKey(id, "sms"),
        notificationCheckKeys
    );

    const [searchParams] = useSearchParams();
    const status = searchParams.get("status") ?? "";
    const rider = searchParams.get("rider"); // manager can request trip for rider
    const [stop, setStop] = useState(false);
    const { service, isManager, config, client } = useAppSelector(authSelector);
    const [sendEmail, { isLoading: isSending }] =
        tripsAPI.useSendEmailMutation();
    const [sendSMS, { isLoading: isSendingSMS }] =
        tripsAPI.useSendSMSMutation();

    const isNavigator = service === PassioService.navigator;
    const stopCodes = useMemo(() => {
        return isNavigator
            ? [TripScheduleStatus.Cancelled, TripScheduleStatus.Noshowed]
            : [11, 12, 13];
    }, [isNavigator]);

    const canAutoScheduleTrips =
        isNavigator &&
        !autoScheduleChecked &&
        autoScheduleFailCount < 2 &&
        config?.ConnectPreferences?.AutoScheduleHierarchy
            ?.SystemShouldAutoSchedule;

    const { data, isLoading, isError, error, refetch } =
        tripsAPI.useGetTripStatusQuery(id, {
            skip: !id || stop || isSending || isSendingSMS,
            refetchOnFocus: true,
            refetchOnMountOrArgChange: true,
            refetchOnReconnect: true,
            pollingInterval: !stop ? 15000 : 0,
        });

    function handleClose() {
        // if trip was requested by manager, redirect to riders history
        if (rider && isManager) {
            navigate(`/riders/${rider}/history`);
            return;
        }
        navigate("/trips/history");
    }

    const fetchDriverAndVehicleDetails = useCallback(
        async (runId = "") => {
            let response: GetDriverAndVehicleDetailsResponse = {
                driverName: "",
                vehicle: "",
            };

            if (runId) {
                const { data: run } = await dispatch(
                    tripsAPI.endpoints.getRun.initiate(runId)
                );
                if (!run) return response;
                const { data: driverDetails } = await dispatch(
                    tripsAPI.endpoints.getDriverAndVehicleDetails.initiate({
                        busId: run.busId,
                        driverId: run.driverId,
                    })
                );
                if (driverDetails) response = driverDetails;
            }

            return response;
        },
        [dispatch]
    );

    const handleNotification = useCallback(async () => {
        try {
            if (isSending || isSendingSMS) return;
            switch (data?.rideStatus) {
                case TripScheduleStatus.PickUpArrived:
                    if (emailChecks && !emailChecks.driverArrived) {
                        const email =
                            emailHelpers.formatDriverArrivedEmail(data);
                        await sendEmail(email).unwrap();
                        setEmailChecks({ ...emailChecks, driverArrived: true });
                    }

                    if (smsChecks && !smsChecks.driverArrived) {
                        const message = smsHelpers.formatDriverArrivedSMS(data);
                        await sendSMS({ to: client.phone, message }).unwrap();
                        setSMSChecks({ ...smsChecks, driverArrived: true });
                    }
                    break;
                case TripScheduleStatus.NotApproved:
                    if (emailChecks && !emailChecks.tripNotApproved) {
                        const email =
                            emailHelpers.formatTripNotApprovedEmail(data);
                        await sendEmail(email).unwrap();
                        setEmailChecks({
                            ...emailChecks,
                            tripNotApproved: true,
                        });
                    }

                    if (smsChecks && !smsChecks.tripNotApproved) {
                        const message = smsHelpers.formatTripNotApprovedSMS();
                        await sendSMS({ to: client.phone, message }).unwrap();
                        setSMSChecks({ ...smsChecks, tripNotApproved: true });
                    }
                    break;
                case TripScheduleStatus.AcceptedTripRequest:
                    if (emailChecks && !emailChecks.tripApproved) {
                        const email =
                            emailHelpers.formatTripApprovedEmail(data);
                        await sendEmail(email).unwrap();
                        setEmailChecks({ ...emailChecks, tripApproved: true });
                    }

                    if (smsChecks && !smsChecks.tripApproved) {
                        const message = smsHelpers.formatTripApprovedSMS(data);
                        await sendSMS({ to: client.phone, message }).unwrap();
                        setSMSChecks({ ...smsChecks, tripApproved: true });
                    }
                    break;
                case TripScheduleStatus.Scheduled:
                    if (emailChecks && !emailChecks.tripScheduled) {
                        const email = emailHelpers.formatTripAssignedEmail(
                            data,
                            await fetchDriverAndVehicleDetails(data.runId)
                        );
                        await sendEmail(email).unwrap();
                        setEmailChecks({ ...emailChecks, tripScheduled: true });
                    }

                    if (smsChecks && !smsChecks.tripScheduled) {
                        const message = smsHelpers.formatTripAssignedSMS(
                            data,
                            (await fetchDriverAndVehicleDetails(data.runId))
                                .driverName
                        );
                        await sendSMS({ to: client.phone, message }).unwrap();
                        setSMSChecks({ ...smsChecks, tripScheduled: true });
                    }
                    break;
            }
        } catch (error) {
            console.error(error);
        }
    }, [
        isSending,
        isSendingSMS,
        data,
        emailChecks,
        smsChecks,
        sendEmail,
        setEmailChecks,
        sendSMS,
        client.phone,
        setSMSChecks,
        fetchDriverAndVehicleDetails,
    ]);

    const initRating = useCallback(
        async (runId = "") => {
            try {
                const details = await fetchDriverAndVehicleDetails(runId);
                setRating({ open: true, driver: details.driverName });
            } catch {}
        },
        [fetchDriverAndVehicleDetails]
    );

    useEffect(() => {
        // ensure trip id is valid for Navigator and Paraplan
        if (!id) return navigate("/trips/request");
        if (!data) return;
        // Paraplan stop polling if trip is completed, rejected or cancelled
        if (stopCodes.includes(data.rideStatus)) setStop(true);

        if (isNavigator) {
            if (
                canAutoScheduleTrips &&
                !data.runId &&
                data.rideStatus !== TripScheduleStatus.Cancelled
            ) {
                navigate(`/trips/schedule/${data.tripId}`);
            }
            handleNotification();
            // trip is complete if dropOff has been performed
            if (
                !data.tripRating &&
                data.rideStatus === TripScheduleStatus.DropOffPerformed
            ) {
                initRating(data.runId);
            }
        }
    }, [
        id,
        data,
        isNavigator,
        stopCodes,
        navigate,
        handleNotification,
        initRating,
        canAutoScheduleTrips,
    ]);

    if (isLoading) {
        return <Splashscreen description="Processing" />;
    }

    if (isError && isFetchBaseQueryError(error)) {
        return <ErrorDialog description={(error.data as any)?.errorMessage} />;
    } else if (isError) {
        return (
            <ErrorDialog
                description="Sorry we couldn't fetch trip status. Please check your connection and try again"
                action={
                    <Button
                        variant="contained"
                        sx={{ py: 1.5, px: 4, my: 2 }}
                        onClick={refetch}
                    >
                        Try again
                    </Button>
                }
            />
        );
    }

    // Paraplan: Trip has no coordinates and data until approved or assigned
    if (
        data?.rideStatus === 1 &&
        !data?.pickUpAddress &&
        service === PassioService.paraplan &&
        !isError
    ) {
        return (
            <ErrorDialog
                description={data?.status}
                title={`Thanks! ${data?.username}`}
                action={
                    <Button
                        variant="contained"
                        sx={{ py: 1.5, px: 4, my: 2 }}
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                }
            />
        );
    }

    return (
        <Container maxWidth="xl" sx={{ p: 2 }}>
            {status === "live" ? (
                <Typography variant="h4" component="h2" my={4}>
                    Thanks! {data?.username}
                </Typography>
            ) : (
                <Typography variant="h4" component="h1" mt={4}>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <FeedOutlined fontSize="large" />
                        <span>Trip Details</span>
                    </Stack>
                </Typography>
            )}

            <Grid container spacing={2}>
                {/* Details  */}
                <Grid item xs={12} md={6}>
                    <List>
                        <ListItem disableGutters>
                            <CustomListItemText
                                primary="Status"
                                secondary={data?.status}
                            />
                        </ListItem>
                        <ListItem disableGutters>
                            <CustomListItemText
                                primary="Pick Up Address"
                                secondary={data?.pickUpAddress}
                            />
                        </ListItem>
                        <ListItem disableGutters>
                            <CustomListItemText
                                primary="Scheduled Pick Up Time"
                                secondary={
                                    data?.scheduledPickUpTimeEpoch || "N/A"
                                }
                            />
                        </ListItem>
                        {/* Arrival */}

                        {data?.actualPickUpArrivalEpoch && (
                            <ListItem disableGutters>
                                <CustomListItemText
                                    primary="Arrived at"
                                    secondary={
                                        data?.actualPickUpArrivalEpoch || "N/A"
                                    }
                                />
                            </ListItem>
                        )}
                        {data?.estimatedPickUpArrivalEpoch && (
                            <ListItem disableGutters>
                                <CustomListItemText
                                    primary="ETA to arrival"
                                    secondary={
                                        data?.estimatedPickUpArrivalEpoch ||
                                        "N/A"
                                    }
                                />
                            </ListItem>
                        )}
                        <ListItem disableGutters>
                            <CustomListItemText
                                primary="Drop Off Address"
                                secondary={data?.dropOffAddress}
                            />
                        </ListItem>
                        <ListItem disableGutters>
                            <CustomListItemText
                                primary="Scheduled Drop Off Time"
                                secondary={
                                    data?.scheduledDropOffTimeEpoch || "N/A"
                                }
                            />
                        </ListItem>

                        {/* Peform */}
                        {data?.actualDropOffTimePerformEpoch && (
                            <ListItem disableGutters>
                                <CustomListItemText
                                    primary="Completed at"
                                    secondary={
                                        data?.actualDropOffTimePerformEpoch ||
                                        "N/A"
                                    }
                                />
                            </ListItem>
                        )}

                        {data?.estimatedDropOffArrivalEpoch && (
                            <ListItem disableGutters>
                                <CustomListItemText
                                    primary="ETA to drop off"
                                    secondary={
                                        data?.estimatedDropOffArrivalEpoch ||
                                        "N/A"
                                    }
                                />
                            </ListItem>
                        )}
                        {data?.estimatedDropOffTimePerformEpoch && (
                            <ListItem disableGutters>
                                <CustomListItemText
                                    primary="ETA to completion"
                                    secondary={
                                        data?.estimatedDropOffTimePerformEpoch ||
                                        "N/A"
                                    }
                                />
                            </ListItem>
                        )}
                    </List>
                </Grid>

                {/**
                 * Map preview for program geozones.
                 * Fallback to request values if updated coords are not available
                 **/}
                <Grid item xs={12} md={6}>
                    <GoogleMap
                        {...data?.coords}
                        loading={isLoading}
                        sx={{ height: 450, width: "100%" }}
                    />
                </Grid>
            </Grid>

            <Box maxWidth="sm" my={4} mx="auto">
                <ButtonGroup
                    variant="contained"
                    size="large"
                    sx={{ my: 2 }}
                    fullWidth
                >
                    {/* only visible to riders */}

                    {!isManager && (
                        <Button
                            component={Link}
                            to="/trips/request"
                            sx={{ py: 1.5 }}
                        >
                            New Trip
                        </Button>
                    )}
                    <Button
                        color="secondary"
                        sx={{ py: 1.5 }}
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </ButtonGroup>
            </Box>

            {isNavigator && (
                <RatingsDialog
                    id={id}
                    open={rating.open}
                    rating={data?.tripRating || 0}
                    onClose={() => setRating({ open: false, driver: "" })}
                    description={`We take your feedback very seriously. Please rate your trip with ${rating.driver}`}
                />
            )}
        </Container>
    );
};

export default Details;
