import { omit, pick } from "lodash";
import { dateTimeFormat } from "src/utils/constants";
import {
    defaultAccessibility,
    defaultParsedAddress,
    defaultPlace,
} from "src/utils/defaultData";
import moment from "moment";
import { Program } from "../../types";
import { SignupFormData } from "./types";

export const missingProgramsError =
    "Sorry no programs are available for this agency, please contact the admin to setup a default program";

export function getInitialValues(isNavigator = false) {
    return {
        firstName: "",
        lastName: "",
        password: "",
        birthdate: isNavigator ? "" : new Date(),
        confirmPassword: "",
        defaultProgramMembershipID: "",
        accessibility: defaultAccessibility,
        home: defaultPlace,
        address: defaultParsedAddress,
    };
}

export function formatParaplanAccount(values: SignupFormData) {
    return {
        ...pick(values, ["email", "phone", "password"]),
        accessibility: pick(values.accessibility, [
            "hasPCA",
            "usesWheelchair",
            "wheelChairType",
        ]),
        name: `${values.firstName} ${values.lastName}`,
        birthdateEpoch: moment(values.birthdate).unix(),
        defaultProgramMembershipID: "",
        home: {
            ...omit(values.address, [
                "description",
                "latitude",
                "longitude",
                "street",
            ]),
            lat: values?.address?.latitude || 0,
            lng: values?.address?.longitude || 0,
        },
    };
}

export function formatNavigatorAccount(
    values: SignupFormData,
    program: Program
) {
    // convert programs back, we only need two fields
    const defaultProgram = {
        serviceId: program.databaseID,
        membershipId: program.membershipID,
    };

    const birthdate = values.birthdate
        ? moment(values.birthdate).format(dateTimeFormat)
        : "";
    return {
        ...omit(values, ["home", "birthdate", "firstName", "lastName"]),
        name: `${values.firstName} ${values.lastName}`,
        birthdate,
        accessibility: JSON.stringify(values.accessibility),
        address: JSON.stringify(values.address),
        programs: [defaultProgram],
        defaultServiceId: program.databaseID,
    };
}
