import {
    GridColDef,
    GridRenderCellParams,
    GridValueFormatterParams,
    GridValueGetterParams,
} from "@mui/x-data-grid";
import { Stack, Typography, Chip } from "@mui/material";
import type { TripHistory } from "../../types";
import { TripReservationType, StatusTypes } from "../../types";
import { omit } from "lodash";
import TripCard from "./components/TripCard";
import TableActions from "./components/TableActions";
import moment from "moment";
import Ratings from "./components/Ratings";

export function formatReservationName(type: TripReservationType) {
    switch (type) {
        case "T":
            return "Trip";
        case "Q":
            return "Request";
        case "R":
            return "Reservation";
        default:
            return "N/A";
    }
}

export function getStatusColor(status: StatusTypes | string) {
    switch (status) {
        case StatusTypes.Scheduled:
            return "info";
        case StatusTypes.Accepted:
            return "warning";
        case StatusTypes.Rejected:
            return "error";
        case StatusTypes.Cancelled:
            return "error";
        case StatusTypes.Requested:
            return "default";
        case StatusTypes.Completed:
            return "success";
        case StatusTypes.InProgress:
            return "info";
        default:
            return "default";
    }
}

/**
 * Column factory
 * @see https://mui.com/x/react-data-grid/column-definition/
 */

export const columns: GridColDef[] = [
    {
        field: "tripDate",
        headerName: "Trip Date",
        flex: 1.5,
        description: "Date of the trip",
        // this value getter enables the column to be filterable by both the date and the second line
        valueGetter: ({ row }: GridValueGetterParams<TripHistory>) => {
            return `${moment(row.tripDate).format("LLLL")} ${row.secondLine}`;
        },
        renderCell: ({ row }: GridRenderCellParams<TripHistory>) => {
            return (
                <Stack spacing={1} py={1.5}>
                    <Typography variant="body2" color="text.secondary">
                        {row.tripDate
                            ? moment(row.tripDate).format("LLLL")
                            : "N/A"}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {row.secondLine}
                    </Typography>
                </Stack>
            );
        },
    },
    {
        field: "status",
        headerName: "Status",
        flex: 1,
        description: "Trip status",
        valueGetter: ({ row }: GridValueGetterParams<TripHistory>) => {
            return row.secondLine;
        },
        renderCell: ({ row }: GridRenderCellParams<TripHistory>) => {
            const isScheduled = row?.secondLine.includes(StatusTypes.Scheduled);
            const color = getStatusColor(
                isScheduled ? StatusTypes.Scheduled : row.secondLine
            );

            return (
                <Chip
                    color={color}
                    label={
                        isScheduled
                            ? StatusTypes.Scheduled
                            : row.secondLine || StatusTypes.Pending
                    }
                />
            );
        },
    },
    {
        field: "puPlace",
        headerName: "Pick Up Place",
        flex: 1,
        description: "Place where the passenger was picked up",
        valueGetter: ({ row }: GridValueGetterParams<TripHistory>) => {
            return `${row.pickUpPlace?.name || "N/A"}`;
        },
    },
    {
        field: "doPlace",
        headerName: "Drop Off Place",
        flex: 1,
        description: "Place where the passenger was dropped off",
        valueGetter: ({ row }: GridValueGetterParams<TripHistory>) => {
            return `${row.dropOffPlace?.name || "N/A"}`;
        },
    },
    {
        field: "reservationType",
        headerName: "Type",
        flex: 1,
        description: "Trip type",
        valueFormatter: ({
            value,
        }: GridValueFormatterParams<TripHistory["reservationType"]>) => {
            return formatReservationName(value);
        },
    },

    {
        field: "tripRating",
        headerName: "Trip Rating",
        flex: 1,
        description: "Trip status",
        valueGetter: ({ row }: GridValueGetterParams<TripHistory>) => {
            return row.secondLine;
        },
        renderCell: ({ row }: GridRenderCellParams<TripHistory>) => {
            return <Ratings id={row.databaseId} rating={row.tripRating || 0} />;
        },
    },
    {
        field: "dummy",
        headerName: "Actions",
        flex: 1,
        description: "Actions your can perfom on this trip",
        disableColumnMenu: true,
        filterable: false,
        sortable: false,
        renderCell: TableActions,
    },
    {
        field: "mobileCard",
        headerName: "All Trips",
        flex: 1,
        description: "Full trip details",
        disableColumnMenu: true,
        // this value getter enables the column to be filterable by both the date and the second line
        valueGetter: ({ row }: GridValueGetterParams<TripHistory>) => {
            return `
                ${moment(row.tripDate).format("LLLL")} 
                ${row.secondLine} 
                ${row.pickUpPlace?.name}
                ${row.dropOffPlace?.name}
                ${formatReservationName(row.reservationType)}
                `;
        },
        renderCell: TripCard,
    },
];

export function formatTripLabel(prefix: string, trip: TripHistory) {
    const date = moment(trip.tripDate).format("LLLL");
    return `${prefix} for ${date} from ${trip.pickUpPlace?.name} to ${trip.dropOffPlace?.name}`;
}

const omitPlaceFields = ["lat", "lng"];
export function formatTripInfo(trip: TripHistory, isNavigator = false) {
    if (!trip || !trip.pickUpPlace || !trip.dropOffPlace) return {};

    const { pickUpPlace, dropOffPlace } = trip;

    let formattedTrip = {};

    if (isNavigator) {
        formattedTrip = { pickUpPlace, dropOffPlace };
    } else {
        formattedTrip = {
            pickUpPlace: {
                ...omit(pickUpPlace, omitPlaceFields),
                latitude: pickUpPlace.lat,
                longitude: pickUpPlace.lng,
                description: pickUpPlace?.description
                    ? pickUpPlace.description
                    : pickUpPlace.address1,
            },
            dropOffPlace: {
                ...omit(dropOffPlace, omitPlaceFields),
                latitude: dropOffPlace.lat,
                longitude: dropOffPlace.lng,
                description: dropOffPlace?.description
                    ? dropOffPlace.description
                    : dropOffPlace.address1,
            },
        };
    }

    return formattedTrip;
}
