import { useEffect, useState, useCallback, useRef } from "react";
import { useSearchParams, Outlet } from "react-router-dom";
import { authAPI, authSelector } from "src/modules/auth/redux/authSlice";
import { getService } from "src/utils/helpers";
import { PassioService } from "src/types";
import { useAppSelector } from "src/hooks";
import Splashscreen from "../Splashscreen";

/**
 *  loads agency config from url param
 *  @param agency
 *  @example /login?agency=ENGRAPHTRANSIT
 */

const AuthLayout = () => {
    const service = getService();
    const [searchParams, setSearchParams] = useSearchParams();
    const agency = searchParams.get("agency") ?? "";
    const isNavigator = service === PassioService.navigator;
    const { customerIds, passioToken } = useAppSelector(authSelector);
    const [processing, setProcessing] = useState(false);

    // check if url has been mapped
    const onDemandURLMapped = useRef(false);
    const [mapOnDemandURL, { isLoading: mappingOnDemandURL }] =
        authAPI.useLazyGetOndemandUrlMapperQuery();

    const isValidNavigatorAgency = agency && parseInt(agency) ? true : false; // navigator accounts are numbers

    // skip load if token is available or if Paraplan agency is specified
    const { isLoading } = authAPI.useGetPassioTokenQuery(undefined, {
        skip: !isValidNavigatorAgency || passioToken ? true : false,
    });

    // For Navigator, we check for UserSolution, potentially show an alert if necessary
    const { data: userSolutions = [], isLoading: loadingSolutions } =
        authAPI.useGetUserSolutionQuery(agency, {
            skip: isValidNavigatorAgency ? !passioToken : true,
        });

    // if agency is present, we prefetch their config url then their config
    const { data: url = "", isLoading: urlMapperLoading } =
        authAPI.useGetUrlMapperQuery(agency, {
            skip: isNavigator || !agency,
            refetchOnFocus: true,
            refetchOnMountOrArgChange: true,
            refetchOnReconnect: true,
        });

    const skipLoadConfig =
        !passioToken || !isValidNavigatorAgency || !userSolutions.length;
    const { isLoading: loadingAgencyConfig } = authAPI.useGetAgencyConfigQuery(
        {
            service,
            agency,
            url, // only needed for paraplan
        },
        {
            skip: isNavigator ? skipLoadConfig : !agency || !url,
            refetchOnFocus: true,
            refetchOnMountOrArgChange: true,
            refetchOnReconnect: true,
        }
    );

    const handleOnDemandURL = useCallback(async () => {
        setProcessing(true);
        try {
            const { data = [] } = await mapOnDemandURL(customerIds);
            const url = new URL(window.location.href);
            const item = data.find((item) => item.url.includes(url.origin));
            if (item) {
                setSearchParams({
                    service: PassioService.navigator,
                    agency: item.userId,
                });
                window.location.reload();
            }
        } catch {}
        onDemandURLMapped.current = true;
        setProcessing(false);
    }, [customerIds, mapOnDemandURL, setSearchParams]);

    useEffect(() => {
        if (!onDemandURLMapped.current && passioToken && customerIds.length) {
            handleOnDemandURL();
        }
    }, [passioToken, onDemandURLMapped, handleOnDemandURL, customerIds]);

    if (
        processing ||
        isLoading ||
        loadingSolutions ||
        urlMapperLoading ||
        loadingAgencyConfig ||
        mappingOnDemandURL
    ) {
        return <Splashscreen description="Processing" />;
    }
    return <Outlet />;
};

export default AuthLayout;
