import { useState, useRef } from "react";
import {
    AppBar,
    Box,
    Toolbar,
    IconButton,
    Typography,
    Menu,
    Container,
    Avatar,
    Button,
    Tooltip,
    MenuItem,
    Stack,
    Divider,
    SwipeableDrawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemButton,
    ListItemText,
    CircularProgress,
} from "@mui/material";

import {
    Launch,
    AddCircle,
    ListAlt,
    Logout,
    ContactSupport,
    AccountCircle,
    RestartAlt,
    Menu as MenuIcon,
    PersonAdd,
    KeyboardCommandKey,
} from "@mui/icons-material";

import { useAppDispatch, useAppSelector } from "src/hooks";
import {
    logout,
    authAPI,
    authSelector,
} from "src/modules/auth/redux/authSlice";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { startCase, capitalize } from "lodash";
import { isErrorWithMessage } from "src/utils/helpers";
import ShortcutsDialog from "./ShortcutsDialog";
import { PassioService } from "src/types";

const Navbar = () => {
    const menuAnchor = useRef(null);
    const [isMenuOpen, setOpen] = useState(false);
    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const [showShortcuts, setShowShortcuts] = useState(false);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [resetCache] = authAPI.useResetCacheMutation();
    const { isManager, user, config, service } = useAppSelector(authSelector);
    const [passioLogout, { isLoading }] = authAPI.usePassioLogoutMutation();

    const isNavigator = service === PassioService.navigator;
    const canDebug =
        user?.Email?.includes("timhibbard") ||
        user?.Email?.includes("passiotech.com");
    const preferences = config?.ConnectPreferences;
    const isExternal =
        !config?.AgencyName?.toLocaleLowerCase().includes("engraph");
    const displayName = preferences?.DisplayName || config?.AgencyName;

    const menuList = [
        {
            label: "New Request",
            path: "/trips/request",
            icon: <AddCircle />,
            hidden: isManager, // only visible to riders
        },
        {
            label: "Trips",
            path: "/trips/history",
            icon: <ListAlt />,
            hidden: isManager, // only visible to riders
        },
        {
            label: "Riders",
            path: "/riders",
            icon: <ListAlt />,
            hidden: !isManager, // only visible to managers
        },
        {
            label: "New Rider",
            path: "/riders/new",
            icon: <PersonAdd />,
            hidden: !isManager, // only visible to managers
        },
    ];

    const toggleMenu = () => {
        setOpen(!isMenuOpen);
    };

    const toggleDrawer = () => {
        setDrawerOpen(!isDrawerOpen);
    };

    async function handleLogout() {
        try {
            if (isNavigator) {
                await passioLogout().unwrap();
            }

            // reset location state to prevent auto redirects after login
            navigate(
                {
                    pathname: "/login",
                    search: isNavigator
                        ? `service=${PassioService.navigator}`
                        : undefined,
                },
                { replace: true, state: {} }
            );

            // add a little timeout to ensure service reset only after redirect
            setTimeout(() => dispatch(logout()), 200);
            enqueueSnackbar("Logout Successfull", { variant: "success" });
        } catch (error) {
            enqueueSnackbar("Sorry, an error occured. Please try again", {
                variant: "error",
            });
        }
    }

    async function handleCacheReset(type: "config" | "geozones") {
        try {
            const request = {
                geozones: type === "geozones" ? true : false,
                filespecs: type === "config" ? true : false,
            };
            await resetCache(request).unwrap();
            enqueueSnackbar(`${type} cache reset`, { variant: "success" });
        } catch (error) {
            if (isErrorWithMessage(error)) {
                enqueueSnackbar(error.message, { variant: "error" });
            } else {
                enqueueSnackbar(`sorry we couldn't reset ${type} cache`, {
                    variant: "error",
                });
            }
        }
    }

    return (
        <AppBar position="sticky" color="primary" enableColorOnDark>
            <Container maxWidth="xl">
                <Toolbar
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: 4,
                        py: { xs: 1.5, md: 0.25 },
                    }}
                    disableGutters
                >
                    {/* mobile nav drawer */}
                    <Box sx={{ display: { md: "none" } }}>
                        <IconButton
                            onClick={toggleDrawer}
                            size="small"
                            sx={{ color: "primary.contrastText" }}
                            aria-controls={
                                isDrawerOpen ? "account-menu" : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={isDrawerOpen ? "true" : undefined}
                        >
                            <MenuIcon />
                        </IconButton>
                        <SwipeableDrawer
                            anchor="left"
                            open={isDrawerOpen}
                            onClose={toggleDrawer}
                            onOpen={toggleDrawer}
                        >
                            <AppBar
                                position="sticky"
                                color="primary"
                                enableColorOnDark
                            >
                                <Toolbar
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        gap: 4,
                                        py: 0.25,
                                    }}
                                    disableGutters
                                >
                                    {/* logo */}
                                    <Stack
                                        spacing={1}
                                        direction="row"
                                        alignItems="center"
                                    >
                                        <Box
                                            component="img"
                                            src={preferences?.Logo}
                                            alt={`${displayName} logo`}
                                            sx={{
                                                height: isExternal ? 64 : 42,
                                                width: isExternal ? 100 : 42,
                                                color: "primary.contrastText",
                                                objectFit: "contain",
                                            }}
                                        />

                                        <Box>
                                            {displayName && (
                                                <Typography
                                                    variant="subtitle2"
                                                    component="span"
                                                >
                                                    Connect Rider
                                                </Typography>
                                            )}
                                            <Typography
                                                noWrap
                                                sx={{
                                                    lineHeight: 1,
                                                    fontSize: 20,
                                                    fontWeight: "medium",
                                                    letterSpacing: ".1rem",
                                                    color: "primary.contrastText",
                                                }}
                                            >
                                                {displayName
                                                    ? startCase(
                                                          capitalize(
                                                              displayName
                                                          )
                                                      )
                                                    : "Connect Rider"}
                                            </Typography>
                                        </Box>
                                    </Stack>
                                </Toolbar>
                            </AppBar>

                            <List sx={{ width: 300 }}>
                                {menuList.map((item, index) => {
                                    // hide protected routes
                                    return (
                                        !item?.hidden && (
                                            <ListItem
                                                key={index}
                                                disablePadding
                                                component={NavLink}
                                                to={item.path}
                                                onClick={toggleDrawer}
                                                sx={{
                                                    color: "text.secondary",
                                                    "&.active": {
                                                        color: "primary.main",
                                                        fontWeight: "bold",
                                                    },
                                                }}
                                            >
                                                <ListItemButton>
                                                    <ListItemIcon>
                                                        {item.icon}
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={item.label}
                                                    />
                                                </ListItemButton>
                                            </ListItem>
                                        )
                                    );
                                })}
                            </List>
                        </SwipeableDrawer>
                    </Box>

                    {/* logo */}
                    <Stack spacing={1} direction="row" alignItems="center">
                        <Box
                            component="img"
                            src={preferences?.Logo}
                            alt={`${displayName} logo`}
                            sx={{
                                height: isExternal ? 64 : 42,
                                width: isExternal ? 100 : 42,
                                color: "primary.contrastText",
                                objectFit: "contain",
                                display: { xs: "none", md: "block" },
                            }}
                        />

                        <Box>
                            {displayName && (
                                <Typography
                                    variant="subtitle2"
                                    component="span"
                                >
                                    Connect Rider
                                </Typography>
                            )}
                            <Typography
                                noWrap
                                sx={{
                                    lineHeight: 1,
                                    fontSize: 20,
                                    fontWeight: "medium",
                                    letterSpacing: ".1rem",
                                    color: "primary.contrastText",
                                }}
                            >
                                {displayName
                                    ? startCase(capitalize(displayName))
                                    : "Connect Rider"}
                            </Typography>
                        </Box>
                    </Stack>

                    {/* desktop navlinks */}
                    <Stack
                        spacing={1}
                        direction="row"
                        alignItems="center"
                        flexGrow={1}
                        display={{
                            xs: "none",
                            md: "block",
                        }}
                    >
                        {menuList.map((item, index) => {
                            // hide protected routes
                            return (
                                !item?.hidden && (
                                    <Button
                                        end // only match end of path
                                        key={index}
                                        component={NavLink}
                                        disableElevation
                                        to={item.path}
                                        color="inherit"
                                        sx={{
                                            height: "100%",
                                            minHeight: 70,
                                            borderRadius: 0,
                                            "&.active": {
                                                fontWeight: "bold",
                                                borderBottomWidth: 4,
                                                borderBottomColor:
                                                    "primary.contrastText",
                                                borderBottomStyle: "solid",
                                            },
                                        }}
                                    >
                                        {item.label}
                                    </Button>
                                )
                            );
                        })}
                    </Stack>

                    {/* user menu */}
                    <Stack spacing={1} direction="row" alignItems="center">
                        <Typography display={{ xs: "none", md: "block" }}>
                            {startCase(capitalize(user.Name))}
                        </Typography>
                        <Tooltip title="Account settings" followCursor>
                            <IconButton
                                onClick={toggleMenu}
                                size="small"
                                aria-controls={
                                    isMenuOpen ? "account-menu" : undefined
                                }
                                aria-haspopup="true"
                                aria-expanded={isMenuOpen ? "true" : undefined}
                                ref={menuAnchor}
                            >
                                <Avatar
                                    sx={{
                                        width: 32,
                                        height: 32,
                                        color: "primary.main",
                                        backgroundColor: user.Name
                                            ? "primary.contrastText"
                                            : "",
                                    }}
                                >
                                    {user.Name?.charAt(0) || "P"}
                                </Avatar>
                            </IconButton>
                        </Tooltip>

                        <Menu
                            anchorEl={menuAnchor.current}
                            id="account-menu"
                            open={isMenuOpen}
                            onClose={toggleMenu}
                            onClick={toggleMenu}
                            slotProps={{
                                paper: {
                                    elevation: 0,
                                    sx: {
                                        overflow: "visible",
                                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                        mt: 1.5,
                                        "&:before": {
                                            content: '""',
                                            display: "block",
                                            position: "absolute",
                                            top: 0,
                                            right: 13,
                                            width: 10,
                                            height: 10,
                                            bgcolor: "background.paper",
                                            transform:
                                                "translateY(-50%) rotate(45deg)",
                                            zIndex: 0,
                                        },
                                    },
                                },
                            }}
                            transformOrigin={{
                                horizontal: "right",
                                vertical: "top",
                            }}
                            anchorOrigin={{
                                horizontal: "right",
                                vertical: "bottom",
                            }}
                        >
                            {/* only visible to users */}
                            {!isManager && (
                                <MenuItem
                                    onClick={toggleMenu}
                                    component={Link}
                                    to="/profile"
                                >
                                    <ListItemIcon>
                                        <AccountCircle />
                                    </ListItemIcon>
                                    <ListItemText>Profile</ListItemText>
                                </MenuItem>
                            )}

                            <MenuItem
                                onClick={toggleMenu}
                                component="a"
                                href="mailto:support@passiotech.com"
                                target="_blank"
                            >
                                <ListItemIcon>
                                    <ContactSupport />
                                </ListItemIcon>
                                <ListItemText>Help</ListItemText>
                            </MenuItem>

                            <MenuItem
                                onClick={toggleMenu}
                                component="a"
                                href={preferences?.OwnerLink}
                                target="_blank"
                            >
                                <ListItemIcon>
                                    <Launch />
                                </ListItemIcon>
                                <ListItemText>{displayName}</ListItemText>
                            </MenuItem>

                            <MenuItem
                                onClick={toggleMenu}
                                component="a"
                                href="https://passiotech.com"
                                target="_blank"
                            >
                                <ListItemIcon>
                                    <Launch />
                                </ListItemIcon>
                                <ListItemText>Passio</ListItemText>
                            </MenuItem>

                            {/*
                             * @see https://github.com/mui/material-ui/issues/16181
                             * Rendering menu items in fragments is not supported
                             */}
                            {!isNavigator &&
                                canDebug && [
                                    <MenuItem
                                        key="reset config"
                                        onClick={() =>
                                            handleCacheReset("config")
                                        }
                                    >
                                        <ListItemIcon>
                                            <RestartAlt />
                                        </ListItemIcon>
                                        <ListItemText>
                                            Reset Config
                                        </ListItemText>
                                    </MenuItem>,

                                    <MenuItem
                                        key="reset geozones"
                                        onClick={() =>
                                            handleCacheReset("geozones")
                                        }
                                    >
                                        <ListItemIcon>
                                            <RestartAlt />
                                        </ListItemIcon>
                                        <ListItemText>
                                            Reset Geozones
                                        </ListItemText>
                                    </MenuItem>,
                                ]}

                            <MenuItem
                                onClick={() => {
                                    setShowShortcuts(true);
                                }}
                            >
                                <ListItemIcon>
                                    <KeyboardCommandKey />
                                </ListItemIcon>
                                <ListItemText>Shortcuts</ListItemText>
                            </MenuItem>

                            <Divider />
                            <MenuItem
                                onClick={(evt) => {
                                    evt.stopPropagation();
                                    handleLogout();
                                }}
                            >
                                <ListItemIcon>
                                    {isLoading ? (
                                        <CircularProgress size={25} />
                                    ) : (
                                        <Logout />
                                    )}
                                </ListItemIcon>
                                <ListItemText> Logout</ListItemText>
                            </MenuItem>
                        </Menu>
                    </Stack>
                </Toolbar>
            </Container>
            <ShortcutsDialog
                open={showShortcuts}
                onClose={() => setShowShortcuts(false)}
            />
        </AppBar>
    );
};

export default Navbar;
