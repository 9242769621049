import {
    Vehicle,
    PassioBus,
    PassioRun,
    Fleetmanager,
    TripHistory,
    OnDemandTrip,
    TripReservationType,
    OnDemandTripActionHistory,
    PassioBusLocation,
    GPS,
    FormattedGPS,
} from "../types";
import { pick } from "lodash";
import { statusFields } from "src/utils/constants";
import {
    defaultVehicle,
    defaultFleetManager,
    defaultGPS,
    defaultFormattedGPS,
} from "src/utils/defaultData";
import { StatusTypes } from "../types";
import { TripScheduleStatus } from "src/types";
const omitFields = [
    "actualDropOffTime",
    "actualPickUpTime",
    "pickUpTime",
    "dropOffTime",
];

export function getTripStatusByActionId(id: number): StatusTypes {
    switch (id) {
        case TripScheduleStatus.Scheduled:
            return StatusTypes.Scheduled;
        case TripScheduleStatus.AcceptedTripRequest:
            return StatusTypes.Accepted;
        case TripScheduleStatus.WaitingForApproval:
            return StatusTypes.Requested;
        case TripScheduleStatus.Cancelled:
            return StatusTypes.Cancelled;
        case TripScheduleStatus.DropOffPerformed:
            return StatusTypes.Completed;
        default:
            return StatusTypes.Pending;
    }
}

export function sortTripHistory(trips: TripHistory[]) {
    return [...trips].sort((a, b) => (a.tripDate > b.tripDate ? -1 : 1));
}

export function sortOnDemandTripActions(trips: OnDemandTripActionHistory[]) {
    return [...trips].sort((a, b) => (a.created > b.created ? -1 : 1));
}

export function formatTripHistoryResponse(
    data: OnDemandTrip[] = []
): TripHistory[] {
    if (!data.length) return [];

    const trips = data.reduce((acc: TripHistory[], item) => {
        /**
         * Use updated trip statuses from onDemandTripActionHistory to assign the correct
         * reservationType for each trip
         * Sort first to get the correct entry
         */
        const sortedLog = sortOnDemandTripActions(
            item.onDemandTripActionHistory
        );

        const tripLog = sortedLog?.[0];
        const actionId = tripLog
            ? parseInt(tripLog.onDemandActionId)
            : TripScheduleStatus.WaitingForApproval;
        const status = item.actualDropOffTime
            ? StatusTypes.Completed
            : item.actualPickUpTime
            ? StatusTypes.InProgress
            : tripLog && tripLog.onDemandActionId
            ? getTripStatusByActionId(parseInt(tripLog.onDemandActionId))
            : StatusTypes.Pending;
        const reservationType =
            actionId === TripScheduleStatus.WaitingForApproval
                ? TripReservationType.Request
                : TripReservationType.Trip;

        const trip = {
            ...pick(item, omitFields),
            ...statusFields,
            tripDate: item.requestTime,
            appointmentTime: item.requestTime,
            actualDropOffTime: item.actualPickUpTime || "",
            actualPickUpTime: item.actualDropOffTime || "",
            databaseId: item.id,
            client: null,
            nextTripDate: null,
            pickUpPlace: JSON.parse(item.pickUpPlace),
            dropOffPlace: JSON.parse(item.dropOffPlace),
            pickUpTime: item.pickUpTime,
            dropOffTime: item.dropOffTime,
            recurrenceInfo: {},
            returnTripInfo: "",
            secondLine: status,
            tripStatus: status,
            reservationType,
            onDemandTripActionHistoryId: tripLog?.id || "",
            onDemandActionId: actionId,
            tripUuid: item.tripUuid,
            runId: item.runId ? parseInt(item.runId) : undefined,
            tripRating: item.tripRating ? parseInt(item.tripRating) : 0,
        };

        acc.push(trip);
        return acc;
    }, []);

    return trips;
}

export function formatVehicles(data: PassioBus[] = []): Vehicle[] {
    if (!data.length) return [];

    const yearRegex = /^(19|20)[\d]{2,2}$/;
    const vehicles = data.reduce((acc: Vehicle[], bus) => {
        const year =
            bus.yearOfManufacture && yearRegex.test(bus.yearOfManufacture)
                ? bus.yearOfManufacture
                : bus.yearOfRebuild && yearRegex.test(bus.yearOfRebuild)
                ? bus.yearOfRebuild
                : "";

        const vehicle = {
            ...defaultVehicle,
            customID: parseInt(bus.id),
            databaseID: parseInt(bus.id),
            currentDriverId: parseInt(bus.driverId),
            defaultDriverId: parseInt(bus.driverId),
            year: year || "",
            model: bus.vehicleType || bus.model || "",
            make: bus.manufacturer || "",
            vin: bus.vin || "",
            name: bus.name,
            totalCap: bus.totalCap ? bus.totalCap : 0,
            seatedCap: bus.seatedCap ? bus.seatedCap : 0,
        };

        acc.push(vehicle);
        return acc;
    }, []);

    return vehicles;
}

export function formatRuns(data: PassioRun[] = []): Fleetmanager[] {
    if (!data.length) return [];

    const fleetManagers = data.reduce((acc: Fleetmanager[], item) => {
        const run = {
            ...defaultFleetManager,
            fleetmanagerID: parseInt(item.id),
            routeId: parseInt(item.routeId),
            driverID: parseInt(item.driverId),
            vehicleID: parseInt(item.busId),
            startTime: item.startDateTime || "",
            endTime: item.endDateTime || "",
            gps: null,
        };

        acc.push(run);
        return acc;
    }, []);

    return fleetManagers;
}

export function formatGPS(data: PassioBusLocation): GPS {
    const gps = {
        ...defaultGPS,
        radioID: data.driver,
        dateStamp: data.datetime,
        lat: data.latitude,
        lng: data.longitude,
        speed: data.speed,
    };

    return gps;
}

export function formatBusLocation(data: PassioBusLocation): FormattedGPS {
    const gps = {
        ...defaultFormattedGPS,
        speed: data.speed,
        busId: data.busId,
        driver: data.driver,
        vehicle: data.bus,
        route: data.route,
        updated: data.created,
        lat: data.latitude,
        lng: data.longitude,
    };
    return gps;
}
