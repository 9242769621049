// shared helper functions

import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import type { Program } from "src/modules/auth/types";
import { FormikErrors } from "formik";
import { enqueueSnackbar } from "notistack";
import { PassioService, ResponseStatus } from "src/types";
import { statusFields } from "./constants";
import { defaultCoords, defaultParsedAddress } from "./defaultData";
import { ParsedAddress } from "src/components/forms/types";
import { geocodeByAddress } from "react-places-autocomplete";

/**
 * Type predicate to narrow an unknown error to `FetchBaseQueryError`
 * @see https://redux-toolkit.js.org/rtk-query/usage-with-typescript#type-safe-error-handling
 */
export function isFetchBaseQueryError(
    error: unknown
): error is FetchBaseQueryError {
    return (
        typeof error === "object" &&
        error != null &&
        "status" in error &&
        "data" in error
    );
}

/**
 * Type predicate to narrow an unknown error to an object with a string 'message' property
 */
export function isErrorWithMessage(
    error: unknown
): error is { message: string } {
    return (
        typeof error === "object" &&
        error != null &&
        "message" in error &&
        typeof (error as any).message === "string"
    );
}

export function isPrefixedUrl(url = "") {
    return url.includes("http");
}

export function filterFundingSources(programs: Program[] = []) {
    return programs
        ?.filter((program) => {
            return (
                (!program.membershipIsRequired || program.membershipID) &&
                !program.archived &&
                !program.deleted
            );
        })
        .map((program) => program.programName);
}

export function dateFromJson(json: any) {
    var a = json.split(/[^0-9]/);
    var d = new Date(a[0], a[1] - 1, a[2]);
    return d;
}

export function dateAndTimeFromJson(json: any) {
    var a = json.split(/[^0-9]/);
    var d = new Date(a[0], a[1] - 1, a[2], a[3], a[4], a[5]);
    return d;
}

export function stringToJSON(data: string) {
    return JSON.parse(data);
}

export function JSONToString(data: object) {
    return JSON.stringify(data);
}

export function getEpochTime(date: Date | string) {
    const parsedDate = typeof date === "string" ? new Date(date) : date;
    return parsedDate && parsedDate.getTime() / 1000;
}

export function alertIfMissingFields(errors: FormikErrors<any>) {
    if (Object.keys(errors).length) {
        enqueueSnackbar(
            "Oops, you missed a few fields, please check and fill them",
            {
                variant: "error",
            }
        );
    }
}

export async function getGeolocation() {
    const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
    });
    return position as GeolocationPosition;
}

export function getServiceBrandName(service: PassioService) {
    return service === PassioService.navigator ? "Navigator" : "ParaPlan";
}

export function getService() {
    const searchParams = new URLSearchParams(window.location.search);
    const param = searchParams.get("service") as keyof typeof PassioService;

    if (param && PassioService[param]) {
        return PassioService[param];
    }

    return PassioService.paraplan;
}

export function isValidCoord(coords: google.maps.LatLngLiteral | any): boolean {
    let isValid = false;
    if (
        coords &&
        coords.lat &&
        coords.lat !== 0 &&
        coords.lng &&
        coords.lng !== 0
    ) {
        isValid = true;
    }

    return isValid;
}

// create a list response
export function formatListResponse(response: any[] = []) {
    return {
        ...statusFields,
        list: response,
    };
}

export function formatEntityResponse(response: any = {}) {
    return {
        ...statusFields,
        entity: response,
    };
}

export function isParaplanError(data: any) {
    return data?.success ? false : true;
}

export function isNavigatorError(data: any) {
    return data?.err ? true : false;
}

export function createQueryError(message = ""): FetchBaseQueryError {
    return {
        status: "CUSTOM_ERROR",
        data: message,
        error: message,
    };
}

// calculate travel duration using googlemaps
export async function getTravelDuration(
    origin: google.maps.LatLngLiteral = defaultCoords,
    destination: google.maps.LatLngLiteral = defaultCoords
) {
    let duration = 0;

    if (
        !isValidCoord(origin) ||
        !isValidCoord(destination) ||
        !window?.google?.maps ||
        !window?.google?.maps.DirectionsService
    ) {
        return duration;
    }

    try {
        const directionsService = new google.maps.DirectionsService();
        const options = {
            origin,
            destination,
            travelMode: google.maps.TravelMode.DRIVING,
            provideRouteAlternatives: false,
        };

        await directionsService.route(
            options,
            (
                result: google.maps.DirectionsResult | null,
                status: google.maps.DirectionsStatus
            ) => {
                if (status === "OK") {
                    if (result) {
                        duration =
                            result.routes[0].legs[0]?.duration?.value || 0;
                    }
                }
            }
        );

        return duration;
    } catch {
        return duration;
    }
}

export function validateParaplanStatus(
    response: any,
    result: ResponseStatus & any
) {
    return response.status === 200 && result?.success;
}

export function validateNavigatorStatus(response: any, result: any) {
    return response.status === 200 && !result?.err;
}

export function getParsedAddress(
    geocoded: google.maps.GeocoderResult,
    address = ""
): ParsedAddress {
    if (!geocoded) return defaultParsedAddress;

    const name = (address ? address : geocoded.formatted_address) || "";

    const parsed = {
        ...defaultParsedAddress,
        description: name,
        name: name ? name.split(",")[0] : "",
        latitude: geocoded.geometry.location.lat() || 0,
        longitude: geocoded.geometry.location.lng() || 0,
    };

    for (const item of geocoded.address_components) {
        if (item.types.length) {
            const addrType = item.types[0];
            switch (addrType) {
                case "street_number":
                    parsed.address1 = item.short_name;
                    break;
                case "route":
                    parsed.street = item.short_name;
                    break;
                case "locality":
                    parsed.city = item.short_name;
                    break;
                case "administrative_area_level_1":
                    parsed.state = item.short_name;
                    break;
                case "postal_code":
                    parsed.zip = item.short_name;
                    break;
            }
        }
    }

    // add street to address1 or fallback to address
    parsed.address1 =
        parsed.address1 && parsed.street
            ? `${parsed.address1} ${parsed.street}`
            : name;

    return parsed;
}

export async function getGeoCodedAddress(
    address: string = ""
): Promise<ParsedAddress> {
    const parsed = {
        ...defaultParsedAddress,
        description: address,
    };

    if (!address) return parsed;

    const geocoded: google.maps.GeocoderResult[] = await geocodeByAddress(
        address
    );

    if (!geocoded) return parsed;
    return getParsedAddress(geocoded[0], address);
}
