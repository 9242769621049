import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { googleMapsApiKey, googleMapLibraries } from "src/utils/constants";
import Splashscreen from "./Splashscreen";
import Error from "./Error";

interface Props {
    children: React.ReactNode;
}

// load google map services, used for calculations in autoschedule and other map related operations
// TODO: consider migrating to https://visgl.github.io/react-google-maps/
const MapWrapper = ({ children }: Props) => {
    const render = (status: Status): React.ReactElement => {
        if (status === Status.FAILURE)
            return (
                <Error
                    onClick={() => window && window.location.reload()}
                    description="Sorry we couldn't load your location selector. Try refreshing
            the page"
                />
            );
        return <Splashscreen description="Loading Map Services" />;
    };

    return (
        <Wrapper
            apiKey={googleMapsApiKey ?? ""}
            render={render}
            libraries={googleMapLibraries} // ensure places load and  match libraries loaded by useJsApiLoader in the GoogleMap component
        >
            {children}
        </Wrapper>
    );
};

export default MapWrapper;
