import { useCallback, useEffect } from "react";
import { Box, Dialog, DialogContent } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Formik, Form } from "formik";
import { useAppSelector, useAppDispatch } from "src/hooks";
import {
    authAPI,
    authActions,
    authSelector,
} from "src/modules/auth/redux/authSlice";
import { loginSchema } from "../schema";
import { FormInput, FormCheckbox, Splashscreen } from "src/components";
import { generateLoginQuery } from "../helpers";
import { Credentials } from "../types";
import { PassioService } from "src/types";
import { enqueueSnackbar } from "notistack";
import logoImg from "src/images/logo-full-green.png";

const LoginModal = () => {
    const dispatch = useAppDispatch();
    const { tokenIsValid, token, user, autoLogin, savedLogin, service } =
        useAppSelector(authSelector);
    const open = !tokenIsValid && token.length > 0;
    const shouldAutoLogin =
        !tokenIsValid &&
        autoLogin &&
        savedLogin &&
        savedLogin?.email &&
        savedLogin?.password;

    const [login, { isLoading }] = authAPI.useLoginMutation();
    const [passioLogin, { isLoading: passioLoading }] =
        authAPI.usePassioLoginMutation();

    const handleRefresh = useCallback(
        async (values: Credentials, resume = false) => {
            try {
                const isNavigator = service === PassioService.navigator;
                const request  = generateLoginQuery(
                    values,
                    service,
                    resume
                );

                if (isNavigator) {
                    await passioLogin(request).unwrap();
                } else {
                    await login(request).unwrap();
                }

                // save autoLogin details
                if (values.autoLogin) {
                    dispatch(
                        authActions.save({
                            autoLogin: true,
                            savedLogin: request,
                        })
                    );
                }

                enqueueSnackbar("Session restored", {
                    variant: "success",
                });
            } catch (error) {
                enqueueSnackbar(
                    "Login failed. Please check your password/PIN",
                    {
                        variant: "error",
                    }
                );
            }
        },
        [dispatch, login, passioLogin, service]
    );

    // if user has autologin credentials saved. proceed to refresh token
    useEffect(() => {
        if (shouldAutoLogin) {
            handleRefresh(
                {
                    ...savedLogin,
                    autoLogin,
                },
                true
            );
        }
    }, [shouldAutoLogin, autoLogin, savedLogin, handleRefresh]);

    if (isLoading && shouldAutoLogin) {
        return (
            <Splashscreen
                description="Auto login enabled, we are restoring your session"
                sx={{
                    position: "fixed",
                    inset: 0,
                    zIndex: 9999,
                }}
            />
        );
    }

    return (
        <Dialog open={open} fullWidth maxWidth="xs">
            <Formik
                initialValues={{
                    email: user.Email,
                    password: "",
                    autoLogin: false,
                }}
                validationSchema={loginSchema}
                onSubmit={(values) => handleRefresh(values)}
            >
                <DialogContent>
                    <Box
                        component="img"
                        alt="logo"
                        src={logoImg}
                        sx={{
                            mx: "auto",
                            height: 100,
                            width: 225,
                            display: "block",
                            objectFit: "contain",
                        }}
                    />

                    <Box component={Form} sx={{ mt: 1 }}>
                        <FormInput
                            fullWidth
                            name="email"
                            label="Email address"
                            margin="normal"
                        />
                        <FormInput
                            fullWidth
                            name="password"
                            label="Password or Pin"
                            type="password"
                            margin="normal"
                        />
                        <FormCheckbox label="Remember me" name="autoLogin" />
                        <LoadingButton
                            size="large"
                            type="submit"
                            fullWidth
                            variant="contained"
                            loading={isLoading}
                            sx={{ my: 2, py: 1, color: "primary.contrastText" }}
                        >
                            {isLoading || passioLoading
                                ? "Verifying account"
                                : "Sign In"}
                        </LoadingButton>
                    </Box>
                </DialogContent>
            </Formik>
        </Dialog>
    );
};

export default LoginModal;
