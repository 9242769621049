import { useState, FormEvent, Fragment } from "react";
import {
    Card,
    Stack,
    Dialog,
    Button,
    MenuItem,
    FormLabel,
    DialogTitle,
    FormControl,
    DialogContent,
    DialogActions,
    TextField,
    List,
    ListItemText,
    Typography,
    ListItem,
    IconButton,
    FormControlLabel,
    Checkbox,
    Tooltip,
} from "@mui/material";
import { FieldArray, useFormikContext } from "formik";
import { Program } from "src/modules/auth/types";
import { ProfileFormData } from "../types";
import { AddCircle, Delete } from "@mui/icons-material";
import { useAppSelector } from "src/hooks";
import { PassioService } from "src/types";
import { authSelector } from "src/modules/auth/redux/authSlice";
import { lowerCase } from "lodash";
interface Props {
    name: string;
    programs: Program[];
}

const Programs = ({ name = "programs", programs = [] }: Props) => {
    const [open, setOpen] = useState(false);
    const { values, setFieldValue } = useFormikContext<ProfileFormData>();
    const { service } = useAppSelector(authSelector);
    const isNavigator = service === PassioService.navigator;
    const label = isNavigator ? "Service" : "Program";

    function getProgramById(id: string) {
        return programs.find((item) => item.databaseID === id);
    }

    return (
        <Card variant="elevation" sx={{ p: 1.5 }}>
            <FormControl
                component="fieldset"
                sx={{ mb: 4, display: "flex", gap: 2 }}
            >
                <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <FormLabel
                        component="legend"
                        sx={{ fontSize: 20, color: "primary.main" }}
                    >
                        {label}s
                    </FormLabel>
                    <Button
                        startIcon={<AddCircle />}
                        variant="contained"
                        onClick={() => setOpen(true)}
                        disabled={values?.programs?.length === programs.length}
                    >
                        Add {label}
                    </Button>
                </Stack>

                <FieldArray name={name}>
                    {(helpers) => (
                        <Fragment>
                            {!values.programs ||
                            (values.programs && values.programs?.length < 1) ? (
                                <Typography component="h1" textAlign="center">
                                    No available {lowerCase(label)}s, please
                                    click to add
                                </Typography>
                            ) : (
                                <List>
                                    {values?.programs?.map(
                                        (
                                            { databaseID, programName },
                                            index
                                        ) => {
                                            const selected =
                                                values.defaultServiceId ===
                                                databaseID;
                                            return (
                                                <ListItem
                                                    key={index}
                                                    disableGutters
                                                    divider
                                                    sx={{ px: 1, py: 2 }}
                                                    secondaryAction={
                                                        <Stack
                                                            spacing={2}
                                                            direction="row"
                                                            alignItems="center"
                                                        >
                                                            {/* membership Id only editable by dispatchers */}
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={
                                                                            selected
                                                                        }
                                                                        onChange={(
                                                                            evt
                                                                        ) => {
                                                                            setFieldValue(
                                                                                "defaultServiceId",
                                                                                databaseID
                                                                            );
                                                                        }}
                                                                    />
                                                                }
                                                                label={`Set as default ${lowerCase(
                                                                    label
                                                                )}`}
                                                            />

                                                            <Tooltip
                                                                title={`Remove ${lowerCase(
                                                                    label
                                                                )}`}
                                                                followCursor
                                                            >
                                                                <IconButton
                                                                    edge="end"
                                                                    color="error"
                                                                    aria-label="delete"
                                                                    disabled={
                                                                        selected
                                                                    }
                                                                    onClick={() =>
                                                                        helpers.remove(
                                                                            index
                                                                        )
                                                                    }
                                                                >
                                                                    <Delete />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Stack>
                                                    }
                                                >
                                                    <ListItemText
                                                        primary={programName}
                                                    />
                                                </ListItem>
                                            );
                                        }
                                    )}
                                </List>
                            )}
                            <Dialog
                                open={open}
                                onClose={() => setOpen(false)}
                                maxWidth="sm"
                                fullWidth
                                PaperProps={{
                                    component: "form",
                                    onSubmit: (
                                        event: FormEvent<HTMLFormElement>
                                    ) => {
                                        event.preventDefault();
                                        event.stopPropagation();
                                        const formData = new FormData(
                                            event.currentTarget
                                        );
                                        const formJson = Object.fromEntries(
                                            (formData as any).entries()
                                        );

                                        const program = getProgramById(
                                            formJson.programId
                                        );

                                        // prevent duplicates
                                        helpers.push(program);
                                        setOpen(false);
                                    },
                                }}
                            >
                                <DialogTitle>Add {label}</DialogTitle>
                                <DialogContent>
                                    <TextField
                                        select
                                        fullWidth
                                        label={label}
                                        name="programId"
                                        variant="standard"
                                    >
                                        {programs?.map((program, index) => {
                                            // prevent duplicates
                                            const selected =
                                                values.programs?.some(
                                                    (item) =>
                                                        item.databaseID ===
                                                        program.databaseID
                                                );
                                            return (
                                                !selected && (
                                                    <MenuItem
                                                        key={index}
                                                        value={
                                                            program.databaseID
                                                        }
                                                    >
                                                        {program.programName}
                                                    </MenuItem>
                                                )
                                            );
                                        })}
                                    </TextField>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => setOpen(false)}>
                                        Cancel
                                    </Button>
                                    <Button type="submit">Add {label}</Button>
                                </DialogActions>
                            </Dialog>
                        </Fragment>
                    )}
                </FieldArray>
            </FormControl>
        </Card>
    );
};

export default Programs;
