import { useState, useEffect, useCallback } from "react";
import {
    Grid,
    Stack,
    Button,
    MenuItem,
    FormControl,
    FormLabel,
    Container,
    Typography,
    useTheme,
    useMediaQuery,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
    FormInput,
    FormDatePicker,
    Splashscreen,
    FormDateTimePicker,
} from "src/components";
import {
    AccountCircleOutlined,
    DepartureBoardOutlined,
    DirectionsBusOutlined,
} from "@mui/icons-material";
import { Formik, Form, FormikHelpers } from "formik";
import {
    focusElement,
    initTripRequest,
    formatParaplanTrip,
    formatNavigatorTrip,
    OUT_OF_ZONE_ERROR,
    SAME_ADDRESS_ERROR,
    validateByCalendar,
    ReturnTripTypes,
    formatNavigatorTripTimes,
    getCurrentPickupLocation,
} from "./helpers";

import { TripRequestSchema } from "./schema";
import { useAppDispatch, useAppSelector, useSelectedClient } from "src/hooks";
import { authSelector } from "src/modules/auth/redux/authSlice";
import { enqueueSnackbar } from "notistack";
import { tripsAPI } from "../../redux/tripSlice";
import { useTitle } from "react-use";
import { alertIfMissingFields, isErrorWithMessage } from "src/utils/helpers";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { TripRequestFormValues } from "./types";
import {
    GetDriverAndVehicleDetailsResponse,
    OnDemandTripRequest,
    StatusTypes,
} from "../../types";
import { TimeTypes, PassioService, TripScheduleStatus } from "src/types";
import { isEqual } from "lodash";
import { tinykeys } from "tinykeys";
import { getProperty } from "dot-prop";
import {
    formatTripApprovedEmail,
    formatTripNotAutoApprovedEmail,
} from "src/utils/email";
import LocationSelector from "./components/LocationSelector";
import MoreInfoSelector from "./components/MoreInfoSelector";
import ShortcutsDialog from "src/components/ShortcutsDialog";
import moment from "moment";
import ReturnTripOptions from "./components/ReturnTripOptions";
import RatingsDialog from "../components/RatingsDialog";
import {
    defaultAccessibility,
    defaultParsedAddress,
} from "src/utils/defaultData";
import {
    getNotificationLSKey,
    notificationCheckKeys,
} from "../details/helpers";
import { formatTripApprovedSMS } from "src/utils/sms";

const TripRequest = () => {
    useTitle("Request a ride");
    const { id = "" } = useParams(); // managers can request rides for clients
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();

    const [showInfo, setShowInfo] = useState(false);
    const [showShortcuts, setShowShortcuts] = useState(false);
    const [editTime, setEditTime] = useState(false);
    const [initValues, setInitValues] = useState<TripRequestFormValues | any>(
        {}
    );

    const [processing, setProcessing] = useState({
        active: true,
        message: "Initializing Trip",
    });

    const [rating, setRating] = useState({
        id: "",
        open: false,
        driver: "",
    });

    const [tripId, setTripId] = useState("");

    const [requestTrip, { isLoading }] = tripsAPI.useRequestTripMutation();
    const [sendEmail, { isLoading: isSendingEmail }] =
        tripsAPI.useSendEmailMutation();
    const [sendSMS, { isLoading: isSendingSMS }] =
        tripsAPI.useSendSMSMutation();
    const [tdbHandler, { isLoading: isRequesting }] = tripsAPI.useTdbMutation();

    const {
        user,
        config,
        service,
        programs,
        isManager,
        availableFundingSources,
    } = useAppSelector(authSelector);

    const theme = useTheme();
    const isMobileDevice = useMediaQuery(theme.breakpoints.down("md"));
    const preferences = config?.ConnectPreferences;
    const canRequestAnyPULocation = preferences?.CanRequestAnyPULocation;
    const client = useSelectedClient(id);
    const isNavigator = service === PassioService.navigator;
    const canAutoScheduleTrips =
        isNavigator &&
        config?.ConnectPreferences?.AutoScheduleHierarchy
            ?.SystemShouldAutoSchedule;

    const [isOnDemand, setIsOnDemand] = useState(isNavigator && isMobileDevice);
    const loading = isLoading || isRequesting || isSendingEmail || isSendingSMS;

    // initialize trip
    useEffect(() => {
        const baseClient = { ...client };
        (async () => {
            if (isNavigator) {
                try {
                    setProcessing({
                        active: true,
                        message: "Please let us know your current location",
                    });

                    const address = await getCurrentPickupLocation();
                    if (address) baseClient.address = address;
                } catch {
                    enqueueSnackbar(
                        "We couldn't get your current position. Defaults have been used instead",
                        { variant: "info" }
                    );
                }

                // imported riders may not have an address or accessibility
                if (!baseClient.address)
                    baseClient.address = defaultParsedAddress;
                if (!baseClient.accessibility)
                    baseClient.accessibility = defaultAccessibility;
            }

            let values = await initTripRequest(
                baseClient,
                user?.Email,
                canRequestAnyPULocation,
                isNavigator
            );

            // default to first available funding source if trip program is not set
            if (!values.tripProgram) {
                if (availableFundingSources?.length) {
                    values.tripProgram = availableFundingSources[0];
                } else if (isNavigator && programs.length) {
                    // for navigator select the first trip program
                    values.tripProgram = programs?.[0]?.programName;
                }
            }

            // if a trip duplication was requested
            if (location.state?.duplicate) {
                setInitValues({ ...values, ...location.state.duplicate });
            } else {
                setInitValues(values);
            }
            setProcessing({ active: false, message: "" });
        })();
    }, [
        client,
        programs,
        user?.Email,
        isNavigator,
        location.state,
        availableFundingSources,
        canRequestAnyPULocation,
    ]);

    // register keyboard shortcuts
    useEffect(() => {
        let unsubscribe = tinykeys(window, {
            // focus pick up address
            "$mod+Alt+P": (evt) => {
                evt.preventDefault();
                focusElement("pickUpPlace");
            },
            // focus drop off address
            "$mod+Alt+O": (evt) => {
                evt.preventDefault();
                focusElement("dropOffPlace");
            },
            // focus time type
            "$mod+Alt+I": (evt) => {
                evt.preventDefault();
                focusElement("timeType");
            },
            // show shortcuts dialog
            "$mod+Alt+H": (evt) => {
                evt.preventDefault();
                setShowShortcuts(true);
            },
        });
        return () => {
            unsubscribe();
        };
    }, []);

    function handleNavigate(tripId = "") {
        if (canAutoScheduleTrips) {
            return navigate(`/trips/schedule/${tripId}`);
        }
        // if manager requesting trip go back to riders, reset state incase a trip was duplicated
        if (id && isManager) {
            return navigate(`/trips/${tripId}?status=live&rider=${id}`, {
                state: {},
            });
        }
        navigate(`/trips/${tripId}?status=live`, { state: {} });
    }

    const fetchDriverAndVehicleDetails = useCallback(
        async (runId = "") => {
            let response: GetDriverAndVehicleDetailsResponse = {
                driverName: "",
                vehicle: "",
            };

            if (runId) {
                const { data: run } = await dispatch(
                    tripsAPI.endpoints.getRun.initiate(runId)
                );
                if (!run) return response;
                const { data: driverDetails } = await dispatch(
                    tripsAPI.endpoints.getDriverAndVehicleDetails.initiate({
                        busId: run.busId,
                        driverId: run.driverId,
                    })
                );
                if (driverDetails) response = driverDetails;
            }
            return response;
        },
        [dispatch]
    );

    const getLastUnratedTrip = useCallback(async () => {
        try {
            const { data = [] } = await dispatch(
                tripsAPI.endpoints.getTrips.initiate(client.id)
            );

            // get the last unrated completed trip
            const trip = data.find(
                (trip) =>
                    trip.tripStatus === StatusTypes.Completed &&
                    trip.tripRating === 0
            );

            if (trip && trip.runId) {
                const details = await fetchDriverAndVehicleDetails(
                    String(trip.runId)
                );
                return {
                    id: trip.databaseId,
                    driver: details.driverName,
                };
            }
        } catch {}
    }, [client.id, dispatch, fetchDriverAndVehicleDetails]);

    async function handlePassioTripRequest(
        trip: OnDemandTripRequest,
        values: TripRequestFormValues
    ) {
        let tripId = "";

        const request = {
            path: "add",
            body: {
                type: "onDemandTrip",
                userId: client.agencyId,
                riderId: client.id,
                ...trip,
            },
        };

        const response = await tdbHandler(request).unwrap();
        tripId = response.id;

        /*
         * Update onDemandTripActionHistory to create rider experience entry
         * If agency has auto approve, accept trip otherwise default to pending
         */

        const status = preferences.AutoApproveRequests
            ? TripScheduleStatus.AcceptedTripRequest
            : TripScheduleStatus.WaitingForApproval;

        const logRequest = {
            path: "add",
            body: {
                type: "onDemandTripActionHistory",
                userId: client.agencyId,
                riderId: client.id,
                onDemandTripId: tripId,
                onDemandActionId: status,
                latitude: 0,
                longitude: 0,
            },
        };
        await tdbHandler(logRequest).unwrap();

        // send notification to dispatcher if auto-approve is off

        try {
            if (!preferences.AutoApproveRequests) {
                const email = formatTripNotAutoApprovedEmail(
                    values,
                    config.DispatcherEmail,
                    trip.nextOnDemandTripId || ""
                );
                await sendEmail(email).unwrap();
            } else {
                const email = formatTripApprovedEmail({
                    pickUpAddress: values.pickUpPlace.address1,
                    dropOffAddress: values.dropOffPlace.address1,
                    ...trip,
                });

                await sendEmail(email).unwrap();
                const notifications = JSON.stringify({
                    ...notificationCheckKeys,
                    tripApproved: true,
                });
                localStorage.setItem(
                    getNotificationLSKey(tripId),
                    notifications
                );
                const message = formatTripApprovedSMS({
                    ...trip,
                    dropOffAddress: values.dropOffPlace.address1,
                });
                await sendSMS({ to: client.phone, message }).unwrap();
                localStorage.setItem(
                    getNotificationLSKey(tripId, "sms"),
                    notifications
                );
            }
        } catch {}

        return tripId;
    }

    async function handleRequest(
        values: TripRequestFormValues,
        helpers: FormikHelpers<TripRequestFormValues>
    ) {
        try {
            // ensure Pickup and dropOff locations are different
            if (
                isEqual(
                    values.pickUpPlace.description,
                    values.dropOffPlace.description
                )
            ) {
                helpers.setFieldError(
                    "dropOffPlace.description",
                    SAME_ADDRESS_ERROR
                );
                enqueueSnackbar(SAME_ADDRESS_ERROR, { variant: "error" });
                return;
            }

            // revalidate trip times, time may expire while hidden behind the now button
            const timeError = await validateByCalendar(
                client,
                values.apptTime,
                values.tripProgram
            );

            if (timeError) {
                const message =
                    "Sorry your trip time expired, Please select a valid time";
                helpers.setFieldError("apptTime", message);
                enqueueSnackbar(message, { variant: "error" });
                setEditTime(true);
                return;
            }

            /**
             * Navigator trip request fields
             * https://github.com/Passio/passio/blob/master/tdb/type/user/goRideRequest/index.json
             */

            let tripId = "";
            const message =
                "Trip Requested successfully. A confirmation email will be sent shortly";

            if (isNavigator) {
                const service = programs.find(
                    (program) => program.programName === values.tripProgram
                );

                if (!service) {
                    throw new Error("Invalid service selected");
                }

                let trip = await formatNavigatorTrip(
                    values,
                    client,
                    service,
                    canAutoScheduleTrips
                );

                //  request return trip first then add its id to the request
                if (values.returnType === ReturnTripTypes.specificTime) {
                    const returnTripValues = {
                        ...values,
                        apptTime: values.returnTime,
                    };

                    const times = await formatNavigatorTripTimes(
                        returnTripValues,
                        client,
                        service
                    );

                    const returnTrip = {
                        ...trip,
                        ...times,
                        pickUpPlace: trip.dropOffPlace,
                        dropOffPlace: trip.pickUpPlace,
                    };

                    trip.nextOnDemandTripId = await handlePassioTripRequest(
                        returnTrip,
                        values
                    );
                }

                tripId = await handlePassioTripRequest(trip, values);

                // check if last successful trip was rated
                const lastUnrated = await getLastUnratedTrip();
                if (lastUnrated) {
                    // stash trip id to navigate to trip details incase ratings need to be done
                    setTripId(tripId);
                    setRating({ ...lastUnrated, open: true });
                    enqueueSnackbar(message, { variant: "success" });
                    return;
                }
            } else {
                const trip = formatParaplanTrip(values, user);
                const response = await requestTrip(trip).unwrap();
                tripId = response.AddedTrips[0];
            }
            enqueueSnackbar(message, { variant: "success" });
            handleNavigate(tripId);
        } catch (error) {
            const message = isErrorWithMessage(error)
                ? error.message
                : "Trip request was unsuccessfull, please try again";
            enqueueSnackbar(message, { variant: "error" });
        }
    }

    // initializing trip could take a while
    if (processing.active) {
        return <Splashscreen description={processing.message} />;
    }

    return (
        <Container maxWidth="xl" sx={{ p: 2 }}>
            <Formik
                initialValues={initValues}
                validationSchema={TripRequestSchema}
                onSubmit={handleRequest}
            >
                {({ values, errors, setFieldValue, isValidating }) => {
                    /**
                     *  Effectively disallow form submission if addresses are similar or out of zone
                     *  without showing a frustrating disabled button
                     */
                    const pickupAddressError = getProperty(
                        errors.pickUpPlace,
                        "description"
                    );
                    const dropOffAddressError = getProperty(
                        errors.dropOffPlace,
                        "description"
                    );
                    const shouldNotSubmit =
                        isValidating ||
                        pickupAddressError === OUT_OF_ZONE_ERROR ||
                        dropOffAddressError === OUT_OF_ZONE_ERROR ||
                        dropOffAddressError === SAME_ADDRESS_ERROR ||
                        errors.apptTime; // trip time errors

                    const disablePast = moment(values.apptTime).isBefore(
                        new Date()
                    );
                    return (
                        <Form>
                            {/* Header */}
                            <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                                justifyContent={{
                                    xs: "center",
                                    md: "space-between",
                                }}
                                my={{ xs: 1, md: 4 }}
                            >
                                <Stack
                                    direction="row"
                                    spacing={1}
                                    alignItems="center"
                                >
                                    <DirectionsBusOutlined fontSize="large" />
                                    <Typography variant="h4" component="h1">
                                        Request A Ride
                                    </Typography>
                                </Stack>

                                {id && isManager && (
                                    <Stack
                                        direction="row"
                                        spacing={1}
                                        alignItems="center"
                                    >
                                        <AccountCircleOutlined fontSize="large" />
                                        <Typography
                                            variant="h5"
                                            component="span"
                                        >
                                            {values.clientFirstName}{" "}
                                            {values.clientLastName}
                                        </Typography>
                                        <Button
                                            variant="contained"
                                            sx={{ ml: 2 }}
                                            onClick={() =>
                                                setShowInfo(!showInfo)
                                            }
                                        >
                                            {showInfo ? "Hide" : "View"}
                                        </Button>
                                    </Stack>
                                )}
                            </Stack>

                            <Grid container>
                                {/* Rider details only visible to managers */}
                                {isManager && showInfo && (
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{
                                            p: 2,
                                            mb: 4,
                                            boxShadow: 1,
                                            borderRadius: 1,
                                        }}
                                    >
                                        <FormControl
                                            component="fieldset"
                                            sx={{
                                                mb: 4,
                                                display: "flex",
                                                gap: 2,
                                            }}
                                        >
                                            <FormLabel
                                                component="legend"
                                                sx={{
                                                    mb: 2,
                                                    fontSize: 20,
                                                    color: "primary.main",
                                                }}
                                            >
                                                Rider
                                            </FormLabel>

                                            {/* Rider Information */}
                                            <Stack
                                                spacing={2}
                                                direction={{
                                                    xs: "column",
                                                    md: "row",
                                                }}
                                            >
                                                <FormInput
                                                    name="clientFirstName"
                                                    label="First Name"
                                                    sx={{ flexGrow: 1 }}
                                                />
                                                <FormInput
                                                    name="clientLastName"
                                                    label="Last Name"
                                                    sx={{ flexGrow: 1 }}
                                                />
                                            </Stack>

                                            <Stack
                                                spacing={2}
                                                direction={{
                                                    xs: "column",
                                                    md: "row",
                                                }}
                                            >
                                                <FormInput
                                                    type="tel"
                                                    required
                                                    name="clientPhone"
                                                    label="Phone"
                                                    sx={{ flexGrow: 1 }}
                                                    autoComplete="tel"
                                                />

                                                <FormDatePicker
                                                    format="MM/dd/yyyy"
                                                    name="clientBirthdate"
                                                    label="Birthday"
                                                    sx={{ flexGrow: 1 }}
                                                />

                                                <FormInput
                                                    type="email"
                                                    name="clientEmail"
                                                    label="Rider Email"
                                                    sx={{ flexGrow: 1 }}
                                                />
                                            </Stack>

                                            {/* requester details */}
                                            <Stack spacing={2}>
                                                <FormInput
                                                    type="email"
                                                    name="requesterEmail"
                                                    label="Requester Email"
                                                    sx={{ flexGrow: 1 }}
                                                />

                                                <FormInput
                                                    multiline
                                                    minRows={5}
                                                    fullWidth
                                                    name="clientComments"
                                                    label="Comments"
                                                />
                                            </Stack>
                                        </FormControl>
                                    </Grid>
                                )}

                                {/* Location Selector  */}
                                <LocationSelector isOnDemand={isOnDemand} />

                                {/* times */}
                                {!isOnDemand && (
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{
                                            p: 2,
                                            mb: 4,
                                            boxShadow: 1,
                                            borderRadius: 1,
                                        }}
                                    >
                                        <FormControl
                                            component="fieldset"
                                            sx={{
                                                mb: 4,
                                                display: "flex",
                                                gap: 2,
                                            }}
                                        >
                                            <FormLabel
                                                component="legend"
                                                sx={{
                                                    mb: 2,
                                                    fontSize: 20,
                                                    color: "primary.main",
                                                }}
                                            >
                                                Times
                                            </FormLabel>

                                            {/* pick up times */}
                                            <Stack
                                                spacing={2}
                                                direction={{
                                                    xs: "column",
                                                    md: "row",
                                                }}
                                            >
                                                <FormInput
                                                    id="timeType"
                                                    name="timeType"
                                                    label="I want to be"
                                                    select
                                                    SelectProps={{
                                                        native: isMobileDevice,
                                                    }}
                                                    sx={{ flexGrow: 1 }}
                                                    disabled={
                                                        !preferences.CanCreateApptRequest
                                                    }
                                                >
                                                    {Object.values(
                                                        TimeTypes
                                                    ).map((item, index) =>
                                                        isMobileDevice ? (
                                                            <option
                                                                key={index}
                                                                value={item}
                                                            >
                                                                {item}
                                                            </option>
                                                        ) : (
                                                            <MenuItem
                                                                key={index}
                                                                value={item}
                                                            >
                                                                {item}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </FormInput>

                                                {editTime || errors.apptTime ? (
                                                    <FormDateTimePicker
                                                        disablePast
                                                        name="apptTime"
                                                        label={
                                                            values.timeType ===
                                                            TimeTypes.droppedOff
                                                                ? "Appointment Date and Time"
                                                                : "Trip Date and Pick Up Time"
                                                        }
                                                        sx={{ flexGrow: 1 }}
                                                        validate={async (
                                                            date
                                                        ) => {
                                                            return await validateByCalendar(
                                                                client,
                                                                date,
                                                                values.tripProgram
                                                            );
                                                        }}
                                                        onChange={async (
                                                            date
                                                        ) => {
                                                            // update return time, add two hours
                                                            await setFieldValue(
                                                                "returnTime",
                                                                moment(date)
                                                                    .add(2, "h")
                                                                    .toDate(),
                                                                false
                                                            );

                                                            await setFieldValue(
                                                                "pickUpTime",
                                                                date,
                                                                false
                                                            );

                                                            await setFieldValue(
                                                                "dropOffTime",
                                                                date,
                                                                false
                                                            );
                                                        }}
                                                    />
                                                ) : (
                                                    <Button
                                                        size="small"
                                                        variant="contained"
                                                        sx={{
                                                            flexGrow: 0.25,
                                                            py: 1.25,
                                                        }}
                                                        onClick={() =>
                                                            setEditTime(true)
                                                        }
                                                    >
                                                        Now
                                                    </Button>
                                                )}
                                            </Stack>

                                            {/* return trip */}
                                            {preferences?.CanRequestReturnTrip && (
                                                <ReturnTripOptions
                                                    client={client}
                                                    disablePast={disablePast}
                                                />
                                            )}
                                        </FormControl>
                                    </Grid>
                                )}

                                {/* more information */}
                                <MoreInfoSelector />

                                <Grid item xs={12} mt={4} mx="auto">
                                    <LoadingButton
                                        fullWidth
                                        type={
                                            // we could just disable but that tends to be frustrating
                                            shouldNotSubmit
                                                ? "button"
                                                : "submit"
                                        }
                                        variant="contained"
                                        sx={{ py: 1.5 }}
                                        loading={loading || isValidating}
                                        onClick={() =>
                                            alertIfMissingFields(errors)
                                        }
                                    >
                                        Request trip
                                    </LoadingButton>

                                    {isOnDemand && (
                                        <Button
                                            fullWidth
                                            variant={"outlined"}
                                            startIcon={
                                                <DepartureBoardOutlined />
                                            }
                                            sx={{ py: 1.5, mt: 2 }}
                                            onClick={() => {
                                                setIsOnDemand(false);
                                                window.scrollTo({
                                                    top: 0,
                                                    behavior: "smooth",
                                                });
                                            }}
                                        >
                                            Schedule A Ride
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                        </Form>
                    );
                }}
            </Formik>
            <ShortcutsDialog
                open={showShortcuts}
                onClose={() => setShowShortcuts(false)}
            />

            {isNavigator && (
                <RatingsDialog
                    id={rating.id}
                    open={rating.open}
                    rating={0}
                    onClose={() => handleNavigate(tripId)}
                    description={`We take your feedback very seriously. Please rate your previous trip with ${rating.driver}`}
                />
            )}
        </Container>
    );
};

export default TripRequest;
