import {
    Box,
    Dialog,
    Button,
    DialogProps,
    DialogActions,
    DialogContent,
    DialogContentText,
    CircularProgress,
    Rating,
} from "@mui/material";
import { Formik, Form } from "formik";
import { enqueueSnackbar } from "notistack";
import { tripsAPI } from "../../redux/tripSlice";
import { Fragment } from "react";

interface Props extends Omit<DialogProps, "onClose"> {
    id: string;
    rating: number;
    description?: string;
    onClose: () => void;
}

interface FormValues {
    id: string;
    rating: number;
}

const RatingsDialog = ({
    id,
    open,
    onClose,
    rating = 0,
    description = "",
    ...rest
}: Props) => {
    const [tdb, { isLoading, isSuccess }] = tripsAPI.useTdbMutation();

    async function handleSubmit({ id, rating }: FormValues) {
        try {
            await tdb({
                path: "update",
                body: { type: "onDemandTrip", tripRating: rating, id },
            });
            enqueueSnackbar("Rating submitted successfully", {
                variant: "success",
            });
        } catch (error) {
            enqueueSnackbar("Sorry an error occured", {
                variant: "error",
            });
        }
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
            aria-labelledby="title"
            {...rest}
        >
            <Formik initialValues={{ id, rating }} onSubmit={handleSubmit}>
                {({ values, setFieldValue, submitForm }) => (
                    <Form>
                        <DialogContent sx={{ textAlign: "center" }} id="title">
                            {!isSuccess ? (
                                <Fragment>
                                    <DialogContentText variant="h6">
                                        Please Rate Your Trip
                                    </DialogContentText>
                                    <DialogContentText mt={1}>
                                        {description}
                                    </DialogContentText>

                                    <Box sx={{ mt: 1 }}>
                                        {isLoading && (
                                            <CircularProgress
                                                color="inherit"
                                                size={55}
                                                sx={{ my: 2 }}
                                            />
                                        )}
                                        <Rating
                                            value={values.rating}
                                            onChange={(evt, value) => {
                                                setFieldValue("rating", value);
                                                setTimeout(() => {
                                                    submitForm();
                                                }, 500);
                                            }}
                                        />
                                    </Box>
                                </Fragment>
                            ) : (
                                <DialogContentText variant="h4" my={2}>
                                    Thank You!
                                </DialogContentText>
                            )}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={onClose}>Close</Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};

export default RatingsDialog;
