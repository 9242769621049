// trip detail helpers
import { User } from "src/modules/auth/types";
import {
    Fleetmanager,
    FormattedGPS,
    OnDemandTrip,
    PassioBus,
    PassioDriver,
    RideRequest,
    RideStatus,
    Vehicle,
} from "../../types";
import { capitalize, startCase } from "lodash";
import {
    defaultTripStatus,
    defaultTripStatusCoords,
} from "src/utils/defaultData";
import { TripScheduleStatus } from "src/types";
import { SelectionType } from "../TripRequest/types";
import { RiderExperience } from "./types";
import { sortOnDemandTripActions } from "../../redux/helpers";
import moment from "moment";
import { isValidCoord } from "src/utils/helpers";

export function getParaplanStatusText(rideStatus: number | undefined) {
    switch (rideStatus) {
        case 1:
            return "Waiting for approval";
        case 2:
            return "Approved. Waiting for assignment";
        case 3:
        case 4:
            return "Assigned to driver";
        case 5:
            return "Driver on their way";
        case 6:
            return "Driver is almost there";
        case 7:
            return "Driver is at pick up location";
        case 8:
            return "Heading to drop off location";
        case 9:
            return "Driver is almost at drop off location";
        case 10:
            return "Driver is at drop off location";
        case 11:
            return "Trip has been completed";
        case 12:
            return "Trip has been rejected";
        case 13:
            return "Trip has been cancelled";
        default:
            return "Email confirmation is on the way!";
    }
}

export function getNavigatorStatusText(rideStatus: number | undefined) {
    switch (rideStatus) {
        case TripScheduleStatus.WaitingForApproval:
            return "Waiting for approval";
        case TripScheduleStatus.AcceptedTripRequest:
            return "Approved. Waiting for assignment";
        case TripScheduleStatus.Scheduled:
            return "Assigned to driver";
        case TripScheduleStatus.PickUpArrived:
            return "Driver is at pick up location";
        case TripScheduleStatus.DropOffArrived:
            return "Driver is at drop off location";
        case TripScheduleStatus.DropOffPerformed:
            return "Trip has been completed";
        case TripScheduleStatus.NotApproved:
            return "Trip has been rejected";
        case TripScheduleStatus.Cancelled:
            return "Trip has been cancelled";
        default:
            return "Email confirmation is on the way!";
    }
}

export function formatAddress(address: any) {
    if (!address && !address?.name && !address?.city) {
        return "N/A";
    }
    return `${address.name}, ${address.city}`;
}

export function formatAddressFromRequest(
    request: RideRequest,
    type: SelectionType = "pickUp"
) {
    if (!request) return "N/A";
    else if (request[`${type}AddressFull`])
        return request[`${type}AddressFull`];
    return `${request[`${type}Address`]}, ${request[`${type}City`]}`;
}

export function formatTime(time: any, isNavigator = false) {
    if (!time) return "";
    return isNavigator
        ? moment(time).format("LLLL")
        : moment.unix(parseInt(time)).format("LLLL");
}

export function formatName(request: any) {
    if (!request && !request?.clientFirstName && !request?.clientLastName)
        return "";
    return `${capitalize(request.clientFirstName)} ${startCase(
        capitalize(request.clientLastName)
    )}`;
}

export function formatParaplanCoords(data: RideStatus | undefined) {
    if (!data) return defaultTripStatusCoords;

    let coords = { ...defaultTripStatusCoords };
    // default to request coords
    if (data.request) {
        coords = {
            ...coords,
            pickUpCoords: {
                lat: parseFloat(data.request.pickUpLatitude as any),
                lng: parseFloat(data.request.pickUpLongitude as any),
            },
            dropOffCoords: {
                lat: parseFloat(data.request.dropOffLatitude as any),
                lng: parseFloat(data.request.dropOffLongitude as any),
            },
        };
    }
    // add geo coords if available
    if (data?.geo) {
        if (data.geo?.pickUp) {
            coords.pickUpCoords = data.geo.pickUp;
        }
        if (data.geo?.dropOff) {
            coords.dropOffCoords = data.geo.dropOff;
        }
        if (data.geo?.vehicle) {
            coords.vehicleCoords = data.geo.vehicle;
        }
        if (data.geo?.rider) {
            coords.riderCoords = data.geo.rider;
        }
    }
    return coords;
}

export function formatParaplanRiderExperience(
    data: RideStatus | undefined
): RiderExperience {
    if (!data) return defaultTripStatus;

    const pickUpAddress = data?.geo?.pickUp
        ? formatAddress(data.geo.pickUp)
        : formatAddressFromRequest(data.request);

    const dropOffAddress = data?.geo?.dropOff
        ? formatAddress(data.geo.dropOff)
        : formatAddressFromRequest(data.request, "dropOff");

    const scheduledPickUpTimeEpoch = data?.times?.scheduledPickUpTimeEpoch
        ? formatTime(data?.times?.scheduledPickUpTimeEpoch)
        : formatTime(data?.request?.pickUpTimeEpoch);

    const scheduledDropOffTimeEpoch = data?.times?.scheduledDropOffTimeEpoch
        ? formatTime(data?.times?.scheduledDropOffTimeEpoch)
        : formatTime(data?.request?.dropOffTimeEpoch);

    let details = {
        ...defaultTripStatus,
        tripId: data.tripId,
        pickUpAddress,
        dropOffAddress,
        scheduledPickUpTimeEpoch,
        rideStatus: data.rideStatus,
        scheduledDropOffTimeEpoch,
        status: getParaplanStatusText(data.rideStatus),
        coords: formatParaplanCoords(data),
        username: formatName(data.request),
        // only add times when available
        ...(data?.times && {
            actualPickUpArrivalEpoch: formatTime(
                data?.times?.actualPickUpArrivalEpoch
            ),
            estimatedPickUpArrivalEpoch: formatTime(
                data?.times?.estimatedPickUpArrivalEpoch
            ),
            actualDropOffTimePerformEpoch: formatTime(
                data?.times?.actualDropOffTimePerformEpoch
            ),
            estimatedDropOffArrivalEpoch: formatTime(
                data?.times?.estimatedDropOffArrivalEpoch
            ),
            estimatedDropOffTimePerformEpoch: formatTime(
                data?.times?.estimatedDropOffTimePerformEpoch
            ),
        }),
    };

    return details;
}

export function formatNavigatorRiderExperience(
    trip: OnDemandTrip,
    user: User,
    bus: PassioBus | undefined
): RiderExperience {
    if (!trip || !user) return defaultTripStatus;

    /**
     * Use updated trip statuses from onDemandTripActionHistory to assign the correct
     * reservationType for each trip
     * Sort first to get the correct entry
     */
    const sortedLog = sortOnDemandTripActions(trip.onDemandTripActionHistory);

    const rideStatus = trip.actualDropOffTime
        ? TripScheduleStatus.DropOffPerformed
        : trip.actualPickUpTime
        ? TripScheduleStatus.PickUpPerformed
        : trip.runId
        ? TripScheduleStatus.Scheduled
        : sortedLog[0]
        ? parseInt(sortedLog[0].onDemandActionId)
        : TripScheduleStatus.WaitingForApproval;

    const pickUpPlace = JSON.parse(trip.pickUpPlace);
    const dropOffPlace = JSON.parse(trip.dropOffPlace);

    let details = {
        ...defaultTripStatus,
        rideStatus,
        runId: trip.runId || "",
        tripId: trip.id,
        tripURL: trip.tripURL,
        tripUuid: trip.tripUuid,
        riderId: trip.riderId,
        serviceId: trip.serviceId,
        requesterEmail: trip.requesterEmail,
        pickUpTime: trip.pickUpTime,
        dropOffTime: trip.dropOffTime,
        username: user.Name,
        pickUpAddress: formatAddress(pickUpPlace),
        dropOffAddress: formatAddress(dropOffPlace),
        scheduledPickUpTimeEpoch: formatTime(trip.pickUpTime, true),
        scheduledDropOffTimeEpoch: formatTime(trip.dropOffTime, true),
        status: getNavigatorStatusText(rideStatus),
        tripRating: trip.tripRating ? parseInt(trip.tripRating) : 0,
        coords: {
            ...defaultTripStatus.coords,
            pickUpCoords: {
                lat: parseFloat(pickUpPlace.latitude),
                lng: parseFloat(pickUpPlace.longitude),
            },
            dropOffCoords: {
                lat: parseFloat(dropOffPlace.latitude),
                lng: parseFloat(dropOffPlace.longitude),
            },
            // add vehicle coords if bus is available
            ...(bus && {
                vehicleCoords: {
                    lat: parseFloat(bus.latitude),
                    lng: parseFloat(bus.longitude),
                },
            }),
        },
    };

    return details;
}

export function getDistanceBetweenCoords(
    from: google.maps.LatLngLiteral,
    to: google.maps.LatLngLiteral
) {
    const fromCoords = new google.maps.LatLng(from);
    const toCoords = new google.maps.LatLng(to);
    return google.maps.geometry.spherical.computeDistanceBetween(
        fromCoords,
        toCoords
    );
}

// convert distance in meters to miles
export function distanceToMiles(distance = 0) {
    return Math.floor(distance * 0.000621371);
}

export interface RankedVehicle {
    points: number;
    vehicleId: number | string;
    onboardRiders: number;
    futureRiders: number;
    totalCap: number;
    runId: number;
    driverId: number | string;
    selected: boolean;
    trips: (string | number)[];
    lat: number | null;
    lng: number | null;
}

export async function autoSchedule(
    tripId: string | number = "",
    runs: Fleetmanager[] = [],
    vehicles: Vehicle[] = [],
    drivers: PassioDriver[] = [],
    trips: OnDemandTrip[] = [],
    currentGPS: FormattedGPS[] = [],
    debug = false
): Promise<RankedVehicle[]> {
    let rankedVehicles: RankedVehicle[] = [];
    const trip = trips.find((trip) => trip.id === String(tripId));

    if (!trip) return [];

    if (debug) {
        console.log("🚀 ~ AutoScheduling trip:", trip);
    }

    for (const run of runs) {
        const vehicle = vehicles.find(
            (vehicle) => vehicle.databaseID === run.vehicleID
        );

        // if vehicle does not exist, skip
        if (!vehicle) continue;

        // check if driver is paused
        const driver = drivers.find(
            (driver) => Number(driver.id) === run.driverID
        );

        if (!driver || driver.onDemandIsPaused) continue;

        const gps = currentGPS.find((gps) => gps.busId === run.vehicleID);

        const assignedTrips = trips.filter((trip) => {
            return trip.runId && parseInt(trip.runId) === run.fleetmanagerID;
        });

        let ranking = {
            points: 0,
            vehicleId: vehicle.databaseID || 0,
            totalCap: vehicle.totalCap || 0,
            onboardRiders: 0,
            futureRiders: 0,
            runId: run.fleetmanagerID,
            driverId: run.driverID,
            selected: false,
            trips: assignedTrips.length
                ? assignedTrips.map((trip) => trip.id)
                : [],
            lat: gps?.lat || null,
            lng: gps?.lng || null,
        };

        // if no current trip, add 10 points
        if (!assignedTrips.length) {
            ranking.points += 10;
        } else {
            // check capacity
            for (const item of assignedTrips) {
                const unloadedTime = moment(item.unloadedTime);
                const totalRiders = item.totalRiders
                    ? parseInt(item.totalRiders)
                    : 1;
                if (moment().isBefore(unloadedTime)) {
                    ranking.onboardRiders += totalRiders;
                } else if (
                    unloadedTime.isBetween(moment(), moment(trip.unloadedTime))
                ) {
                    ranking.futureRiders += totalRiders;
                }
            }
            // check if no trip is pending we can deduce this from onboard riders
            if (!ranking.onboardRiders) {
                ranking.points += 15;
            }
        }

        // if vehicle is not moving or speed is lower than 5mph, add 10 points
        if (!gps || gps?.speed < 5) {
            ranking.points += 10;
        }

        // if run has valid gps coordinates, calculate distance to trip
        if (gps && isValidCoord(gps)) {
            //  calculate distance to pickup
            const distance = getDistanceBetweenCoords(
                gps,
                JSON.parse(trip.pickUpPlace)
            );
            // if distance is under 10miles, add the difference as points
            const pointDiff = 10 - distanceToMiles(distance);
            if (pointDiff > 0 && pointDiff < 10) {
                ranking.points += pointDiff;
            }

            if (debug) {
                console.info(
                    "🚀 ~ Calculating point difference, distance to pickup"
                );
                console.log("🚀 ~ distance:", distance);
                console.log("🚀 ~ pointDif:", pointDiff);
            }

            // if vehicle has other trips, calculate distance from last trip manifest to vehicles current location

            if (assignedTrips.length) {
                const lastTrip = assignedTrips.pop();
                if (lastTrip) {
                    const distance = getDistanceBetweenCoords(
                        gps,
                        JSON.parse(trip.dropOffPlace)
                    );

                    // if distance is under 10miles, add the difference as points
                    const pointDiff = 10 - distanceToMiles(distance);
                    if (pointDiff > 0 && pointDiff < 10) {
                        ranking.points += pointDiff;
                    }

                    if (debug) {
                        console.info(
                            "🚀 ~ Run has no pending trips, calculating point difference"
                        );
                        console.log("🚀 ~ distance:", distance);
                        console.log("🚀 ~ pointDif:", pointDiff);
                    }
                }
            }
        }

        rankedVehicles.push(ranking);

        if (debug) {
            console.log(
                `🚀 ~ run: ${run.nameWithTP} | ${run.vehicleName}`,
                run
            );
            console.log(`🚀 ~ vehicle: ${vehicle.name}`, vehicle);
            console.log(`🚀 ~ gps: ${gps?.busId} | ${gps?.driver}`, gps);
            console.log("🚀 ~ assignedTrips ~ assignedTrips:", assignedTrips);
            console.log("🚀 ~  ranking :", ranking);
        }
    }

    // Ensure ranked vehicles have capacity and select best
    rankedVehicles = rankedVehicles
        .sort((a, b) => b.points - a.points)
        .reduce((acc: RankedVehicle[] = [], vehicle) => {
            const totalRiders = trip.totalRiders
                ? parseInt(trip.totalRiders)
                : 1;
            const potentialRiderCount =
                vehicle.onboardRiders + vehicle.futureRiders + totalRiders;
            if (vehicle.totalCap >= potentialRiderCount) {
                // we only want one selected target
                if (!acc.find((item) => item.selected)) {
                    vehicle.selected = true;
                }
            }
            acc.push(vehicle);
            return acc;
        }, []);

    // if no best fit target was found, check if vehicle will be free at the time this trip will get on
    if (
        rankedVehicles.length &&
        !rankedVehicles.find((item) => item.selected)
    ) {
        const topRated = rankedVehicles[0];
        const lastTrip = trips.find((item) => item.id === topRated.trips[0]);
        if (
            lastTrip &&
            moment(lastTrip.unloadedTime).isBefore(moment(trip.pickUpTime))
        ) {
            topRated.selected = true;
        }
    }

    if (debug) {
        console.group("🚀 ~ RankedVehicles");
        for (const item of rankedVehicles) {
            const vehicle = vehicles.find(
                (vehicle) => vehicle.databaseID === item.vehicleId
            );
            console.log(
                `🚀 ~ ${
                    item.selected ? "AutoScheduleTarget" : "Vehicle"
                }: ${vehicle?.name} | ${item.points}`,
                item
            );
        }
        console.groupEnd();
    }

    return rankedVehicles;
}

export const notificationCheckKeys = {
    driverArrived: false,
    tripApproved: false,
    tripNotApproved: false,
    tripScheduled: false,
};

export function getNotificationLSKey(
    id: string | number,
    type: "sms" | "email" = "email"
) {
    return type === "sms" ? `${id}smsChecks` : `${id}emailChecks`
}
