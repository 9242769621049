import { useRef, useState, useEffect, useMemo, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Splashscreen, Error as ErrorDialog } from "src/components";
import { useAppSelector } from "src/hooks";
import { tripsAPI, tripsSelector } from "../redux/tripSlice";
import {
    authSelector,
    navigatorSelector,
} from "src/modules/auth/redux/authSlice";
import { RankedVehicle, autoSchedule } from "./details/helpers";
import { FormattedGPS } from "../types";
import { AutoScheduleFailAction, TripScheduleStatus } from "src/types";
import { autoScheduleFailedSMS } from "src/utils/sms";
import { isErrorWithMessage } from "src/utils/helpers";
import { enqueueSnackbar } from "notistack";
import {
    formatTripNotAutoScheduledEmail,
    formatTripNotAutoScheduledDispatcherEmail,
    autoScheduleFailedEmail,
} from "src/utils/email";
import { ParsedAddress } from "src/components/forms/types";
import { pick, debounce } from "lodash";
import { useTitle, useLocalStorage } from "react-use";

const Schedule = () => {
    useTitle("Trip Autoschedule");
    const { id = "" } = useParams();
    const navigate = useNavigate();
    const scheduling = useRef(false);

    const { config, client, debug, programs, user } =
        useAppSelector(authSelector);
    const isNavigator = useAppSelector(navigatorSelector);
    const { devices, mdts } = useAppSelector(tripsSelector);

    const [processing, setProcessing] = useState({
        active: true,
        modal: false,
        message: "Please wait while we find your driver",
    });

    const [autoScheduleChecked, setAutoScheduleChecked] = useLocalStorage(
        `${id}autoScheduleChecked`,
        false
    );

    const [autoScheduleFailCount = 0, setAutoScheduleFailCount] =
        useLocalStorage(`${id}autoScheduleFailCount`, 0);

    const canAutoScheduleTrips =
        isNavigator &&
        !autoScheduleChecked &&
        autoScheduleFailCount < 2 &&
        config?.ConnectPreferences?.AutoScheduleHierarchy
            ?.SystemShouldAutoSchedule;

    const [tdb] = tripsAPI.useTdbMutation();
    const [sendSMS] = tripsAPI.useSendSMSMutation();
    const [updateMDT] = tripsAPI.useUpdateMDTMutation();
    const [sendEmail] = tripsAPI.useSendEmailMutation();
    const [cancelTrip] = tripsAPI.useCancelTripMutation();
    const [scheduleTrip] = tripsAPI.useScheduleTripMutation();

    // todo remove autoschedule logic after its moved to backend
    // load requirements for autoschedule
    const { data: runs = [] } = tripsAPI.useGetFleetManagersQuery(undefined, {
        skip: !canAutoScheduleTrips,
        refetchOnMountOrArgChange: true,
    });

    const { data: vehicles = [] } = tripsAPI.useGetVehiclesQuery(undefined, {
        skip: !canAutoScheduleTrips,
        refetchOnMountOrArgChange: true,
    });

    const { data: trips = [], isSuccess: tripsFetched } =
        tripsAPI.useGetRequestedTripsQuery(undefined, {
            skip: !canAutoScheduleTrips,
            refetchOnMountOrArgChange: true,
        });

    const { data: currentGPS = [], isSuccess: gpsFetched } =
        tripsAPI.useGetBusLocationQuery(
            runs.length > 0 ? runs.map((item) => item.vehicleID) : [],
            {
                skip: !canAutoScheduleTrips || !runs.length,
            }
        );

    const { data: drivers = [], isSuccess: driversFetched } =
        tripsAPI.useGetDriversQuery(undefined, {
            skip: !canAutoScheduleTrips || !runs.length,
        });

    const handleNavigate = useCallback(() => {
        navigate(`/trips/${id}?status=live`, { state: {} });
    }, [navigate, id]);

    const handleMDTUpdate = useCallback(
        async (runId: number) => {
            try {
                // only update mdts for navigator
                if (!isNavigator || !runId) return;
                else if (typeof runId === "string") {
                    runId = parseInt(runId);
                }
                const run = runs.find((run) => run.fleetmanagerID === runId);

                if (!run) return;

                const deviceIds = devices.reduce(
                    (acc: string[] = [], device) => {
                        const match =
                            Number(device.busId) === Number(run.vehicleID);
                        const isMDT = mdts.find(
                            (mdt) => mdt.id === device.deviceSystemId
                        );
                        if (match && isMDT) {
                            acc.push(device.id);
                        }
                        return acc;
                    },
                    []
                );

                if (deviceIds.length) {
                    await Promise.all(
                        deviceIds.map(
                            async (id) => await updateMDT(id).unwrap()
                        )
                    );
                }
            } catch {}
        },
        [devices, isNavigator, mdts, runs, updateMDT]
    );

    const addAutoScheduleLog = useCallback(
        async (runs: RankedVehicle[]) => {
            try {
                await tdb({
                    path: "add",
                    body: {
                        type: "onDemandAutoScheduleLog",
                        results: runs,
                        userId: config.agencyId,
                        authorId: user.UserId,
                        tripId: id,
                    },
                });
            } catch {}
        },
        [id, user, tdb, config.agencyId]
    );

    const handleAutoSchedule = useCallback(async () => {
        if (processing.modal) return;

        setProcessing((current) => ({
            ...current,
            active: true,
            message: "Please wait while we find your driver",
        }));
        try {
            const ranked = await autoSchedule(
                id,
                runs,
                vehicles,
                drivers,
                trips,
                currentGPS as FormattedGPS[],
                debug
            );

            const bestRun = ranked.find((item) => item.selected);

            if (!bestRun) {
                const message =
                    "Sorry, we couldn't autoschedule your trip at this time. Please try again later";
                const trip = trips.find((trip) => trip.id === id);
                if (!trip) throw new Error(message);

                const service = programs.find(
                    (program) => program.databaseID === trip.serviceId
                );

                // If we tried to autoschedule at least once before, send out the notifications
                if (service && autoScheduleFailCount) {
                    switch (service.autoScheduleFailAction) {
                        case AutoScheduleFailAction.CancelTripAndLetRiderKnow:
                            // cancel trip
                            await cancelTrip({
                                reason: "No available autoschedule targets",
                                id,
                            }).unwrap();

                            // update Trip log
                            const logRequest = {
                                path: "add",
                                body: {
                                    type: "onDemandTripActionHistory",
                                    userId: client.agencyId,
                                    riderId: client.id,
                                    onDemandTripId: id,
                                    onDemandActionId:
                                        TripScheduleStatus.Cancelled,
                                    latitude: 0,
                                    longitude: 0,
                                },
                            };
                            await tdb(logRequest).unwrap();

                            const email = formatTripNotAutoScheduledEmail(
                                client.email
                            );
                            await sendEmail(email).unwrap();
                            await sendSMS({
                                to: client.phone,
                                message: autoScheduleFailedSMS,
                            }).unwrap();

                            setProcessing({
                                active: false,
                                modal: true,
                                message: "Trip cancelled",
                            });

                            return;
                        case AutoScheduleFailAction.EmailDispatcher:
                            const pickUpPlace: ParsedAddress = JSON.parse(
                                trip.pickUpPlace
                            );

                            const dropOffPlace: ParsedAddress = JSON.parse(
                                trip.dropOffPlace
                            );
                            const dispatcherEmail =
                                formatTripNotAutoScheduledDispatcherEmail({
                                    pickUpAddress: pickUpPlace.address1,
                                    dropOffAddress: dropOffPlace.address1,
                                    email: config.DispatcherEmail,
                                    riderId: client.id,
                                    ...pick(trip, [
                                        "tripUuid",
                                        "pickUpTime",
                                        "dropOffTime",
                                    ]),
                                });
                            await sendEmail(dispatcherEmail).unwrap();
                            break;
                    }
                }
                await addAutoScheduleLog(ranked);
                throw new Error(message);
            }

            const request = {
                tripId: id,
                clientId: client.id,
                fleetManagerId: bestRun.runId,
            };

            await scheduleTrip(request).unwrap();
            await addAutoScheduleLog(ranked);
            await handleMDTUpdate(bestRun.runId);
            setAutoScheduleChecked(true);
        } catch (error) {
            // only alert for the second try
            if (isErrorWithMessage(error) && autoScheduleFailCount) {
                enqueueSnackbar(error.message, { variant: "info" });
            }
            setAutoScheduleFailCount(autoScheduleFailCount + 1);
        }

        // only navigate away after trying twice
        if (autoScheduleFailCount >= 2 && !processing.modal) {
            handleNavigate();
        }
    }, [
        id,
        runs,
        drivers,
        trips,
        vehicles,
        programs,
        currentGPS,
        debug,
        client.id,
        client.agencyId,
        client.email,
        client.phone,
        processing.modal,
        tdb,
        sendSMS,
        sendEmail,
        cancelTrip,
        scheduleTrip,
        handleNavigate,
        handleMDTUpdate,
        addAutoScheduleLog,
        setAutoScheduleChecked,
        config.DispatcherEmail,
        setAutoScheduleFailCount,
        autoScheduleFailCount,
    ]);

    const debouncedAutoSchedule = useMemo(() => {
        return debounce(handleAutoSchedule, 15000);
    }, [handleAutoSchedule]);

    useEffect(() => {
        // only pass numeric trip ids
        if (!id || !parseInt(id)) return navigate("/trips/request");

        // only navigate away if we've failed to autoschedule twice
        if (!canAutoScheduleTrips && !processing.modal) handleNavigate();

        if (
            !scheduling.current &&
            runs.length &&
            vehicles.length &&
            tripsFetched &&
            gpsFetched &&
            driversFetched
        ) {
            scheduling.current = true;
            debouncedAutoSchedule();
        }
        return () => {
            scheduling.current = false;
            debouncedAutoSchedule.cancel();
        };
    }, [
        id,
        navigate,
        gpsFetched,
        runs.length,
        vehicles.length,
        tripsFetched,
        driversFetched,
        handleNavigate,
        processing.modal,
        debouncedAutoSchedule,
        canAutoScheduleTrips,
    ]);

    if (processing.modal) {
        return (
            <ErrorDialog
                description={autoScheduleFailedEmail}
                title="Trip Scheduling Failure Notification"
                onClick={() => navigate("/trips/request")}
            />
        );
    }

    return <Splashscreen description={processing.message} />;
};

export default Schedule;
