import { useState } from "react";
import { tripsAPI } from "src/modules/trips/redux/tripSlice";
import { enqueueSnackbar } from "notistack";
import { CircularProgress, Rating, Tooltip } from "@mui/material";

interface Props {
    id: string;
    rating: number;
}

const Ratings = ({ id, rating = 0 }: Props) => {
    const [value, setValue] = useState<number | null>(rating);
    const [tdb, { isLoading }] = tripsAPI.useTdbMutation();

    async function handleRating(value = 0) {
        try {
            await tdb({
                path: "update",
                body: { type: "onDemandTrip", tripRating: value, id },
            });
            enqueueSnackbar("Rating submitted successfully", {
                variant: "success",
            });
        } catch (error) {
            enqueueSnackbar("Sorry an error occured", { variant: "error" });
        }
    }

    return isLoading ? (
        <CircularProgress />
    ) : (
        <Tooltip title="Rate this trip">
            <Rating
                value={value}
                onChange={(evt, value) => {
                    setValue(value);
                    setTimeout(() => {
                        handleRating(value || 0);
                    }, 500);
                }}
            />
        </Tooltip>
    );
};

export default Ratings;
