import {
    Box,
    Alert,
    Dialog,
    Button,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogProps,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useAppSelector, useAppDispatch } from "src/hooks";
import { FormInput } from "src/components";
import { Formik, Form, FormikHelpers } from "formik";
import { passwordSchema } from "../schema";
import {
    authAPI,
    logout,
    authSelector,
} from "src/modules/auth/redux/authSlice";
import { enqueueSnackbar } from "notistack";
import { PassioService } from "src/types";
import { useNavigate } from "react-router-dom";

interface Props extends Omit<DialogProps, "onClose"> {
    onClose: () => void;
}

interface FormValues {
    oldPassword: string;
    newPassword: string;
    confirmPassword?: string;
}

const initialValues: FormValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
};

const ChangePasswordModal = ({ open, onClose, ...rest }: Props) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { service, user } = useAppSelector(authSelector);
    const isNavigator = service === PassioService.navigator;

    const [changePassword, { isLoading }] = authAPI.useChangePasswordMutation();
    const [validatePassword, { isLoading: isValidating }] =
        authAPI.useValidateOldPasswordMutation();

    async function handleSubmit(
        values: FormValues,
        helpers: FormikHelpers<FormValues>
    ) {
        try {
            // validate old password for navigator
            if (isNavigator) {
                try {
                    await validatePassword({
                        id: user.UserId,
                        password: values.oldPassword,
                    }).unwrap();
                } catch {
                    helpers.setFieldError("oldPassword", "Invalid password");
                    enqueueSnackbar("Old password is incorrect", {
                        variant: "error",
                    });
                    return;
                }
            }
            delete values.confirmPassword;
            const request = {
                ...values,
                userId: user?.UserId,
            };

            await changePassword(request).unwrap();
            enqueueSnackbar("Password changed successfully. Please login", {
                variant: "success",
            });

            // reset location state to prevent auto redirects after login
            navigate(
                {
                    pathname: "/login",
                    search: isNavigator
                        ? `service=${PassioService.navigator}`
                        : undefined,
                },
                { replace: true, state: {} }
            );

            // add a little timeout to ensure service reset only after redirect
            setTimeout(() => dispatch(logout()), 200);
            onClose();
        } catch (error) {
            enqueueSnackbar("Sorry an error occured", {
                variant: "error",
            });
        }
    }

    return (
        <Dialog open={open} onClose={onClose} {...rest}>
            <Formik
                initialValues={initialValues}
                validationSchema={passwordSchema}
                onSubmit={handleSubmit}
            >
                <Form>
                    <DialogTitle>Change Password</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <Alert severity="warning">
                                You will have to login again after changing your
                                password
                            </Alert>
                        </DialogContentText>

                        <Box component={Form} sx={{ mt: 1 }}>
                            <FormInput
                                fullWidth
                                name="oldPassword"
                                label="Current Password"
                                type="password"
                                margin="normal"
                            />
                            <FormInput
                                fullWidth
                                name="newPassword"
                                label="New Password"
                                type="password"
                                margin="normal"
                            />
                            <FormInput
                                fullWidth
                                name="confirmPassword"
                                label="Confirm Password"
                                type="password"
                                margin="normal"
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose}>Cancel</Button>
                        <LoadingButton
                            type="submit"
                            loading={isLoading || isValidating}
                        >
                            Continue
                        </LoadingButton>
                    </DialogActions>
                </Form>
            </Formik>
        </Dialog>
    );
};

export default ChangePasswordModal;
