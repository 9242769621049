import React from "react";

import {
    Box,
    Slide,
    Stack,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Button,
    Dialog,
    Grid,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { Close } from "@mui/icons-material";
import { useFormikContext } from "formik";
import { useAppSelector, useSelectedClient } from "src/hooks";
import { authSelector } from "src/modules/auth/redux/authSlice";
import { isObject } from "lodash";
import { Program } from "src/modules/auth/types";
import { PassioService } from "src/types";
import { TripRequestFormValues } from "../types";
import GoogleMap, { MapGeoFence } from "src/components/map/GoogleMap";
import ProgramDetails from "./ProgramDetails";
import TripSelector from "./TripSelector";

interface Props {
    label?: string;
    name?: string;
    geofence?: MapGeoFence; // passed down from location selector
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    loading?: boolean;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const TripPrograms = ({
    label = "Service",
    name = "tripProgram",
    onChange,
    geofence,
    loading,
}: Props) => {
    const [open, setOpen] = React.useState(false);
    const { values } = useFormikContext<TripRequestFormValues>();
    const { isManager, availableFundingSources, service, programs } =
        useAppSelector(authSelector);

    const isNavigator = service === PassioService.navigator;
    const hasAddresses =
        values.pickUpPlace.description && values.dropOffPlace.description
            ? true
            : false;

    // since managers can request trips for a rider, we want to make sure we are loading
    // the riders programs instead of the managers at this point
    const client = useSelectedClient(String(values.clientID));

    const program = React.useMemo(() => {
        let item = client?.programs?.find(
            (program) => program.programName === values.tripProgram
        );

        // programCalendarRules is a JSON string so we have to parse it
        if (
            item &&
            item.programCalendarRules &&
            isObject(JSON.parse(item.programCalendarRules))
        ) {
            return {
                ...item,
                parsedProgramCalendarRules: JSON.parse(
                    item.programCalendarRules
                ),
            };
        }

        return item;
    }, [client, values.tripProgram]);

    const filteredPrograms = React.useMemo(() => {
        let selected: Program[] = [];
        if (client && client?.programs.length > 0) {
            // only select programs which are not archived
            selected = client?.programs?.filter((program) => !program.archived);
        } else if (isNavigator) {
            selected = programs;
        }

        if (
            selected.length &&
            geofence &&
            geofence.zones.length &&
            hasAddresses
        ) {
            selected = selected.filter((program) => {
                if (!program.activeGeozoneIds.length) return true;
                return geofence.zones.some((zone) =>
                    program.activeGeozoneIds.includes(zone)
                );
            });
        }
        return selected;
    }, [client, isNavigator, programs, geofence, hasAddresses]);

    function handleClose() {
        setOpen(false);
    }

    return (
        <React.Fragment>
            <Stack spacing={2} direction="row" tabIndex={0}>
                <Box flexGrow={2}>
                    <TripSelector
                        label={label}
                        name={name}
                        programs={filteredPrograms}
                        availableFundingSources={availableFundingSources}
                        hasAddresses={hasAddresses}
                        onChange={onChange}
                    />
                </Box>
                {!isManager && values.tripProgram && (
                    <Button
                        size="small"
                        variant="outlined"
                        onClick={() => setOpen(true)}
                    >
                        View Details
                    </Button>
                )}
            </Stack>

            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar color="primary" sx={{ position: "relative" }}>
                    <Toolbar sx={{ justifyContent: "space-between" }}>
                        <Typography variant="h6" component="div">
                            {isNavigator ? "Service" : "Program"} Details
                        </Typography>
                        <IconButton
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <Close />
                        </IconButton>
                    </Toolbar>
                </AppBar>

                <Box p={{ xs: 2, md: 4 }}>
                    <Stack
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        mb={2}
                    >
                        <Typography variant="h4" component="h2" flexGrow={3}>
                            {program?.programName}
                            {program?.membershipID &&
                                ` (${program.membershipID})`}
                        </Typography>

                        <Box flexGrow={1}>
                            <TripSelector
                                label={label}
                                name={name}
                                programs={filteredPrograms}
                                availableFundingSources={
                                    availableFundingSources
                                }
                                hasAddresses={hasAddresses}
                                onChange={onChange}
                            />
                        </Box>
                    </Stack>

                    <Grid container spacing={2}>
                        {/* Details  */}
                        <Grid item xs={12} md={6}>
                            <ProgramDetails program={program} />
                        </Grid>
                        {/* Map preview for program geozones */}
                        <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justityContent: "center",
                            }}
                        >
                            <GoogleMap
                                loading={loading}
                                geofence={geofence}
                                pickUpCoords={{
                                    lat: values.pickUpPlace.latitude,
                                    lng: values.pickUpPlace.longitude,
                                }}
                                dropOffCoords={{
                                    lat: values.dropOffPlace.latitude,
                                    lng: values.dropOffPlace.longitude,
                                }}
                                sx={{
                                    minHeight: 450,
                                    height: "100%",
                                    width: "100%",
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Dialog>
        </React.Fragment>
    );
};

export default TripPrograms;
